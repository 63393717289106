import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './GoodBehavior.module.css';

const GoodBehavior = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
<h1>Charte de bonne conduite</h1>

<h2>a. Engagements des Hôtes</h2>

<h3>Les Hôtes s’engagent à respecter les principes de bonne conduite énumérés ci-après :</h3>
<div>
    <ul>
      <li>
      <u><b>1. Avant l’entrée dans les lieux :</b></u><br />

        · Pour la location d'un jardin : Communiquer de bonne foi aux propriétaires toutes les informations nécessaires sur la nature de l’événement pour lequel ils souhaitent effectuer une Réservation, le nombre de participants (adultes & enfants), la date de location et la durée souhaitée.
        <br /><br />
        · Pour le Prêt d'un jardin ou d'un potager : Communiquer de bonne foi aux propriétaires toutes les informations sur leur motivation et leur connaissance en matière de jardinage ou de potager.

      </li>
      <br />
      <li>

      <u><b>2. Le jour de l’entrée dans les lieux :</b></u><br />

· Respecter les plages horaires ou calendaires sélectionnées lors de la Réservation ;
<br /><br />
· Respecter les plantations, plates-bandes ou toute autre aménagement floral du jardin;
<br /><br />
· Ne pas couper ou détruire de fleurs, de plantations, arbustes, arbres, etc… de quelque nature que ce soit sans l’accord du propriétaire ;
<br /><br />
· Surveiller la sécurité des enfants aux abords des piscines ;
<br /><br />
· Avoir une tenue descente et particulièrement aux abords des piscines ;
<br /><br />
· Prendre soin des matériels et équipements (ex : barbecue, outils de jardins, …) mis à leur disposition ;
<br /><br />
· Ne pas créer de nuisances sonores qui pourraient déranger la tranquillité du voisinage excepté dans le respect des horaires autorisées par la municipalité (musique, tonte de pelouse, …) ;
<br /><br />
· Ne pas faire de feu et ne pas utiliser de produits inflammables autres que les produits mis à disposition par le propriétaire pour l’allumage des barbecues ou la destruction des déchets végétaux ;
<br /><br />
· Veiller à ce que les chiens n’aboient pas de manière intempestive ;
<br /><br />
· Ne pas laisser les animaux uriner ou faire leurs besoins dans le jardin
<br /><br />
· Ne pas laisser d’animaux se baigner dans les piscines.
      </li>
      <br />
      <li>
      <u><b>3. Avant de libérer les lieux :</b></u><br />

      · Laisser les matériels et équipements dans le même état d’usage que lors de leur mise à disposition ;
      <br /><br />
      · Ne laisser aucun détritus, sacs poubelle sur les lieux et les déposer dans les poubelles ou containers prévus par le propriétaire à cet effet ;
      <br /><br />
      · Respecter le tri des ordures ménagères lorsque les lieux disposent de bacs de tris prévus à cet effet (carton, verre, ordures ménagères, végétaux…) ;

      </li>
    </ul>
    </div>





En tout état de cause, les hôtes s’engagent à avoir un comportement courtois et de bonnes mœurs pendant toute la durée de leur présence sur les lieux.

<h2>b. Engagement des propriétaires</h2>
<br />
Le propriétaire déclare et garantit être le propriétaire ou disposer des droits nécessaires sur le jardin qu’il propose à la Réservation. Il s’engage à fournir tout justificatif à première demande.
<br />
Le propriétaire garantit que ni son Annonce, ni la Réservation, ne violent le droit de tiers, ni aucune disposition légale applicable.
<br />
Le propriétaire s’engage à ce que son Annonce corresponde au jardin destiné à la Réservation.
<br />
Le propriétaire s’engage à restituer tout dépôt de garantie dans les conditions convenues sur son annonce.
<br /><br />
En cas d’annulation de la Réservation dans le respect des délais d’annulation mentionnés sur l’Annonce, le propriétaire s’engage à rembourser au locataire la totalité du montant de la Réservation. Les Utilisateurs comprennent et acceptent qu’en aucun cas JARDINS PRIVÉS, simple tiers au contrat conclu entre le propriétaire et le locataire, ne sera responsable en cas de manquement à ces dispositions.


    </div>
  );
};

GoodBehavior.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

GoodBehavior.propTypes = {
  rootClassName: string,
  className: string,
};

export default GoodBehavior;
