import { addMarketplaceEntities, addMarketplaceFullEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
const RESULT_PAGE_SIZE = 3;

// ================ Action types ================ //

export const LANDING_LISTINGS_REQUEST = 'app/LandingPage/LANDING_LISTINGS_REQUEST';
export const LANDING_LISTINGS_SUCCESS = 'app/LandingPage/LANDING_LISTINGS_SUCCESS';
export const LANDING_LISTINGS_ERROR = 'app/LandingPage/LANDING_LISTINGS_ERROR';


// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  inProgress: false,
  listingsError: null,
  currentPageResultIds: [],
}

const resultIds = data => data.data.map(l => l.id);


const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case LANDING_LISTINGS_REQUEST:
      return {
        ...state,
        inProgress: true,
        listingsError: null,
      };
    case LANDING_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        inProgress: false,
      };
    case LANDING_LISTINGS_ERROR:
      return { ...state, inProgress: false, listingsError: payload };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInProgress = () => ({
  type: LANDING_LISTINGS_REQUEST
});

export const searchListingsSuccess = response => ({
  type: LANDING_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const listingsError = response => ({
    type: LANDING_LISTINGS_ERROR,
    payload: { data: response.data },
  });
  
export const loadData = (params, search) => (dispatch, getState, sdk) => {
  
  dispatch(setInProgress())
  

  const apiFullQueryParams = {
    lastTransitions: ["transition/confirm-payment"],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions'
    ],
    page:1,
    per_page: 30,
  };

  const currentUser = getState().user.currentUser;
if (currentUser !=null){
 sdk.transactions
    .query(apiFullQueryParams)
    .then(response => {
      dispatch(addMarketplaceFullEntities(response));
      return response;
    })
    .catch(e => {
      throw e;
    });
}

const apiQueryParams = {
  page: 1,
  pub_category: "garden_rent",
  perPage: RESULT_PAGE_SIZE,
  include: ['author', 'images', 'author.profileImage'],
  'fields.listing': ['title', 'geolocation', 'price', 'publicData.location', 'publicData.category'],
  'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
  'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x', 'variants.default'],
  'limit.images': 1,
};

  return sdk.listings
    .query(apiQueryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(response))
      console.log(response)
      return response
    })
    .catch(e => {
      dispatch(listingsError(storableError(e)));
      throw e;
    });
};
