import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingPricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';

import css from './EditListingPricingPanel.module.css';


const { Money } = sdkTypes;

const EditListingPricingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { price, publicData } = currentListing.attributes;
  const extra_fee =
    publicData && publicData.extra_fee ? publicData.extra_fee : null;
  const deposit =
    publicData && publicData.deposit ? new Money(publicData.deposit.amount, publicData.deposit.currency) : null;
  const price_terms =
    publicData && publicData.price_terms ? publicData.price_terms : null;

  const initialValues = { price, extra_fee, deposit, price_terms };

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

  let category = "garden_rent";
  if (listing && listing.attributes && listing.attributes.publicData && listing.attributes.publicData.category){
    category = listing.attributes.publicData.category;
  }


  const panelTitle = isPublished ? (
    <FormattedMessage
      id={"EditListingPricingPanel.createListingTitle_"+category}
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id={"EditListingPricingPanel.createListingTitle_"+category} />
  );

  const priceCurrencyValid = price instanceof Money ? price.currency === config.currency : true;

  // Assign optional fees to post 
  const handleExtraFee = (extra_fee) => {
    if(!extra_fee) return null
    let fees = {extra_fee: extra_fee}
    if(extra_fee && extra_fee.length > 0){
        fees.bbq_fee = null
        fees.kitchen_fee= null
        fees.barnum_fee= null
        fees.sound_fee = null
        fees.spa_fee= null
      for(let i = 0 ; i < extra_fee.length; i++){
        switch (extra_fee[i]) {
          case 'bbq_fee':
            fees.bbq_fee = {amount: 1000, currency : "EUR"}
            break;
          case 'barnum_fee':
            fees.barnum_fee = {amount: 1500, currency : "EUR"}
            break;
          case 'kitchen_fee':
            fees.kitchen_fee = {amount: 500, currency : "EUR"}
            break;
          case 'sound_fee':
            fees.sound_fee = {amount: 1000, currency : "EUR"}
            break;
          case 'spa_fee':
            fees.spa_fee = {amount: 1000, currency : "EUR"}
            break;
          default:
            break;
        }
      }
    }
    return fees
  }

  const form = priceCurrencyValid ? (
    <EditListingPricingForm
      className={css.form}
      initialValues={initialValues}
      onSubmit={values => {
        const { price , extra_fee = null, deposit = null, price_terms = null} = values;
        let updatedValues = {
          price
        }
        if(extra_fee){
          updatedValues.publicData = handleExtraFee(extra_fee)
        }
        if(deposit){
          let x = {amount: deposit.amount, currency : "EUR"}
          if(updatedValues.publicData === undefined){
            updatedValues.publicData = {};
          }
          updatedValues.publicData.deposit = x
        }
        if(price_terms){
          if(updatedValues.publicData === undefined){
            updatedValues.publicData = {};
          }
          updatedValues.publicData.price_terms = price_terms
        }

        onSubmit(updatedValues);
      }}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
      category={publicData.category}
    />
  ) : (
    <div className={css.priceCurrencyInvalid}>
      <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
    </div>
  );

  return (
    <div className={classes}>
      <h2 className={css.title}>{panelTitle}</h2>
      {form}
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPricingPanel;