import React from 'react';

import {
  FieldSelect
} from '../../components';


const BookingEventType = (props) => {
  const {category,events} = props

  if(category === "garden_share"){
      return <FieldSelect id="event_type" name="event_type" label="Type de jardin*">
          <option value="">Selectionner dans la liste</option>
          <option value="cultivate">Potager à cultiver</option>
          <option value="maintain">Jardin à entretenir</option>
      </FieldSelect>
  }
  
  if(category === "garden_rent"){
    return ( <FieldSelect id="event_type" name="event_type" label="Type d'evenement*">
                <option value="">Selectionner dans la liste</option>
              {events && events.includes("bbq_party") &&
                <option value="bbq_party">Barbecue Party</option>}
                {events && events.includes("birthday") &&
                <option value="birthday">Anniversaire</option>}
                {events && events.includes("bapteme") &&
                <option value="bapteme">Baptême</option>}
                {events && events.includes("wedding") &&
                <option value="wedding">Mariage</option>}
                {events && events.includes("event_comp") &&
                <option value="event_comp">Event d'entreprise</option>}
                {events && events.includes("shooting") &&
                <option value="shooting">Shooting Photos</option>}
                {events && events.includes("friend_gathering") &&
                <option value="friend_gathering">Détente entre amis</option>}
                {events && events.includes("baby_shower") &&
                <option value="baby_shower">Baby-shower</option>}
                {events && events.includes("sport_yoga") &&
                <option value="sport_yoga">Sport-Yoga</option>}
                {events && events.includes("other") &&
                <option value="other">Autre</option>}
          </FieldSelect>
    )
  }

  return null

}

export default BookingEventType;
