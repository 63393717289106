
// Bloquer au maximum les numéros de téléphones et leurs variants.
// 06  37  94  60  14 (deux espaces et plus)
// O6 37 94 6O 14 (Lettre O qui remplace le 0) avec espace => prévoir cas avec tiret du 6 et du 8
// O637946O14 (Lettre O qui remplace le 0) sans espace => prévoir cas avec tiret du 6 et du 8
// 06.37.94.60.14
// 06-37-94-60-14
// 06_37_94_60_14 (tiret _)
// 06/37/94/60/14
// 06/ 37/ 94/ 60/ 14
// 06 / 37 / 94 / 60 / 14
// #06 37 94 60 14
// Bloquer également les double 00 => 006 37 94 60 14
// 06  37  94  60  14 (deux espaces et plus)
// O6 37 94 6O 14 (Lettre O qui remplace le 0) avec espace => prévoir cas avec tiret du 6 et du 8
// O637946O14 (Lettre O qui remplace le 0) sans espace => prévoir cas avec tiret du 6 et du 8
// 06.37.94.60.14
// 06-37-94-60-14
// 06_37_94_60_14 (tiret _)
// 06/37/94/60/14
// 06/ 37/ 94/ 60/ 14
// 06 / 37 / 94 / 60 / 14
// #06 37 94 60 14
// Bloquer également les double 00 => 006 37 94 60 14
// Bloquer l'accès au réseau sociaux :


const socials = [
    'IG',
    'Ig',
    'FB',
    'fb',
    'Fb',
    'Instagram',
    'instagram',
    'Instagrame',
    'instagrame',
    'Instagramme',
    'instagramme',
    'Instagramm',
    'instagramm',
    'instagramme',
    'INSTA',
    'INSTAGRAM',
    'INSTAGRAMM',
    'INSTAGRAMME',
    'FACEBOOK',
    'facebook',
    'Facebook',
    'facebok',
    'facebouk',
    'FACEBOUK',
    'FACEBOUC',
    'facebouc',
    'FACEBOK',
    'F.B',
    'f.b',
    'F.b',
    'F-B',
    'f-b',
    'F-B',
    'F_B',
    'F_b',
    'f_b',
    'Tweeter',
    'TWEETER',
    'Twiter',
    'TWITET',
    'Tweter',
    'Tweeter',
    'Twitter',
    'TWITTER',
    'youtube',
    'Youtube',
    'YOUTUBE',
    'YOUtube',
    'Ytube']


export const formatMessage = message => {
    
    // const emailRegex = /[\w-\.]+@([\w-]+\.)+[\w-]{2,4}/g;   ///(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g;
    // const phoneRegex = /(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})/g; // /[0-9 -.\/\\_  oO]{8,15}/g
    // const urlRegex = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/g;
    // const urlRegex2 = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{2,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;
    // const urlRegex3 = /[0-9][\./\\\- ]{0,2}[0-9][\./\\\- ]{0,2}[0-9][\./\\\- ]{0,2}[0-9][\./\\\- ]{0,2}[0-9][\./\\\- ]{0,2}[0-9][\./\\\- ]{0,2}[0-9][\./\\\- ]{0,2}[0-9]?[\./\\\- ]{0,2}[0-9]?[\./\\\- ]{0,2}[0-9]?/g;
    // //const urlRegex4 = /[0-9][0-9][0-9][0-9][0-9][0-9][0-9]?[0-9]?[0-9]?[0-9]?[0-9]*/g;


    ///[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)?/g
    // const letterNumber = ['zero', 'zéro', 'trois', 'quatre', 'cinq', 'six', 'sept', 'huit', 'neuf', 'dix']
    // let newMessage = message.replace(emailRegex, " (Jardins Privés masque les coordonnées)")
    // newMessage = newMessage.replace(phoneRegex, " (Jardins Privés masque les coordonnées)")
    // newMessage = newMessage.replace(urlRegex, " (Jardins Privés masque les coordonnées)")
    // newMessage = newMessage.replace(urlRegex2, " (Jardins Privés masque les coordonnées)")
    // newMessage = newMessage.replace(urlRegex3, " (Jardins Privés masque les coordonnées)")
    // newMessage = newMessage.replace(urlRegex4, " (Jardins Privés masque les coordonnées)")

    // for (let i = 0; i < letterNumber.length; i++) {
    //     newMessage = newMessage.replace(" " + letterNumber[i] + " ", " * ")
    // }
    // for (let i = 0; i < socials.length; i++) {
    //     let m = newMessage.split(' ')
    //     for (let x = 0; x < m.length; x++) {
    //         if (m[x].match(socials[i])) {
    //             m[x] = '(Jardins Privés masque les coordonnées)'
    //         }
    //         if (m[x].match('@')) {
    //             m[x] = "(Jardins Privés masque les coordonnées)"
    //         }
    //     }
    //     newMessage = m.join(' ')
    // }

    return message
}







/*



const socials = [
'IG',
'Ig',
'FB',
'fb',
'Fb',
'Instagram',
'instagram',
'Instagrame',
'instagrame',
'Instagramme',
'instagramme',
'Instagramm',
'instagramm',
'instagramme',
'INSTA',
'INSTAGRAM',
'INSTAGRAMM',
'INSTAGRAMME',
'FACEBOOK',
'facebook',
'Facebook',
'facebok',
'facebouk',
'FACEBOUK',
'FACEBOUC',
'facebouc',
'FACEBOK',
'F.B',
'f.b',
'F.b',
'F-B',
'f-b',
'F-B',
'F_B',
'F_b',
'f_b',
'Tweeter',
'TWEETER',
'Twiter',
'TWITET',
'Tweter',
'Tweeter',
'Twitter',
'TWITTER',
'youtube',
'Youtube',
'YOUTUBE',
'YOUtube',
'Ytube']
la location de votre jardin le 23 mai SVP 06 83 51 94 34 texte

export const formatMessage = message => {
    const emailRegex = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g;
    const phoneRegex = /[0-9 -.\/\\_  oO]{6,15}/g
    const urlRegex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)?/g
    const letterNumber = ['zero','zéro', 'trois', 'quatre', 'cinq', 'six', 'sept', 'huit', 'neuf', 'dix']
    let newMessage = message.replace(emailRegex, " (Jardins Privés masque les coordonnées)")
    newMessage = newMessage.replace(phoneRegex, " (Jardins Privés masque les coordonnées)")
    newMessage = newMessage.replace(urlRegex, " (Jardins Privés masque les coordonnées)")

    for(let i = 0; i < letterNumber.length; i++){
        newMessage = newMessage.replace(" " +letterNumber[i]+" ", " * ")
    }
    for(let i = 0; i < socials.length; i++){
        let m = newMessage.split(' ')
        for(let x = 0; x < m.length; x++){
            if(m[x].match(socials[i])){
                m[x] = '(Jardins Privés masque les coordonnées)'
            }
            if(m[x].match('@')){
                m[x] = "(Jardins Privés masque les coordonnées)"
            }
        }
        newMessage = m.join(' ')
    }

    return newMessage
}

*/


