import React from "react";
import axios from 'axios'
import css from './PayoutDetailsForm.module.css';
import { CircularProgress, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import IconCheck from '../../assets/icons/003_Compte/validation_green.svg'
import swal from 'sweetalert';


const MyFileInput = (props) => {
  const [loading, setLoading] = React.useState(false)
  const uploadFile = async (files) => {
    setLoading(true)
    const file = files[0]
    if (!file) return swal("Aucun fichier specifié")

    try {
      let formData = new FormData();
      formData.append("file", file);
      formData.append('purpose', 'identity_document');
      const req = await axios.post("https://files.stripe.com/v1/files",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
          },
        }
      )
      const response = req.data
      if (props.onChange) {
        props.onChange(response.id);
      }
    } catch (err) {
      console.log("++++++++++ERROR++++++++++", err)
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    return () => {
    }
  }, [])

  const handleChange = () => {
    // e.preventDefault();
    props.form.change(props.name, null)
  }

  if (props.hasFile) {
    return <>
      <div className={css.boxWrapperFile}>
        <div className={css.fileOk} style={{ width: "100%" }}>
          <div style={{ textAlign: "center" }}>
            <label style={{ cursor: 'default !important' }} htmlFor={props.name} >
              {loading ?
                <CircularProgress style={{ color: "#e12c42", margin: 10, height: 20, width: 20 }} />
                : <> <span><b>{props.title}</b></span><br /><br /><img src={IconCheck} height="50" /></>}
            </label>
          </div>

        </div>
        {
          !props.stripeConnected &&
          <IconButton
            style={{ color: '#4a4a4a', position: "absolute", top: 0, right: 0 }}
            onClick={() => handleChange()}><CloseIcon /></IconButton>
        }

      </div>

    </>
  }

  return (
    <div className={css.boxWrapperFile}>
      <label htmlFor={props.name} >
        <b>{props.title}</b><br />
        {loading ? <CircularProgress style={{ color: "#e12c42", margin: 10, height: 20, width: 20 }} /> : props.paragraph}<br />
        <span>JPEG, PNG, JPG (max 16mb)</span>
      </label>
      <input name={props.name} id={props.name} type="file" onChange={(e) => uploadFile(e.target.files)} />

    </div>
  );
}

export default MyFileInput;