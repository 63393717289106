import React from "react";
import css from './BookingDatesForm.module.css';

const MyInputField = (props) => {

  const handleChange = (e, value) => {
      e.preventDefault()
      if (props.onChange) {
        props.onChange(parseInt(value));
      }
  }

  return (
    <div className={css.wrapperBoxForm}>
        <p className={css.wrapperBoxFormText}>{props.title}<br />
        <span>{props.caption}</span>
        </p>
        <input disabled={props.disabled || false} name={props.name} value={props.currentValue || ""} className={css.inputBoxForm} type="number" placeholder={props.placeholder} />
        <button
          className={css.buttonBoxForm}
          onClick={(e) => handleChange(e, (props.currentValue !== props.capacityMin ? props.currentValue - 1 : props.currentValue))}>-</button>
        <button
          className={css.buttonBoxForm} onClick={(e) => handleChange(e, (props.currentValue <= props.capacityMax? props.currentValue + 1 : props.currentValue))}>+</button>
    </div>

  );
}

export default MyInputField;
