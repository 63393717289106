import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import css from './SectionHero.module.css';
import Button from '../Button/Button';
import  {
  NamedLink,
  PrimaryButton
} from '../../components'

const SectionHero = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={css.heroMainTitle}>
          Envie de respirer ?<br />
          Louer un jardin privé ou partager un potager.
        </h1>
        <h2 className={css.heroSubTitle}>
          <FormattedMessage id="SectionHero.subTitle" />
        </h2>
        <div className={css.sectionHeroSearch}>
            <NamedLink
              name="CategoryPage">
                <PrimaryButton className={css.buttonSearch}>
                  <FormattedMessage id="SectionHero.browseButton" />
                </PrimaryButton>
            </NamedLink>
        </div>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
