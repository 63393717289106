import { createStripeAccount } from './stripe.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/PayoutPreferencesPage/SET_INITIAL_STATE';
export const SAVE_PAYOUT_DETAILS_REQUEST = 'app/PayoutPreferencesPage/SAVE_PAYOUT_DETAILS_REQUEST';
export const LOADING_DATA_IN_PROGRESS = 'app/PayoutPreferencesPage/LOADING_DATA_IN_PROGRESS';
export const SAVE_PAYOUT_DETAILS_SUCCESS = 'app/PayoutPreferencesPage/SAVE_PAYOUT_DETAILS_SUCCESS';
export const SAVE_PAYOUT_DETAILS_ERROR = 'app/PayoutPreferencesPage/SAVE_PAYOUT_DETAILS_ERROR';

// ================ Reducer ================ //

const initialState = {
  payoutDetailsSaveInProgress: false,
  loadDataInProgress: false,
  payoutDetailsSaved: false,
  stripeAccountData: null
};

export default function payoutPreferencesPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState, ...{ stripeAccountData: payload, loadDataInProgress: false } };
    case LOADING_DATA_IN_PROGRESS:
      return { ...state, loadDataInProgress: true };
    case SAVE_PAYOUT_DETAILS_REQUEST:
      return { ...state, payoutDetailsSaveInProgress: true, loadDataInProgress: false };
    case SAVE_PAYOUT_DETAILS_ERROR:
      return { ...state, payoutDetailsSaveInProgress: false, loadDataInProgress: false };
    case SAVE_PAYOUT_DETAILS_SUCCESS:
      return { ...state, payoutDetailsSaveInProgress: false, payoutDetailsSaved: true, loadDataInProgress: false };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = (data) => ({
  type: SET_INITIAL_STATE,
  payload: data
});

export const savePayoutDetailsRequest = () => ({
  type: SAVE_PAYOUT_DETAILS_REQUEST,
});
export const loadingDataRequest = () => ({
  type: LOADING_DATA_IN_PROGRESS,
});

export const savePayoutDetailsError = () => ({
  type: SAVE_PAYOUT_DETAILS_ERROR
});
export const savePayoutDetailsSuccess = () => ({
  type: SAVE_PAYOUT_DETAILS_SUCCESS,
});

// ================ Thunks ================ //

export const savePayoutDetails = values => (dispatch, getState, sdk) => {
  dispatch(savePayoutDetailsRequest());

  if (values.method === "UPDATE") {
    if (values.accountType === 'individual') {
      let individual = getState().PayoutPreferencesPage.stripeAccountData.individual
      let hasFrontModify = (values.individual && values.individual.verification && values.individual.verification.document.front) ===
        (individual && individual.verification && individual.verification.document.front);
      let hasBackModify = (values.individual && values.individual.verification && values.individual.verification.document.back) ===
        (individual && individual.verification && individual.verification.document.back);
      let hasAddressModify = (values.individual && values.individual.verification && values.individual.verification.additional_document.front) ===
        (individual && individual.verification && individual.verification.additional_document.front);

      if (hasFrontModify) {
        delete values.individual.verification.document.front
      }
      if (hasBackModify) {
        delete values.individual.verification.document.back
      }
      if (hasAddressModify) {
        delete values.individual.verification.additional_document.front
      }
    }
    if (values.accountType === 'company') {
      let company = getState().PayoutPreferencesPage.stripeAccountData.company
      let hasCompFile = (values.company && values.company.verification && values.company.verification.document.front) ===
        (company && company.verification && company.verification.document.front);

      if (hasCompFile) {
        delete values.company.verification.document.front
      }
    }
  }

  return dispatch(createStripeAccount(values))
    .then(() => {
      dispatch(savePayoutDetailsSuccess())
      dispatch(loadData());
    })
    .catch((err) => dispatch(savePayoutDetailsError(err)));
};

export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(loadingDataRequest());
  sdk.stripeAccount.fetch().then(res => {
    if (res.data) {
      dispatch(setInitialState(res.data.data.attributes.stripeAccountData));
    } else {
      dispatch(setInitialState(null));
    }
  }).catch(err => {
    dispatch(setInitialState(null));
  })
  // dispatch(setInitialState());
  return dispatch(fetchCurrentUser());
};
