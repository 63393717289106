import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  {
    id: 'default-paris',
    predictionPlace: {
      address: 'Paris, France',
      bounds: new LatLngBounds(new LatLng(48.85974113780296, 2.293156340372342), new LatLng(48.858611757032, 2.379330347328323)),
    },
  },
  {
    id: 'default-lyon',
    predictionPlace: {
      address: 'Lyon, France',
      bounds: new LatLngBounds(new LatLng(45.808215, 4.898391), new LatLng(45.707468, 4.771677)),
    },
  },
  {
    id: 'default-bordeaux',
    predictionPlace: {
      address: 'Bordeaux, France',
      bounds: new LatLngBounds(new LatLng(44.915545, 0.533675), new LatLng(44.81077, -0.638626)),
    },
  },
];
export default defaultLocations;
