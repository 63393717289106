import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput, FieldRadioButton } from '../../components';

// import garden_rent from './icons/garden_rent.svg'
// import garden_share from './icons/garden_share.svg'
// import tinyhouse from './icons/tinyhouse.svg'
// import classifields from './icons/classifields.svg'

import {ReactComponent as GardenRentIcon } from './icons/garden_rent.svg'
import {ReactComponent as GardenShareIcon } from './icons/garden_share.svg'
import {ReactComponent as TinyhouseIcon } from  './icons/tinyhouse.svg'
import {ReactComponent as ClassifieldsIcon } from './icons/classifields.svg'


import css from './EditListingDescriptionForm.module.css';

const TITLE_MAX_LENGTH = 60;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        category,
        garden_type,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        categoriesOptions,
        classifieldsTypeOptions,
        gardenTypeOptions,
        isNewListing,
        updateCurrenCategory,
        form
      } = formRenderProps;

      const [selectedCategory,  setSelectedCategory] = React.useState(category)
      const [selectedGardenType,  setSelectedGardenType] = React.useState(garden_type)

      const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
      const titlePlaceholderMessage = selectedCategory ? intl.formatMessage({
        id: 'EditListingDescriptionForm.titlePlaceholder_'+selectedCategory+ (selectedGardenType && selectedCategory === "garden_share" ? "_" + selectedGardenType:""),
      }): "...";
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });
      const descriptionPlaceholderMessage = selectedCategory ? intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder_'+selectedCategory+ (selectedGardenType && selectedCategory === "garden_share" ? "_" + selectedGardenType:""),
      }) : "...";
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const renderIcon = (key) => {
        switch (key) {
          case "garden_rent":
            return <GardenRentIcon style={{ height: 60}} />
          case "garden_share":
            return <GardenShareIcon style={{ height: 60}} />
          case "tinyhouse":
            return <TinyhouseIcon style={{ height: 60}} />
          case "classifields":
            return <ClassifieldsIcon style={{ height: 60}} />
          default:
            return <GardenRentIcon style={{ height: 60}} />
        }
      }

      const handleChangeCategory = (value) => {
        form.change('category', value);
        updateCurrenCategory(value)
      }

      const handleChangeGardenType = (value) => {
        console.log("ICI garden_type",value);
        form.change('garden_type', value);
        setSelectedGardenType(value);
        //updateCurrentGardenType(value)
      }

console.log("selectedGardenType", selectedGardenType);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}

          {
            isNewListing ? 
              <h2><FormattedMessage id="EditListingDescriptionForm.categoryLabel" /></h2>
            : <div className={css.titleIconWrapper}>
                <div style={{textAlign: "center"}}>
                  {renderIcon(category)} 
                    <h2><FormattedMessage id={`FieldCheckbox.${category}`} /></h2>
                </div>
                  
              </div>
          }
          
          <div className={css.categoryWrapper}>
              { isNewListing ? (
                categoriesOptions.map((item, i) => {
                  return <div className={css.categoryCard} key={i}>
                          {renderIcon(item.key)}
                          <p><FormattedMessage id={`FieldCheckbox.${item.key}`} /></p>
                          <div onClick={() => setSelectedCategory(item.key)}>
                          <FieldRadioButton
                              id={`${i}-category`}
                              className={css.checkbox}
                              name="category"
                              label={null}
                              value={item.key}
                              showAsRequired={true}
                              onChange={(e) => handleChangeCategory(e.target.value)}    
                          />
                          </div>
                        </div>
                })
              )
                : null 
              }
            </div>
            <div style={{display: selectedCategory === "garden_share" ? "block" : "none"}}>
              <p><FormattedMessage id="EditListingDescriptionForm.gardenType" /></p>
              <div className={css.sectionRadio}>
              {
                  gardenTypeOptions.map((item, i) => {
                    return <div onClick={() => setSelectedGardenType(item.key)}>
                      <FieldRadioButton
                            key={i}
                            id={`${i}-gardenType`}
                            className={css.checkbox}
                            name="garden_type"
                            label={intl.formatMessage({id: `FieldCheckbox.${[item.key]}`})}
                            value={item.key}
                            showAsRequired={true}    
                            onChange={(e) => handleChangeGardenType(e.target.value)}    
                        />
                        </div>
                    })
                }
              </div>
          </div>
          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            autoFocus
          />

          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={composeValidators(required(descriptionRequiredMessage))}
          />

          <div style={{display: selectedCategory === "classifields" ? "block" : "none"}}>
            <p><FormattedMessage id="EditListingDescriptionForm.classifieldsType" /></p>
            <div className={css.sectionRadio}>
            {
              classifieldsTypeOptions.map((item, i) => {
                  return <FieldRadioButton
                          key={i}
                          id={`${i}-classifields_type`}
                          className={css.checkbox}
                          name="classifields_type"
                          label={intl.formatMessage({id: `FieldCheckbox.${[item.key]}`})}
                          value={item.key}
                          showAsRequired={true}    
                      />
                })
            }
            </div>
          </div>

          
          
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
