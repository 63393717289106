import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import {
  intlShape,
  injectIntl,
  FormattedMessage,
} from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';
import { Form, Button, FieldSelect, FieldTextInput, FieldRadioButton } from '../../components';




// Create this file using EditListingCapacityForm.module.css
import css from './EditListingCapacityForm.module.css';


/**
 * Fieldname: 
 *  - capacity_surface
 *  - capacity_capacity
 *  - capacity_acces
 *  - capacity_surface_tinyhouse
 * 
 */
export const EditListingCapacityFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        handleSubmit,
        category,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateError,
        updateInProgress,
      } = formRenderProps;

      const errorMessage = updateError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingCapacityForm.updateFailed" />
        </p>
      ) : null;

      const capacityRequired = required(
        intl.formatMessage({
          id: 'EditListingCapacityForm.capacityRequired',
        })
      );

      const classes = classNames(css.root, className);
      const submitReady = updated && pristine;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const surfaceTitle = (category) => {
        switch (category) {
          case 'garden_rent':
            return intl.formatMessage({ id:"EditListingCapacityForm.surfaceLabelRent"})
          case 'garden_share':
            return intl.formatMessage({ id:"EditListingCapacityForm.surfaceLabelShare"})
          case 'tinyhouse':
            return intl.formatMessage({ id:"EditListingCapacityForm.surfaceLabelRent"})
          default:
            return 'Surface'
        }
      } 

      const accessHandicap = <div>
        <p>
          <FormattedMessage id="EditListingCapacityForm.capacityAccessLabel"/>
        </p>
        <div className={css.sectionAccess}>
          <FieldRadioButton
            id={`option-yes`}
            name="capacity_access"
            label={intl.formatMessage({id:"FieldBoolean.yes"})}
            value="yes"
            showAsRequired={true}
          />
          <FieldRadioButton
            id={`option-no`}
            name="capacity_access"
            label={intl.formatMessage({id:"FieldBoolean.no"})}
            value="no"
            showAsRequired={true}
          />
        </div>
      </div>

      if(category === "garden_share"){
        return <Form className={classes} onSubmit={handleSubmit}>
        {errorMessage}
        <FieldTextInput
            className={css.field}
            type="number"
            id={`capacity_surface_garden`}
            name="capacity_surface_garden"
            label={surfaceTitle(category)}
            placeholder="Ex: 5000m2"
          />        
          <br />  
        {accessHandicap}
        <Button
          className={css.submitButton}
          type="submit"
          inProgress={submitInProgress}
          disabled={submitDisabled}
          ready={submitReady}
        >
          {saveActionMsg}
        </Button>
      </Form>
      }

      if(category === "tinyhouse"){
        return <Form className={classes} onSubmit={handleSubmit}>
            {errorMessage}
                <FieldTextInput
                  className={css.field}
                  type="number"
                  id={`capacity_max_people`}
                  name="capacity_max_people"
                  label={intl.formatMessage({id:"EditListingCapacityForm.capacityLabel"}) }
                  placeholder="Ex: 2 personnes"
                />
                <br />
                <FieldTextInput
                  className={css.field}
                  type="text"
                  type="number"
                  id={`capacity_surface_tinyhouse`}
                  name="capacity_surface_tinyhouse"
                  label={intl.formatMessage({ id:"EditListingCapacityForm.surfaceLabelTinyhouse"})}
                  placeholder="Ex: 25m2"
                />
                <br />
                <FieldTextInput
                  className={css.field}
                  type="number"
                  id={`capacity_surface_garden`}
                  name="capacity_surface_garden"
                  label={surfaceTitle(category)}
                  placeholder="Ex: 5000m2"
                />
                <br />
          
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
        </Button>
      </Form>
      }

      if(category === "garden_rent"){
        return <Form className={classes} onSubmit={handleSubmit}>
        {errorMessage}
            <FieldTextInput
              className={css.field}
              type="number"
              id={`capacity_surface_garden`}
              name="capacity_surface_garden"
              label={surfaceTitle(category)}
              placeholder="Ex: 5000m2"
            />
            <br />
            <FieldTextInput
              className={css.field}
              type="number"
              id={`capacity_max_people`}
              name="capacity_max_people"
              label={intl.formatMessage({id:"EditListingCapacityForm.capacityLabel"}) }
              placeholder="Ex: 20 personnes"
            />
            <br />
          {accessHandicap}
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
          {saveActionMsg}
        </Button>
      </Form>
      }
    }}
  />
)

EditListingCapacityFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingCapacityFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool.isRequired,
  capacityOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
};

export default compose(injectIntl)(EditListingCapacityFormComponent);