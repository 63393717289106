import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { bool, object, string } from 'prop-types';
import * as validators from '../../util/validators';
import { FieldSelect, FieldTextInput } from '../../components';
import Cities from '../../assets/data/cities.json'

import { stripeCountryConfigs } from './PayoutDetailsForm';
import { CA_PROVINCES, US_STATES, AU_STATES } from './statesAndProvinces';
import css from './PayoutDetailsForm.module.css';


const PayoutDetailsAddress = props => {
  const { className, country, intl, disabled, form, fieldId } = props;



  const countryConfig = country ? stripeCountryConfigs(country).addressConfig : null;

  const isRequired = (countryConfig, field) => {
    return countryConfig[field];
  }

  const showTitle =
    fieldId === 'company.address' ||
    fieldId === 'individual' ||
    fieldId === 'company.personalAddress';
  const addressTitle = intl.formatMessage({
    id:
      fieldId === 'company.address'
        ? 'PayoutDetailsForm.companyAddressTitle'
        : 'PayoutDetailsForm.streetAddressLabel',
  });

  const showAddressLine = country && isRequired(countryConfig, 'addressLine');

  const streetAddressLabel = intl.formatMessage({
    id: 'PayoutDetailsForm.streetAddressLabel',
  });
  const streetAddressPlaceholder = intl.formatMessage({
    id: 'PayoutDetailsForm.streetAddressPlaceholder',
  });
  const streetAddressRequired = validators.required(
    intl.formatMessage({
      id: 'PayoutDetailsForm.streetAddressRequired',
    })
  );

  const showPostalCode = country && isRequired(countryConfig, 'postalCode');

  const postalCodeLabel = intl.formatMessage({ id: 'PayoutDetailsForm.postalCodeLabel' });
  const postalCodePlaceholder = intl.formatMessage({
    id: 'PayoutDetailsForm.postalCodePlaceholder',
  });
  const postalCodeRequired = validators.required(
    intl.formatMessage({
      id: 'PayoutDetailsForm.postalCodeRequired',
    })
  );

  const showCity = country && isRequired(countryConfig, 'city');

  const cityLabel = intl.formatMessage({ id: 'PayoutDetailsForm.cityLabel' });
  const cityPlaceholder = intl.formatMessage({ id: 'PayoutDetailsForm.cityPlaceholder' });
  const cityRequired = validators.required(
    intl.formatMessage({
      id: 'PayoutDetailsForm.cityRequired',
    })
  )

  const getCities = (value) => {
    form.change(`${fieldId}.postalCode`, value)
    for (let i = 0; i < Cities.length; i++) {
      if(Cities[i].zip_code === value){
        form.change(`${fieldId}.city`, Cities[i].name)
      }
    }
  }

  return (
    <div className={className ? className : css.sectionContainer}>
      {showAddressLine ? (
        <FieldTextInput
          id={`${fieldId}.streetAddress`}
          name={`${fieldId}.streetAddress`}
          disabled={disabled}
          className={css.field}
          type="text"
          autoComplete="street-address"
          label={streetAddressLabel}
          placeholder={streetAddressPlaceholder}
          validate={streetAddressRequired}
          onUnmount={() => form.change(`${fieldId}.streetAddress`, undefined)}
        />
      ) : null}
      <div className={css.formRow}>
        {showPostalCode ? (
          <FieldTextInput
            id={`${fieldId}.postalCode`}
            name={`${fieldId}.postalCode`}
            disabled={disabled}
            className={css.postalCode}
            type="text"
            autoComplete="postal-code"
            label={postalCodeLabel}
            placeholder={postalCodePlaceholder}
            validate={postalCodeRequired}
            onChange={(e) => getCities(e.target.value)}
            onUnmount={() => form.change(`${fieldId}.postalCode`, undefined)}
          />
        ) : null}
        {showCity ? (
          <FieldTextInput
            id={`${fieldId}.city`}
            name={`${fieldId}.city`}
            disabled={disabled}
            className={css.city}
            type="text"
            autoComplete="address-level2"
            label={cityLabel}
            placeholder={cityPlaceholder}
            validate={cityRequired}
            onUnmount={() => form.change(`${fieldId}.city`, undefined)}
          />
        ) : null}
      </div>
    </div>
  );
};
PayoutDetailsAddress.defaultProps = {
  country: null,
  disabled: false,
  fieldId: null,
};

PayoutDetailsAddress.propTypes = {
  country: string,
  disabled: bool,
  form: object.isRequired,
  fieldId: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default PayoutDetailsAddress;
