import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Button, Form, FieldCurrencyInput, FieldCheckboxGroup, FieldRadioButton, FieldTextInput } from '../../components';
import css from './EditListingPricingForm.module.css';
import { findOptionsForSelectFilter } from '../../util/search';

const { Money } = sdkTypes;

export const EditListingPricingFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        category,
        values
      } = formRenderProps;

      const unitType = config.bookingUnitType;
      const isNightly = unitType === LINE_ITEM_NIGHT;
      const isDaily = unitType === LINE_ITEM_DAY;

      const translationKey = category === "classifields"
        ? 'EditListingPricingForm.classifields'
        : category === "tinyhouse"
        ? 'EditListingPricingForm.pricePerNight'
        : 'EditListingPricingForm.pricePerUnit'

      const pricePerUnitMessage = intl.formatMessage({
        id: translationKey,
      });

      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.priceInputPlaceholder',
      });

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      const optionsPrices = findOptionsForSelectFilter('price_extras', config.custom.filters);
      const optionsPricesTinyhouse = findOptionsForSelectFilter('price_extras_tinyhouse', config.custom.filters);
      const optionsPriceTerms = findOptionsForSelectFilter('price_terms', config.custom.filters);

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <h2 className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </h2>
          ) : null}
          {showListingsError ? (
            <h2 className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </h2>
          ) : null}
          

          <div>
           <p><FormattedMessage id={pricePerUnitMessage} /></p>
            <FieldCurrencyInput
              id="price"
              name="price"
              className={css.priceInput}
              autoFocus
              placeholder={pricePlaceholderMessage}
              currencyConfig={config.currencyConfig}
              validate={priceValidators}
            />
            { category === "garden_rent" && <p className={css.priceTips}><FormattedMessage id="EditListingPricingForm.priceTips" /></p> }
          </div>

          {
            category !== "classifields" &&
            <div>
              <h2><FormattedMessage id="EditListingPricingForm.labelDeposit" /></h2>
              <FieldCurrencyInput
                id="deposit"
                name="deposit"
                className={css.priceInput}
                autoFocus
                placeholder="Définir votre montant"
                currencyConfig={config.currencyConfig}
              />
              <p className={css.priceTips}><FormattedMessage id={`EditListingPricingForm.${category === 'garden_rent' ? 'textDeposit' : 'textDepositTiny'}`} /></p>
            </div>
          }
          {
            category !== "classifields" &&
            <div>
              <h2>
              <FormattedMessage id="EditListingPricingForm.labelExtra" />
              </h2>
              <FieldCheckboxGroup name="extra_fee" id="extra_fee" options={category === 'tinyhouse' ? optionsPricesTinyhouse : optionsPrices}/>
            </div>
          }
          {
            category !== "classifields" &&
            <div>
              <h2><FormattedMessage id="EditListingPricingForm.labelTerms" />*</h2>
              <div className={css.sectionRadio}>
                {
                  optionsPriceTerms.map((item, i) => {
                    return <FieldRadioButton
                              key={i}
                              id={item.key}
                              className={css.checkbox}
                              name="price_terms"
                              label={<FormattedMessage id={`FieldCheckbox.${item.key}`} />}
                              value={item.key}
                              showAsRequired={true}
                          />
                  })
                }
              </div>
            </div>
          }
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
