import React from 'react';

import {
  FieldSelect
} from '../../components';


    const BookingTimeArriving = (props) => {
      const {availabilityFrom, availabilityTo} = props
      
      let times=[];
      var afi = parseInt(availabilityFrom);
      var ati = parseInt(availabilityTo)-1;
      for (let hour = afi; hour <= ati; hour++) {
            times.push({key:hour, value:(hour + "H00")});
      }

      return <FieldSelect id="time_arriving" name="time_arriving" label="Heure d'arrivée*">
      <option value="">Selectionner l'heure</option>
      {
        times.map((time, i) => {
          return <option value={time.key}>{time.value}</option>
        })
      }
</FieldSelect>


    }
export default BookingTimeArriving;
