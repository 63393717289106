import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
<p>
JARDINS PRIVES accorde une grande importance à la vie privée de ses Utilisateurs et respecte le cadre juridique en vigueur à cet égard, notamment le règlement général sur la protection de données n°2016/679 (dit « RGPD »).

</p>

<p>JARDINS PRIVES, est responsable du traitement des données personnelles concernant la gestion des Comptes Utilisateurs et prospects à qui elle propose ses services, dans le cas de l’utilisation de la Plateforme à savoir  :</p>
<ul>
  <li> - aux fins d’assurer l’inscription de l’Utilisateur à la Plateforme et de permettre son identification et l’ouverture d’une session personnalisée sur la Plateforme et la gestion du Compte utilisateur (certains services requièrent une identification en vue de leur utilisation),</li>
  <li> - aux fins de permettre à l’Utilisateur de publier et accéder aux avis publiés sur la Plateforme,</li>
  <li> - aux fins du paiement éventuel en cas de transaction sur la Plateforme,</li>
  <li> - aux fins d’assurer la transmission des messages échangés via la Plateforme ;</li>
</ul>

<p>de la géolocalisation des personnes et des Biens ;</p>     

<ul>
  <li> - aux fins d’améliorer les fonctionnalités de la Plateforme et de faciliter la navigation et le confort de navigation sur celle-ci, afin de répondre au mieux aux attentes des Utilisateurs</li>
  <li> - aux fins légitimes d’envoi des messages marketing, publicitaires, promotionnels ou toutes autres informations aux Utilisateurs en relation avec la Plateforme.</li>

</ul>

<p>L’Utilisateur  s’engage à ne communiquer que des informations réelles, loyales et licites, et tiendra informé JARDINS PRIVES de toute modification ou changement les concernant.</p>

<p>Dans le cadre de l’utilisation de la Plateforme, JARDINS PRIVES  peut être amenée à communiquer certaines données personnelles collectées via la Plateforme à des tiers dans les cas suivants :  </p>
<ul>
  <li> - Lorsque JARDINS PRIVES  recourt aux services de prestataires techniques qui l’aident à fournir les services et plus précisément pour : (i) le développement, l’infogérance et la maintenance de la Plateforme, (ii) l’hébergement de la Plateforme, (iii) l’envoi de messages électroniques via le la Plateforme et (iv) l’encaissement pour compte de tiers. Ces prestataires disposent d’un accès limité aux données personnelles des Utilisateurs, dans le cadre de l’exécution de leurs prestations respectives ;</li>
  <li> - lorsque la loi ou un règlement l’exige, pour donner suite aux injonctions des autorités administratives et/ou judiciaires autorisées.</li>

</ul>

<p>JARDINS PRIVES conserve les informations personnelles des Utilisateurs pour la durée strictement nécessaire à la gestion de la relation commerciale. Cependant, toutes données permettant d’établir la preuve d’un droit ou d’un contrat, ou conservées au titre du respect d’une obligation légale ou règlementaire, peuvent faire l’objet d’une politique d’archivage, et être conservées à cette fin conformément aux dispositions en vigueur (délais de prescription, obligations légales de conservation, etc.).</p>
<p>Les données personnelles de prospects non clients sont conservées trois (3) ans à compter de leur collecte ou du dernier contact émanant du prospect, sauf accord pour une durée plus longue.</p>

<p>
L’Utilisateur dispose d’un droit d’accès, de rectification, ou d’effacement des données personnelles le concernant, et le cas échéant, d’un droit à la portabilité de ses données, et du droit de ne pas faire l’objet d’une décision individuelle automatisée (y compris le profilage). L’Utilisateur peut demander la limitation du traitement, s’opposer au traitement de ses données, ou le cas échéant retirer son consentement à leur traitement. L’Utilisateur dispose également du droit de définir des directives sur le sort de ses données après sa mort.

</p>
<p>
Pour exercer ces droits, l’Utilisateur peut s’adresser à :<br />
Par courrier : Jardins privés – 9 rue Anatole de la Forge – 75017 Paris<br />
Par courrier électronique : <a href="mailto:contact@jardins-prives.com">contact@jardins-prives.com</a><br />


</p>

<p>
L’Utilisateur dispose du droit d’introduire une réclamation auprès d’une autorité de contrôle  <a href="https://www.cnil.fr/fr/plaintes" target="_blank">https://www.cnil.fr/fr/plaintes</a>

</p>


<p>
En revanche, les Utilisateurs assument la responsabilité afférente aux contenus qu’ils traitent en relation avec leur utilisation de la Plateforme. 
Les Utilisateurs sont responsables de traitement s’agissant des traitements de données à caractère personnel qu’ils réalisent en 
relation avec leur Annonces, leurs Réservation et leur utilisation de la Plateforme (messagerie, avis, etc.). JARDINS PRIVES, simple prestataire technique, a dans ce contexte la qualité de sous-traitant, et n’utilise les données que pour l’usage technique nécessaire à la mise en œuvre de la Plateforme.

</p>

<p>
  JARDINS PRIVES en sa qualité de sous-traitant, s’engage à :
  </p>
  <ul>
    <li> - 
      n’agir que sur instruction documentée de l’Utilisateur dans le cadre de l’utilisation de la Plateforme,
    </li>
    <li> - 
    assurer la sécurité des données conformément à l’art. 32 RGPD,
    </li>
    <li> - 
    s’assurer que son personnel est tenu par une obligation de confidentialité,
    </li>
    <li> - 
    à notifier dans des délais raisonnables en cas de violation de données personnelles, à aider l’Utilisateur à respecter ses obligations au titre du RGPD, dans la mesure du possible et dans un délai raisonnable
    </li>
  </ul>



<p>
Un cookie est un petit fichier texte qui peut être déposé sur un équipement terminal à l'occasion de la consultation d'un site Internet. Un fichier cookie permet à son émetteur d'identifier l’équipement terminal dans lequel il est enregistré, pendant la durée de validité ou d'enregistrement dudit cookie..
</p>   

<p>
  JARDINS PRIVES utilise plusieurs types de cookies
  <ul>
    <li> - 
    Les cookies nécessaires au fonctionnement de la Plateforme : ces cookies, comme les identifiants de session, permettent à l’Utilisateur d'utiliser les fonctionnalités de la Plateforme et de sécuriser la connexion de l’Utilisateur. Sans ces cookies l’Utilisateur ne peut utiliser normalement le Site. Nous déconseillons de les supprimer.
    </li>
    <li> - 
    Les cookies de fonctionnalités de la Plateforme : ils ne sont pas indispensables à la navigation sur la Plateforme mais permettent d'en optimiser le fonctionnement et de donner accès à des fonctionnalités spécifiques. Ils permettent également d'adapter la présentation de la Plateforme aux préférences d'affichage de l’équipement terminal, et d'avoir une navigation fluide et sur mesure. Des exemples de cookies de fonctionnalités : langue utilisée, résolution d'affichage, système d'exploitation utilisé, annonces sauvegardées etc.
    </li>
    <li> - 
    Les cookies de mesure d’audience de la Plateforme : Il s'agit de cookies qui permettent de connaître l'utilisation et les performances de la Plateforme et d'établir des statistiques, des volumes de fréquentation et d'utilisation des divers éléments de la Plateforme (contenus visités, parcours) permettant ainsi d'améliorer l'ergonomie de la Plateforme et l’expérience de navigation.
    </li>
  </ul>
</p>


  <p>
  L’Utilisateur donne son  accord au dépôt des Cookies, lorsqu’il clique sur le bouton « j’accepte» du bandeau l’informant de l’utilisation de Cookies, ou lorsqu’il poursuit sa navigation sur les pages de la Plateforme.  

  </p>

  <p>
  Dans ces cas, l’Utilisateur reconnaît avoir expressément donné son consentement au dépôt de l’ensemble des Cookies déposés ou lus sur la Plateforme.

  </p>

  <p>
  Cet accord n’est valable que pour une durée de treize mois à compter du premier dépôt dans l’équipement terminal, sauf retrait du consentement de la part de l’Utilisateur ou exercice de son droit d’opposition.

  </p>

  <p>
  L’Utilisateur peut décider de bloquer les cookies, ou demander à son navigateur Internet de l’avertir lorsqu'un site tente de déposer un cookie sur son équipement terminal.

  </p>

  <p>
  Pour changer la gestion des cookies, il est possible de modifier les réglages dans l'onglet « confidentialité » ou équivalent du navigateur.

  </p>
  <p>
  Attention, certaines fonctionnalités de la Plateforme peuvent ne plus fonctionner.

  </p>
  <p>
  La configuration de chaque navigateur est différente. Elle est décrite dans le menu d'aide du navigateur, qui permettra de savoir de quelle manière modifier les souhaits en matière de cookies."

  </p>

  </div>
  )
}

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
