import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './BookingBreakdown.module.css';


const LineItemChildMaybe = props => {
  const {transaction} = props

  if(!transaction){
    return null
  }
  const childs1 = transaction.attributes.protectedData && 
      transaction.attributes.protectedData.nb_child ? 
      transaction.attributes.protectedData.nb_child : null
      
  const childs2 = transaction.attributes.lineItems[0] && 
      transaction.attributes.lineItems[0].nbChild ? 
      transaction.attributes.lineItems[0].nbChild : null 

  if(!childs1 && !childs2) return null 

  return <div className={css.lineItem}>
              <span className={css.itemLabel}>
                <FormattedMessage id={(childs1 || childs2) > 1 ? `BookingBreakdown.childs_fee` : `BookingBreakdown.child_fee`} />
              </span>
              <span className={css.itemValue}>{childs1 || childs2}</span>
            </div>
};

export default LineItemChildMaybe;
