import React from 'react';
import clsx from 'clsx';
import classNames from 'classnames';
import { Drawer, makeStyles } from '@material-ui/core';
import { FormattedMessage } from '../../util/reactIntl';
import { List, ListItem, IconButton, Divider } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/MenuOutlined'
import CloseIcon from '@material-ui/icons/CloseOutlined'


import ExitToAppOutlined from '@material-ui/icons/ExitToAppOutlined'
import {
  Logo
} from '../../components';


import {
  NamedLink,
  InlineTextButton
} from '../../components';


import css from './Drawer.module.css';

const drawerWidth = 350;
const drawerMobileWidth = 250;

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    minWidth: drawerWidth,
    paddingLeft: 0,
    [theme.breakpoints.down('sm')]: {
      width: "auto"
    },
  },
  drawerPaperMobile: {
    minWidth: drawerMobileWidth,
    paddingLeft: 0,
    [theme.breakpoints.down('sm')]: {
      width: "auto"
    },
  }
}));

const MyDrawer = (props) => {
  const {
    onLogout,
    isAuthenticatedOrJustHydrated,
    user,
    intl,
    currentPage,
    transactionsCount,
    isMobileLayout,
    showAddListing,
    mounted } = props
  const [open, setOpen] = React.useState(false)
  const classes = useStyles();

  const list = () => (
    <List>
      <ListItem>
        <NamedLink
          className={css.menuItemBorder}
          name="LandingPage"
        >
          <span />
          <FormattedMessage id="Drawer.menuHome" />
        </NamedLink>
      </ListItem>
      <ListItem>
        <NamedLink
          className={css.menuItemBorder}
          name="SearchPage"
          to={{ search: `?pub_category=garden_rent` }}>
          <span />
          <FormattedMessage id="Drawer.menuList" />
        </NamedLink>
      </ListItem>
      <ListItem>
        <NamedLink
          className={css.menuItemBorder}
          name="HowItWorkPage"
        >
          <span />
          <FormattedMessage id="Drawer.menuHowItWork" />
        </NamedLink>
      </ListItem>
      <ListItem>
        <NamedLink
          className={css.menuItemBorder}
          name="AboutPage"
        >
          <span />
          <FormattedMessage id="Drawer.menuAbout" />
        </NamedLink>
      </ListItem>
      <ListItem>
        <NamedLink
          className={css.menuItemBorder}
          name="FaqPage"
        >
          <span />
          <FormattedMessage id="Drawer.menuSupport" />
        </NamedLink>
      </ListItem>
    </List>
  )

  const signUp = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink
      className={css.menuItemSign}
      name="SignupPage"
    >
      <span />
      <FormattedMessage id={`TopbarDesktop.signup`} />
    </NamedLink>
  )
  const signIn = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink
      className={css.menuItemSign}
      name="LoginPage" >
      <span />
      <FormattedMessage id={`TopbarDesktop.login`} />
    </NamedLink>
  )

  const profil = !isAuthenticatedOrJustHydrated ? null : (
    <>
      <NamedLink
        className={css.logo}
        name="LandingPage"
      >
        <Logo format="mobile" />

      </NamedLink>
      {
        showAddListing &&
        <NamedLink
          className={css.newListing}
          name="NewListingPage"
        >
          <FormattedMessage id="TopbarDesktop.createListing" />
        </NamedLink>
      }
    </>
  )

  const loggedInMenu = !isAuthenticatedOrJustHydrated ? null : (
    <>
      <Divider />
      <h2 className={css.h2}><FormattedMessage id="Drawer.user" /></h2>
      <ListItem>
        <NamedLink
          className={css.menuItemBorder}
          name="ProfileSettingsPage"
        >
          <span />
          <FormattedMessage id="Drawer.menuProfile" />
        </NamedLink>
      </ListItem>
      <ListItem>
        <NamedLink
          className={css.menuItemBorder}
          name="InboxPage"
          params={{ tab: 'demandes-en-cours' }}
        >
          <span />
          <FormattedMessage id="Drawer.menuInbox" />
        </NamedLink>
      </ListItem>
      <ListItem>
        <NamedLink
          className={css.menuItemBorder}
          name="ManageListingsPage"
        >
          <span />
          <FormattedMessage id="Drawer.menuAnnonce" />
        </NamedLink>
      </ListItem>
      <ListItem>
        <NamedLink
          className={css.menuItemBorder}
          name="BookingListingsPage"
          params={{ tab: "attente" }}
        >
          <span />

          <FormattedMessage id="Drawer.menuBooking" />
        </NamedLink>
        <span style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'red',
          borderRadius: "50%",
          width: 20,
          height: 20,
          marginLeft: 10,
          color: 'white'
        }}>
          {transactionsCount}
        </span>
      </ListItem>
      <ListItem>
        <NamedLink
          className={css.menuItemBorder}
          name="AccountSettingsPage"
        >
          <span />
          <FormattedMessage id="Drawer.menuParams" />
        </NamedLink>
      </ListItem>
      <ListItem>

        <InlineTextButton rootClassName={css.logoutButton} onClick={() => onLogout()}>
          <ExitToAppOutlined />
          <FormattedMessage id="TopbarDesktop.logout" />
        </InlineTextButton>
      </ListItem>
    </>
  )

  return (mounted ?
    <>
      <IconButton onClick={() => setOpen(true)}>
        {isMobileLayout ? <MenuIcon style={{ width: '1.5em', height: '1.5em' }} /> : <MenuIcon />}
      </IconButton>
      {!isMobileLayout && <span style={{ fontSize: 13 }}><FormattedMessage id="Drawer.menu" /></span>}
      <Drawer
        classes={{
          paper: isMobileLayout ? classes.drawerPaperMobile : classes.drawerPaper
        }}
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
      // onOpen={() => setOpen( true)}
      >

        <>
          <IconButton className={css.closeBtn} onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>

          <div className={css.header}>
            {signUp}
            {signIn}
            {profil}
          </div>
          <div
            className={clsx(css.list)}
            role="presentation"
            onClick={() => setOpen(false)}
            onKeyDown={() => setOpen(false)}
          >
            {!isMobileLayout &&
              <h2 className={css.h2}><FormattedMessage id="Drawer.menu" /></h2>
            }
            {list()}
            <div className={css.hide}>
              {loggedInMenu}
            </div>
          </div>
        </>

      </Drawer>
    </> : <></>
  );
}

export default MyDrawer;
