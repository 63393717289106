import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput, NamedLink } from '../../components';
import * as validators from '../../util/validators';
import {ReactComponent as EyePassword} from '../../assets/icons/003_Compte/eye_password.svg'
import {ReactComponent as EyePasswordView} from '../../assets/icons/003_Compte/eye_password_view.svg'

import css from './LoginForm.module.css';

const LoginFormComponent = props => {

  const [showPassword, setShowPassword] = React.useState(false)
  return <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        isError,
        handleSubmit,
        inProgress,
        intl,
        invalid,
      } = fieldRenderProps;

      // email
      const emailLabel = intl.formatMessage({
        id: 'LoginForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'LoginForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'LoginForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'LoginForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordLabel = intl.formatMessage({
        id: 'LoginForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'LoginForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'LoginForm.passwordRequired',
      });
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const passwordRecoveryLink = (
        <NamedLink name="PasswordRecoveryPage" className={css.recoveryLink}>
          <FormattedMessage id="LoginForm.forgotPassword" />
        </NamedLink>
      );

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <FieldTextInput
              style={{marginBottom: 10}}
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <label  className={css.label}>Mot de passe</label>
              <div className={css.passwordInputContainer}>
              <FieldTextInput
                // className={css.password}
                type={showPassword ? 'text' : 'password'}
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="current-password"
                label={null}
                placeholder={passwordPlaceholder}
                validate={passwordRequired}
              />
              <button className={css.passwordInputVisibleButton} onClick={()=>{setShowPassword(!showPassword);return false;}} type='button'>
                  {
                    !showPassword ? <EyePassword className={css.eyeViewIcon} /> : <EyePasswordView className={css.eyeViewIcon} />
                  }
                </button>
              </div>
            { isError ? <p className={css.error}>Votre compte ou mot de passe est incorrect.</p> : null}
          </div>

          <div className={css.bottomWrapper}>
            <p className={css.bottomWrapperText}>
              <span className={css.recoveryLinkInfo}>
                <FormattedMessage
                  id="LoginForm.forgotPasswordInfo"
                  values={{ passwordRecoveryLink }}
                />
              </span>
            </p>
            <div className={css.buttonWrapper}>
              <PrimaryButton  type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="LoginForm.logIn" />
              </PrimaryButton>
            </div>

          </div>
          <p className={css.signUpLink}>
          <FormattedMessage id="LoginForm.linkSignUp" />&nbsp;
            <NamedLink name="SignupPage" >
            <FormattedMessage id="SignupForm.signUp" />
          </NamedLink>
          </p>

        </Form>
      );
    }}
  />
}

LoginFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

LoginFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

const LoginForm = compose(injectIntl)(LoginFormComponent);
LoginForm.displayName = 'LoginForm';

export default LoginForm;
