import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldCheckbox, FieldRadioButton } from '../../components';
import { ReactComponent as EyePassword } from '../../assets/icons/003_Compte/eye_password.svg'
import { ReactComponent as EyePasswordView } from '../../assets/icons/003_Compte/eye_password_view.svg'
import css from './SignupForm.module.css';

const KEY_CODE_ENTER = 13;

const SignupFormComponent = props => {

  const [showPassword, setShowPassword] = React.useState(false)

  return <FinalForm
    {...props}
    // initialValues={{
    //   checkboxTerms: false
    // }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        values,
        onOpenTermsOfService,
      } = fieldRenderProps;

      console.log("values", values.checkboxTerms)

      // email
      const emailLabel = intl.formatMessage({
        id: 'SignupForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordLabel = intl.formatMessage({
        id: 'SignupForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'SignupForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      const termsLink = (
        <a href="/conditions-utilisation" target="_blank">
          <FormattedMessage id="ConfirmSignupForm.termsAndConditionsLinkText" />
        </a>
      );
      const privacyLink = (
        <a href="/politique-de-confidentialite" target="_blank">
          <FormattedMessage id="ConfirmSignupForm.privacyLinkText" />
        </a>
      );

      // firstName
      const firstNameLabel = intl.formatMessage({
        id: 'SignupForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.firstNameRequired',
      });

      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName
      const lastNameLabel = intl.formatMessage({
        id: 'SignupForm.lastNameLabel',
      });
      const lastNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lastNameRequired',
      });

      const ownerRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lastNameRequired',
      });

      const professionalRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lastNameRequired',
      });

      const lastNameRequired = validators.required(lastNameRequiredMessage);
      const ownerRequired = validators.required(ownerRequiredMessage);
      const professionalRequired = validators.required(professionalRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress || !values.checkboxTerms || !values.checkboxPrivacy;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <FieldTextInput
              type="text"
              id={formId ? `${formId}.lname` : 'lname'}
              name="lname"
              autoComplete="family-name"
              label={lastNameLabel}
              placeholder={lastNamePlaceholder}
              validate={lastNameRequired}
            />
            <FieldTextInput
              type="text"
              id={formId ? `${formId}.fname` : 'fname'}
              name="fname"
              autoComplete="given-name"
              label={firstNameLabel}
              placeholder={firstNamePlaceholder}
              validate={firstNameRequired}
            />
            <FieldTextInput
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />

            <label className={css.label}>Mot de passe</label>
            <div className={css.passwordInputContainer}>
              <FieldTextInput
                type={showPassword ? 'text' : 'password'}
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="new-password"
                label={null}
                placeholder={passwordPlaceholder}
                validate={passwordValidators}
              />
              <button className={css.passwordInputVisibleButton} onClick={() => { setShowPassword(!showPassword); return false; }} type='button'>
                {
                  !showPassword ? <EyePassword className={css.eyeViewIcon} /> : <EyePasswordView className={css.eyeViewIcon} />
                }
              </button>
            </div>
          </div>

          <div className={css.checkboxWrapper}>
            <p className={css.checkBoxTitle}><FormattedMessage id="SignupForm.IamOwner" /></p>
            <div className={css.checkBox}>
              {
                ["yes", "no"].map((item, i) => {
                  return <FieldRadioButton
                    key={i}
                    id={`${i}-light`}
                    className={css.checkBox}
                    name={"is_owner"}
                    label={<FormattedMessage id={`FieldCheckbox.${item}`} />}
                    value={item}
                    showAsRequired={true}
                    validate={ownerRequired}
                  />
                })
              }
            </div>
          </div>
          <div className={css.checkboxWrapper}>
            <p className={css.checkBoxTitle}><FormattedMessage id="SignupForm.IamProfessional" /></p>
            <div className={css.checkBox}>
              {
                ["yes", "no"].map((item, i) => {
                  return <FieldRadioButton
                    key={`${i}P`}
                    id={`${i}-lightP`}
                    className={css.checkBox}
                    name={"is_professional"}
                    label={<FormattedMessage id={`FieldCheckbox.${item}`} />}
                    value={item}
                    showAsRequired={true}
                    validate={professionalRequired}
                  />
                })
              }
            </div>
          </div>
          <div className={css.checkBoxTerms} style={{ marginTop: "10px" }}>
            <FieldCheckbox id="checkbox-terms"
              className={css.checkBoxTerms}
              name="checkboxTerms"
              showAsRequired={true}
              label={<FormattedMessage
                id="SignupForm.termsAndConditionsAcceptText"
                values={{ termsLink }}
              />}
            />

          </div>
          <div className={css.checkBoxTerms} >
            <FieldCheckbox id="checkbox-privacy"
              className={css.checkBoxTerms}
              name="checkboxPrivacy"
              showAsRequired={true}
              label={<FormattedMessage
                id="SignupForm.privacyAcceptText"
                values={{ privacyLink }}
              />}
            />

          </div>
          <div className={css.bottomWrapper}>
            <div className={css.buttonWrapper}>
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="SignupForm.signUp" />
              </PrimaryButton>
            </div>
          </div>
        </Form>
      );
    }}
  />
}

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
