import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config.js';
import { FormattedMessage } from '../../util/reactIntl';

import {
  Button
} from '../../components';

// CSS
import css from './FilterAmbiance.module.css'


const FilterAmbiance = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const tinyhouseOptions = findOptionsForSelectFilter(
    'ambiance_tinyhouse',
    config.custom.filters
  )

  const gardenOptions = findOptionsForSelectFilter(
    'ambiance_garden',
    config.custom.filters
  )

  const filters = props.category === "tinyhouse" ?
    [
      { key: null, label: "all" },
      ...tinyhouseOptions
    ]
    : [
      { key: null, label: "all" },
      ...gardenOptions
    ]

  const handleSearch = (key) => {
    props.handleChange("pub_ambiances", key)
  }

  const [checked, setChecked] = React.useState([]);

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }

    setChecked(updatedList);

    props.handleChange("pub_ambiances", updatedList.join(","))
  };

  // Generate string of checked items
  const checkedItems = checked.length
    ? checked.reduce((total, item) => {
      return total + ", " + item;
    })
    : "";


  if (props.isMobile) {
    return <div className="checkList" xs={12}>
      <div className="list-container" xs={12}>
        {filters.map((item, index) => (
          item.key &&
          <div key={index} style={{ marginBottom: "15px" }}>
            <label for={item.key} className={css.checkboxes}>
              <input type="checkbox" id={item.key} value={item.key} className={css.checkboxesInput} onChange={handleCheck} />
              {props.intl.formatMessage({ id: `FieldCheckbox.${[item.key || item.label]}` })}
            </label>

          </div>

        ))}
      </div>
    </div >
    /*

     <input value={item.key} type="checkbox" onChange={handleCheck} />
              {props.intl.formatMessage({ id: `FieldCheckbox.${[item.key || item.label]}` })}


              
    return <select onChange={(e) => handleSearch(e.target.value)}>
      {
        filters.map((item, i) => <option value={item.key} key={i}>
          {props.intl.formatMessage({ id: `FieldCheckbox.${[item.key || item.label]}` })}
        </option>
        )
      }
    </select>
    */
  }

  let classButton;
  if (props.ambiance) {
    classButton = css.buttonFilterActive
  } else {
    classButton = css.buttonFilter
  }

  return <div>
    <Button className={classButton} onClick={handleClick}>
      <FormattedMessage id={`FieldCheckbox.${props.ambiance || 'ambiance'}`} />
    </Button>
    <Menu
      id="ambiances-menu-filter"
      elevation={4}
      variant="menu"
      anchorEl={anchorEl}

      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {filters.map((item, i) => {
        return <MenuItem
          style={{ fontWeight: item.key === props.ambiance ? 600 : 400 }}
          onClick={() => handleSearch(item.key)} key={i} value={item.key} >
          <FormattedMessage id={`FieldCheckbox.${item.key || item.label}`} />
        </MenuItem>
      })
      }
    </Menu>
  </div>
}

export default FilterAmbiance;
