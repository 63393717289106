import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {
  Button,
} from '../../components';
import { FormattedMessage } from '../../util/reactIntl';


import css from './FilterMaxPeople.module.css'

const maxPeopleOptions = [
  {key: null, label: null },
  {key: "0,5", label: "1 - 5"},
  {key: "1,11", label: "5 - 10"},
  {key: "10,16", label: "10 - 15"},
  {key: "15,21", label: "15 - 20"},
  {key: "20,", label: "+20"},
]

const maxPeopleTinyhouseOptions = [
  {key: null, label: null },
  {key: "0,5", label: "1 - 2"},
  {key: "1,11", label: "2 - 4"},
]


const FilterMaxPeople = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const options = props.currentCategory === 'tinyhouse' ? maxPeopleTinyhouseOptions : maxPeopleOptions

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch =(key) => {
    props.handleChange("pub_capacity_max_people", key)
    handleClose()
  }

  const formatValue = (value) => {
    return options.filter(el => el.key === value )
  }

  

  if(props.isMobile) {
    return <select onChange={(e) => handleSearch(e.target.value)}>
        { 
          options.map((item, i) => <option value={item.key} key={i}>
                { item.label ? item.label  : props.intl.formatMessage({id:`FieldCheckbox.all`})} 
            </option>
          )
        }
    </select>
  }

  let classButton;
  if(props.maxPeople){
    classButton = css.buttonFilterActive
  }else{
    classButton = css.buttonFilter
  }


  return (<div>
        <Button className={classButton} onClick={handleClick}>
         { props.maxPeople ? formatValue(props.maxPeople)[0].label : <FormattedMessage id={`FieldCheckbox.maxPeople`} /> }
        </Button>
        <Menu
          id="max-capacity-menu-filter"
          elevation={4}
          variant="menu"
          anchorEl={anchorEl}
          // anchorOrigin={{
          //   vertical: 'bottom',
          //   horizontal: 'bottom',
          // }}
          // transformOrigin={{
          //   vertical: 'top',
          //   horizontal: 'bottom',
          // }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          { options.map((item, i) => {
              return  <MenuItem
                        style={{fontWeight: item.key === props.maxPeople ? 600 : 400}}
                        onClick={() => handleSearch(item.key)} key={i} value={item.key} >
                          { item.label ? item.label  : <FormattedMessage id={`FieldCheckbox.all`} />} 
                      </MenuItem>
            })
          }
        </Menu>
      </div>
  );
}

export default FilterMaxPeople;