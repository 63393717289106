
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ListingCard, PaginationLinks } from '../../components';
import css from './SearchResultsPanel.module.css';
import {Grid, Paper} from '@material-ui/core';


const SearchResultsPanel = props => {
  const { className, rootClassName, listings, pagination, search, setActiveListing, mapOpen} = props;
  const classes = classNames(rootClassName || css.root, className);
  const [spacing, setSpacing] = React.useState(2);

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  return (
    <Grid container className={css.root} spacing={2}>
          {listings.map((l, i) => (
            <Grid item xs={12} sm={mapOpen ? 12 : 6} md={mapOpen ? 6 : 3} lg={mapOpen ? 6 : 4} key={i}>
              <div className={css.paper}>
                <ListingCard
                  className={css.listingCard}
                  key={l.id.uuid}
                  listing={l}
                  renderSizes={cardRenderSizes}
                  setActiveListing={setActiveListing}
                />
              </div>
            </Grid>
          ))}
            {props.children}
          {paginationLinks}
      </Grid>
  )
}

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default SearchResultsPanel;