import React from 'react';
import css from './Filterm2.module.css'


const Filterm2 = (props) => {


  const handleSearch =(e) => {
    props.handleChange("pub_capacity_surface_garden", e.target.value)
  }

  return (<div className={css.root}>
          <input className={css.input} type="number" placeholder='m2' onChange={(e) => handleSearch(e)}/>
      </div>
  );
}

export default Filterm2;
