import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { PrimaryButton, SecondaryButton } from '../Button/Button';
import Switch from '@material-ui/core/Switch';
import css from './CookieConsent.module.css';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    maxWidth: '40%',
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
      },
  },
}));

const CookieModal = (props) => {
  const classes = useStyles();

  return <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.showModal}
        onClose={props.handleClose}
        closeAfterTransition
        // BackdropComponent={Backdrop}
        // BackdropProps={{
        //   timeout: 500,
        // }}
      >
          <div className={classes.paper}>
            <h2 style={{textAlign: 'center'}}>Gérer mes cookies</h2>
            <p>Notre site jardins-prives.com et nos partenaires utilisent<br/>- des Cookies de fonctionnalité qui permettent d'optimiser le fonctionnement et d'accéder à des fonctionnalités spécifiques. Ils permettent d'adapter la présentation aux préférences d'affichage de votre terminal, et d'avoir une navigation fluide et sur mesure. Exemples de cookies de fonctionnalité : langue utilisée, résolution d'affichage, système d'exploitation utilisé, publicités enregistrées, etc.<br/>- des Cookies de mesure d'audience qui permettent de connaître l'utilisation et les performances de la Plateforme et d'établir des statistiques, des volumes de visites (contenus visités, parcours) améliorant ainsi l'ergonomie de notre site et l'expérience de navigation.<br/><br/>Nous conservons votre choix pendant 13 mois.

            
            </p>
            <div className={css.switchContainer}>
                <div className={css.wrapperSwitch}>
                    <p>Securité</p>
                    <Switch
                        checked={props.security}
                        onChange={() => props.handleChange('security')}
                        name="security"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                </div>
                <div className={css.wrapperSwitch}>
                    <p>Expérience utilisateur</p>
                    <Switch
                        checked={props.ui}
                        onChange={() => props.handleChange('ui')}
                        name="ui"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                </div>
            
            </div>

            <div className={css.buttonWrapper}>
                <SecondaryButton style={{marginRight: 10}} onClick={() => props.refuseAll()}>Tout refuser</SecondaryButton>
                <SecondaryButton style={{marginRight: 10}} onClick={props.handleValidate}>Valider la sélection</SecondaryButton>
                <PrimaryButton onClick={() => props.acceptAll()}>Tout accepter</PrimaryButton>
            </div>
          </div>
      </Modal>
}

export default CookieModal;
