import React from "react";
import css from './BookingDatesForm.module.css';

const MyHiddenField = (props) => {

  return (
        <input id={props.name} name={props.id} value={props.currentValue || ""} type="hidden"  />
  );
}

export default MyHiddenField;
