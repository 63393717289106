

const socials = [
    'IG',
    'Ig',
    'FB',
    'fb',
    'Fb',
    'Instagram',
    'instagram',
    'Instagrame',
    'instagrame',
    'Instagramme',
    'instagramme',
    'Instagramm',
    'instagramm',
    'instagramme',
    'INSTA',
    'INSTAGRAM',
    'INSTAGRAMM',
    'INSTAGRAMME',
    'FACEBOOK',
    'facebook',
    'Facebook',
    'facebok',
    'facebouk',
    'FACEBOUK',
    'FACEBOUC',
    'facebouc',
    'FACEBOK',
    'F.B',
    'f.b',
    'F.b',
    'F-B',
    'f-b',
    'F-B',
    'F_B',
    'F_b',
    'f_b',
    'Tweeter',
    'TWEETER',
    'Twiter',
    'TWITET',
    'Tweter',
    'Tweeter',
    'Twitter',
    'TWITTER',
    'youtube',
    'Youtube',
    'YOUTUBE',
    'YOUtube',
    'Ytube']




export const sanitizeMessage = message => {
    const emailRegex = /[\w-\.]+@([\w-]+\.)+[\w-]{2,4}/g;
    const urlRegex = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/g;
    const urlRegex2 = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{2,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;
    const urlRegex3 = /[0-9][\./\\\- ]{0,2}[0-9][\./\\\- ]{0,2}[0-9][\./\\\- ]{0,2}[0-9][\./\\\- ]{0,2}[0-9][\./\\\- ]{0,2}[0-9][\./\\\- ]{0,2}[0-9][\./\\\- ]{0,2}[0-9]?[\./\\\- ]{0,2}[0-9]?[\./\\\- ]{0,2}[0-9]?/g;
    const urlRegex4 = /[0-9]{7,}/g;
    const letterNumber = ['zero', 'zéro', 'trois', 'quatre', 'cinq', 'six', 'sept', 'huit', 'neuf', 'dix'];
    const socials = ["facebook", "twitter", "instagram", "linkedin"]; // Add any other social keywords here
const phoneRegex = /(?:(?:\+|00)33[\s.\-x]{0,3}(?:\(0\)[\s.\-x]{0,3})?|0)[1-9](?:(?:[\s.\-x]?\d{2}){4}|\d{2}(?:[\s.\-x]?\d{3}){2})/g;

    const sanitize = msg => {
        let newMessage = msg.replace(emailRegex, " (Jardins Privés masque les coordonnées)")
            .replace(phoneRegex, " (Jardins Privés masque les coordonnées)")
            .replace(urlRegex, " (Jardins Privés masque les coordonnées)")
            .replace(urlRegex2, " (Jardins Privés masque les coordonnées)")
            .replace(urlRegex3, " (Jardins Privés masque les coordonnées)")
            .replace(urlRegex4, " (Jardins Privés masque les coordonnées)");

        for (let i = 0; i < letterNumber.length; i++) {
            newMessage = newMessage.replace(" " + letterNumber[i] + " ", " * ");
        }

        for (let i = 0; i < socials.length; i++) {
            let m = newMessage.split(' ');
            for (let x = 0; x < m.length; x++) {
                if (m[x].match(socials[i])) {
                    m[x] = '(Jardins Privés masque les coordonnées)';
                }
                if (m[x].match('@')) {
                    m[x] = "(Jardins Privés masque les coordonnées)";
                }
            }
            newMessage = m.join(' ');
        }

        return  newMessage;
    };

    if (Array.isArray(message)) {
        return message.map(msg => {
            if (msg && msg.attributes && typeof msg.attributes.content === 'string') {
                msg.attributes.content = sanitize(msg.attributes.content);
            }
            return msg;
        });
    } else {
        return sanitize(message);
    }
};





