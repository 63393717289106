import React from 'react'
import { FieldCheckboxGroup} from '../../components';
import { findOptionsForSelectFilter } from '../../util/search';

import css from './EditListingFeaturesForm.module.css';

const EditListingAmbiance = ({category, filterConfig}) => {
    const ambianceKey = "ambiances";

    const optionsAmbianceTinyhouse = findOptionsForSelectFilter('ambiance_tinyhouse', filterConfig);
    const optionsAmbianceGarden = findOptionsForSelectFilter('ambiance_garden', filterConfig);

    switch (category) {
        case "garden_rent":
            return <>
                <h2>Ambiances *</h2>
                <div className={css.sectionRadio}>
                {
                    optionsAmbianceGarden && 
                        <FieldCheckboxGroup 
                            className={css.features} 
                            id={`ambiances`}
                            name={ambianceKey}
                            options={optionsAmbianceGarden} 
                        />
                }
                </div>
             </>
        case "tinyhouse":
            return <><h2>Ambiances *</h2>
            <div className={css.sectionRadio}>
                {
                optionsAmbianceTinyhouse && 
                    <FieldCheckboxGroup 
                        className={css.features} 
                        id={`ambiances`}
                        name={ambianceKey}
                        options={optionsAmbianceTinyhouse} 
                    />
                }
            </div>
            </>
        default:
            return null 
    }
}


export default EditListingAmbiance;