import React from 'react';
import { Field } from 'react-final-form'
import css from './PayoutDetailsForm.module.css';
import MyFileInput from './MyFileInput'
import { FormattedMessage } from 'react-intl/dist/react-intl';


const PayoutDetailsAddressFile = props => {
  const {
    fieldId,
    disabled,
    initialValues,
    address_front,
    fieldRenderProps,
    stripeConnected,
    intl
  } = props;

  if (!fieldRenderProps) {

    return <></>;
  }

  const { form } = fieldRenderProps;
  return (
    <div className={css.sectionContainer}>
      <div style={{ paddingTop: 5 }}>
        <h3><FormattedMessage id="PayoutDetailsForm.addressFileTitle" /></h3>
        <p><FormattedMessage id="PayoutDetailsForm.addressFileSubTitle" /></p>
      </div>
      <Field name={fieldId}>
        {props => (
          <div>
            <MyFileInput
              form={form}
              stripeConnected={stripeConnected}
              title={intl.formatMessage({
                id: 'PayoutDetailsForm.addressFileInputTitle',
              })}
              name="address_front"
              span={intl.formatMessage({
                id: 'PayoutDetailsForm.addressFileInputSpan',
              })}
              paragraph={intl.formatMessage({
                id: 'PayoutDetailsForm.addressFileInputParagraph',
              })}
              hasFile={address_front}
              {...props.input}
            />
          </div>
        )}
      </Field>
    </div>
  );
};


export default PayoutDetailsAddressFile;
