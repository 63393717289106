import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ensureListing } from '../../util/data';
import { EditListingFeaturesForm } from '../../forms';

import css from './EditListingFeaturesPanel.module.css';

const EditListingFeaturesPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;

  const amenities_tinyhouse = publicData && publicData.amenities_tinyhouse;
  const amenities_garden_rent = publicData && publicData.amenities_garden_rent;
  const amenities_garden_share = publicData && publicData.amenities_garden_share;
  const games = publicData && publicData.games;
  const events = publicData && publicData.events;
  const pet = publicData && publicData.pet;
  const water_point = publicData && publicData.water_point;
  const night_light = publicData && publicData.night_light;
  const ambiances = publicData && publicData.ambiances;
  const parking = publicData && publicData.parking;


  const initialValues = { 
      amenities_tinyhouse, 
      amenities_garden_rent,
      amenities_garden_share,
      events,
      games,
      ambiances,
      pet, 
      water_point,
      parking,
      night_light,
      parking
  };


  return (
    <div className={classes}>
      <EditListingFeaturesForm
        className={css.form}
        initialValues={initialValues}
        category={publicData.category}
        onSubmit={values => {
          const { 
            amenities_garden_rent = [], 
            amenities_garden_share = [], 
            amenities_tinyhouse = [], 
            events  = [],
            games = [],
            parking = [],
            ambiances = [],
            pet = null,
            water_point = null,
            night_light = null
          } = values;

          const updatedValues = {
            publicData: { 
                amenities_tinyhouse, 
                amenities_garden_rent,
                amenities_garden_share,
                events,
                games,
                ambiances,
                pet, 
                water_point, 
                night_light,
                parking
              },
          };
          onSubmit(updatedValues)
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingFeaturesPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingFeaturesPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingFeaturesPanel;
