import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  IconSocialMediaYoutube,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';

import css from './Footer.module.css';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle, siteYoutubePage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });
  const goToYoutube = intl.formatMessage({ id: 'Footer.goToYoutube' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const youtubeLink = siteFacebookPage ? (
    <ExternalLink key="linkToYoutube" href={siteYoutubePage} className={css.icon} title={goToYoutube}>
      <IconSocialMediaYoutube />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;
  return [fbLink, twitterLink, instragramLink, youtubeLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.someLiksMobile}>{socialMediaLinks}</div>
            <div className={css.organization} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink}>
                <Logo format="desktop" className={css.logo} />
              </NamedLink>

            </div>
              <div className={css.linkWrapper}>
                  <NamedLink name="AboutPage" className={css.legalLink}>
                    <FormattedMessage id="Footer.toAboutPage" />
                  </NamedLink>
                  {/* <NamedLink name="HowItWorkPage" className={css.legalLink}>
                    <FormattedMessage id="Footer.toHowItWorkPage" />
                  </NamedLink> */}
                  <NamedLink name="FaqPage" className={css.legalLink}>
                    <FormattedMessage id="Footer.toHelpPage" />
                  </NamedLink>
                  <NamedLink name="ContactPage" className={css.legalLink}>
                    Nous contacter
                  </NamedLink>
                  <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
                    <FormattedMessage id="Footer.privacyPolicy" />
                  </NamedLink>
                  <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                    CGU
                  </NamedLink>
                  <NamedLink name="LegalPage" className={css.legalLink}>
                    <FormattedMessage id="LegalPage.heading" />
                  </NamedLink>


                  <div className={css.someLinks}>{socialMediaLinks}</div>
            </div>
    </div>

    </div>
    <div className={css.mobileLink}>
      <NamedLink name="AboutPage" className={css.legalLink}>
        <FormattedMessage id="Footer.toAboutPage" />
      </NamedLink><br />
      {/* <NamedLink name="HowItWorkPage" className={css.legalLink}>
        <FormattedMessage id="Footer.toHowItWorkPage" />
      </NamedLink><br /> */}
      <NamedLink name="FaqPage" className={css.legalLink}>
        <FormattedMessage id="Footer.toHelpPage" />
      </NamedLink><br />
      <NamedLink name="ContactPage" className={css.legalLink}>
        Nous contacter
      </NamedLink><br />
      <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
        <FormattedMessage id="Footer.privacyPolicy" />
      </NamedLink><br />
      <NamedLink name="TermsOfServicePage" className={css.legalLink}>
        CGU
      </NamedLink><br />
      <NamedLink name="LegalPage" className={css.legalLink}>
        <FormattedMessage id="LegalPage.heading" />
      </NamedLink><br />



    </div>
    <div className={css.organizationInfo}>
                <p className={css.organizationCopyright}>
                  © Jardins-prives.com est une marque déposée de Jardins Privés
                </p>
              </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
