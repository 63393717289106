import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import {
  TRANSITIONS,
  TRANSITION_ACCEPT,
  TRANSITION_DECLINE,
  TRANSITION_CANCEL,
  TRANSITION_CANCEL_BY_PROVIDER,
  TRANSITION_CANCEL_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_PROVIDER
} from '../../util/transaction';
import { addMarketplaceEntities, addMarketplaceFullEntities } from '../../ducks/marketplaceData.duck';
import * as log from '../../util/log';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  )

const SOON = ["transition/confirm-payment"]
const PAST = ["transition/accept"]
const SOON_AND_PAST = ["transition/confirm-payment", "transition/accept"]
const END = [
  "transition/request-payment",
  "transition/request-payment-after-enquiry",
  "transition/expire-payment",
  "transition/decline",
  "transition/expire",
  "transition/cancel",
  "transition/cancel-by-provider",
  "transition/cancel-by-customer",
  "transition/complete",
  "transition/expire-review-period",
  "transition/review-1-by-customer",
  "transition/review-1-by-provider",
  "transition/review-2-by-provider",
  "transition/expire-provider-review-period",
  "transition/review-2-by-customer",
  "transition/expire-customer-review-period"
]


// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/BookingListingsPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/BookingListingsPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/BookingListingsPage/FETCH_ORDERS_OR_SALES_ERROR';

export const ACCEPT_SALE_REQUEST = 'app/TransactionPage/ACCEPT_SALE_REQUEST';
export const ACCEPT_SALE_SUCCESS = 'app/TransactionPage/ACCEPT_SALE_SUCCESS';
export const ACCEPT_SALE_ERROR = 'app/TransactionPage/ACCEPT_SALE_ERROR';

export const DECLINE_SALE_REQUEST = 'app/TransactionPage/DECLINE_SALE_REQUEST';
export const DECLINE_SALE_SUCCESS = 'app/TransactionPage/DECLINE_SALE_SUCCESS';
export const DECLINE_SALE_ERROR = 'app/TransactionPage/DECLINE_SALE_ERROR';

export const CANCEL_SALE_REQUEST = 'app/TransactionPage/CANCEL_SALE_REQUEST';
export const CANCEL_SALE_SUCCESS = 'app/TransactionPage/CANCEL_SALE_SUCCESS';
export const CANCEL_SALE_ERROR = 'app/TransactionPage/CANCEL_SALE_ERROR';

export const REVIEW_SEND_REQUEST = 'app/TransactionPage/REVIEW_SEND_REQUEST';
export const REVIEW_SEND_SUCCESS = 'app/TransactionPage/REVIEW_SEND_SUCCESS';
export const REVIEW_SEND_ERROR = 'app/TransactionPage/REVIEW_SEND_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
  acceptInProgress: false,
  acceptSaleError: null,
  declineInProgress: false,
  declineSaleError: null,
  cancelSaleError: null,
  cancelInProgress: false,
  reviewError: null,
  reviewInProgress: false,
  currentTxId: null
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    case ACCEPT_SALE_REQUEST:
      return { ...state, acceptInProgress: true, acceptSaleError: null, declineSaleError: null, currentTxId: payload };
    case ACCEPT_SALE_SUCCESS:
      return { ...state, acceptInProgress: false, currentTxId: null };
    case ACCEPT_SALE_ERROR:
      return { ...state, acceptInProgress: false, acceptSaleError: payload, currentTxId: null };

    case DECLINE_SALE_REQUEST:
      return { ...state, declineInProgress: true, declineSaleError: null, acceptSaleError: null, currentTxId: payload };
    case DECLINE_SALE_SUCCESS:
      return { ...state, declineInProgress: false, currentTxId: null };
    case DECLINE_SALE_ERROR:
      return { ...state, declineInProgress: false, declineSaleError: payload, currentTxId: null };

    case CANCEL_SALE_REQUEST:
      return { ...state, cancelInProgress: true, cancelSaleError: null, currentTxId: payload };
    case CANCEL_SALE_SUCCESS:
      return { ...state, cancelInProgress: false, currentTxId: null };
    case CANCEL_SALE_ERROR:
      return { ...state, cancelInProgress: false, cancelSaleError: payload, currentTxId: null };

    case REVIEW_SEND_REQUEST:
      return { ...state, reviewInProgress: true, reviewError: null, currentTxId: payload };
    case REVIEW_SEND_SUCCESS:
      return { ...state, reviewInProgress: false, currentTxId: null, reviewError: null };
    case REVIEW_SEND_ERROR:
      return { ...state, reviewInProgress: false, reviewError: payload, currentTxId: null };
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const acceptOrDeclineInProgress = state => {
  return state.TransactionPage.acceptInProgress || state.TransactionPage.declineInProgress;
};


// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

const acceptSaleRequest = (id) => ({ type: ACCEPT_SALE_REQUEST, payload: id });
const acceptSaleSuccess = () => ({ type: ACCEPT_SALE_SUCCESS });
const acceptSaleError = e => ({ type: ACCEPT_SALE_ERROR, error: true, payload: e });

const declineSaleRequest = (id) => ({ type: DECLINE_SALE_REQUEST, payload: id });
const declineSaleSuccess = () => ({ type: DECLINE_SALE_SUCCESS });
const declineSaleError = e => ({ type: DECLINE_SALE_ERROR, error: true, payload: e });

const cancelSaleRequest = (id) => ({ type: CANCEL_SALE_REQUEST, payload: id });
const cancelSaleSuccess = () => ({ type: CANCEL_SALE_SUCCESS });
const cancelSaleError = e => ({ type: CANCEL_SALE_ERROR, error: true, payload: e });

const reviewRequest = (id) => ({ type: REVIEW_SEND_REQUEST, payload: id });
const reviewSuccess = () => ({ type: REVIEW_SEND_SUCCESS });
const reviewError = e => ({ type: REVIEW_SEND_ERROR, error: true, payload: e });

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 10;

export const acceptSale = (id, params) => (dispatch, getState, sdk) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(acceptSaleRequest(id))

  return sdk.transactions
    .transition({ id, transition: TRANSITION_ACCEPT, params: {} }, { expand: true })
    .then(response => {
      dispatch(loadData({ tab: params.tab || "attente" }, params))
      dispatch(acceptSaleSuccess())
      return response
    })
    .catch(e => {
      dispatch(acceptSaleError(storableError(e)));
      log.error(e, 'accept-sale-failed', {
        txId: id,
        transition: TRANSITION_ACCEPT,
      });
      // throw e;
    });
};

export const declineSale = (id, params) => (dispatch, getState, sdk) => {
  if (acceptOrDeclineInProgress(getState())) {
    return Promise.reject(new Error('Accept or decline already in progress'));
  }
  dispatch(declineSaleRequest(id));

  return sdk.transactions
    .transition({ id, transition: TRANSITION_DECLINE, params: {} }, { expand: true })
    .then(response => {
      dispatch(loadData({ tab: params.tab || "attente" }, params))
      dispatch(declineSaleSuccess())
      return response;
    })
    .catch(e => {
      dispatch(declineSaleError(storableError(e)));
      log.error(e, 'reject-sale-failed', {
        txId: id,
        transition: TRANSITION_DECLINE,
      });
      // throw e;
    });
};

export const cancelSale = (id, params, role) => (dispatch, getState, sdk) => {
  dispatch(cancelSaleRequest(id));

  const transaction = role === "customer" ? TRANSITION_CANCEL_BY_CUSTOMER : TRANSITION_CANCEL_BY_PROVIDER

  return sdk.transactions
    .transition({ id, transition: transaction, params: {} }, { expand: true })
    .then(response => {
      dispatch(loadData({ tab: params.tab || "attente" }, params))
      dispatch(cancelSaleSuccess())
      return response;
    })
    .catch(e => {

      dispatch(cancelSaleError(storableError(e)));
      log.error(e, 'reject-sale-failed', {
        txId: id,
        transition: TRANSITION_DECLINE,
      });
      // throw e;
    });
}


export const sendReview = (id, params, role) => (dispatch, getState, sdk) => {
  dispatch(reviewRequest(id));

  const { transaction } = params;

  let transition = role === "customer" ? TRANSITION_REVIEW_1_BY_CUSTOMER : TRANSITION_REVIEW_1_BY_PROVIDER
  if (transaction.attributes.lastTransition == TRANSITION_REVIEW_1_BY_PROVIDER) {
    transition = TRANSITION_REVIEW_2_BY_CUSTOMER;
  }
  if (transaction.attributes.lastTransition == TRANSITION_REVIEW_1_BY_CUSTOMER) {
    transition = TRANSITION_REVIEW_2_BY_PROVIDER;
  }



  // const REVIEW_TX_INCLUDES = ['reviews', 'reviews.author', 'reviews.subject'];
  // const IMAGE_VARIANTS = {
  //   'fields.image': [
  //     // Profile images
  //     'variants.square-small',
  //     'variants.square-small2x',

  //     // Listing images:
  //     'variants.landscape-crop',
  //     'variants.landscape-crop2x',
  //   ],
  // };
  // const include = REVIEW_TX_INCLUDES;
  console.log("BookingListingPage duck, transaction", transaction);
  console.log("BookingListingPage duck, transition", transition);
  console.log("BookingListingPage duck params", params);
  /*
  dispatch(loadData({ tab: params.tab || "attente" }, params));
  dispatch(reviewSuccess());
  return true;
  // LM 13/05 . Shunt sdk submit to perform multiple tests.
  */
  return sdk.transactions
    .transition({
      id: id,
      transition: transition,
      params: {
        reviewRating: params.reviewRating,
        reviewContent: params.reviewContent,
      }
    }, { expand: true })
    .then(response => {
      dispatch(loadData({ tab: params.tab || "attente" }, params))
      dispatch(reviewSuccess())
      return response;
    })
    .catch(e => {
      dispatch(reviewError(storableError(e)));
      log.error(e, 'reject-sale-failed', {
        txId: id,
        transition: TRANSITION_DECLINE,
      });
      // throw e;
    });

};



export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { tab } = params;
  let transactionLastStatus = []
  if (tab === 'attente') {
    transactionLastStatus = SOON
  } else if (tab === 'a-venir') {
    transactionLastStatus = PAST
  } else if (tab === 'calendrier') {
    transactionLastStatus = PAST
  } else {
    transactionLastStatus = END
  }

  dispatch(fetchOrdersOrSalesRequest());


  const { page = 1 } = parse(search);

  const apiFullQueryParams = {
    lastTransitions: ["transition/confirm-payment"],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions'
    ],
    page: 1,
    per_page: 30,
  };

  const currentUser = getState().user.currentUser;
  if (currentUser != null) {
    sdk.transactions
      .query(apiFullQueryParams)
      .then(response => {
        dispatch(addMarketplaceFullEntities(response));
        return response;
      })
      .catch(e => {
        throw e;
      });
  }
  const apiQueryParams = {
    lastTransitions: transactionLastStatus,
    include: ['provider', 'provider.profileImage', 'customer', 'customer.profileImage', 'booking', 'listing', 'listing.images', 'reviews'],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'protectedData',
      'relationships'
    ],
    'fields.listing': ['title', 'geolocation', 'images', 'publicData.location', 'publicData.category', 'publicData.price_terms'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    per_page: INBOX_PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};


// "transition/enquire",
// "transition/request-payment",
// "transition/request-payment-after-enquiry",
// "transition/expire-payment",
// "transition/confirm-payment",
// "transition/decline",
// "transition/expire",
// "transition/accept",
// "transition/cancel",
// "transition/complete",
// "transition/expire-review-period",
// "transition/review-1-by-customer",
// "transition/review-1-by-provider",
// "transition/review-2-by-provider",
// "transition/expire-provider-review-period",
// "transition/review-2-by-customer",
// "transition/expire-customer-review-period"
