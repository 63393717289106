import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {
  Button,
} from '../../components';
import { FormattedMessage } from '../../util/reactIntl';


import css from './FilterSortBy.module.css'


const FilterSortBy = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = (key) => {
    props.handleChange("sort", key)
    handleClose()
  }


  const [checked, setChecked] = React.useState([]);

  const handleCheck = (event) => {
    setChecked([]);
    var updatedList = [];//[...checked];
    if (event.target.checked) {
      if (event.target.value == "-price") {
        console.log("price", document.getElementById("price"));
        document.getElementById("price").checked = false;
      }
      else {
        document.getElementById("-price").checked = false;
      }
      updatedList = [event.target.value];
    } else {
      props.handleChange("sort", "")
      updatedList = [];
      //updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    console.log("updatedList", updatedList);
    setChecked(updatedList);

    props.handleChange("sort", updatedList)

  };

  if (props.isMobile) {
    return <div className="checkList" xs={12}>
      <div className="list-container" xs={12}>

        <div key="-price" style={{ marginBottom: "15px" }}>
          <label for="-price" className={css.checkboxes}>
            <input type="checkbox" id="-price" value="-price" className={css.checkboxesInput} onChange={handleCheck} />
            {props.intl.formatMessage({ id: `FieldCheckbox.priceAsc` })}
          </label>

        </div>
        <div key="price" style={{ marginBottom: "15px" }}>
          <label for="price" className={css.checkboxes}>
            <input type="checkbox" id="price" value="price" className={css.checkboxesInput} onChange={handleCheck} />
            {props.intl.formatMessage({ id: `FieldCheckbox.priceDesc` })}
          </label>

        </div>
      </div>
    </div >
  }
  /*
  if (props.isMobile) {
    return <select onChange={(e) => handleSearch(e.target.value)}>
      <option value={null}> {props.intl.formatMessage({ id: `FieldCheckbox.all` })} </option>
      <option value="-price"> {props.intl.formatMessage({ id: `FieldCheckbox.priceAsc` })} </option>
      <option value="price"> {props.intl.formatMessage({ id: `FieldCheckbox.priceDesc` })} </option>
    </select>
  }
*/
  let classButton;
  if (props.initValue) {
    classButton = css.buttonFilterActive
  } else {
    classButton = css.buttonFilter
  }

  return (<div>
    <Button className={classButton} onClick={handleClick}>
      {props.initValue ? props.initValue === '-price'
        ? <FormattedMessage id={`FieldCheckbox.priceAsc`} />
        : <FormattedMessage id={`FieldCheckbox.priceDesc`} />
        : <FormattedMessage id={`FieldCheckbox.sortBy`} />
      }
    </Button>
    <Menu
      id="price-menu-filter"
      elevation={4}
      variant="menu"
      anchorEl={anchorEl}
      // anchorOrigin={{
      //   vertical: 'bottom',
      //   horizontal: 'bottom',
      // }}
      // transformOrigin={{
      //   vertical: 'top',
      //   horizontal: 'bottom',
      // }}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={() => handleSearch(null)}><FormattedMessage id={`FieldCheckbox.all`} /></MenuItem>
      <MenuItem onClick={() => handleSearch("-price")}><FormattedMessage id={`FieldCheckbox.priceAsc`} /></MenuItem>
      <MenuItem onClick={() => handleSearch("price")}><FormattedMessage id={`FieldCheckbox.priceDesc`} /></MenuItem>

    </Menu>
  </div>
  );
}

export default FilterSortBy;