import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './BookingBreakdown.module.css';


const LineItemAdultMaybe = props => {
  const {transaction} = props

  if(!transaction){
    return null
  }
  const adults1 = transaction.attributes.protectedData && 
      transaction.attributes.protectedData.nb_adult ? 
      transaction.attributes.protectedData.nb_adult : null 
  
  const adults2 = transaction.attributes.lineItems[0] && 
      transaction.attributes.lineItems[0].nbAdult ? 
      transaction.attributes.lineItems[0].nbAdult : null 

  if(!adults1 && !adults2) return null 


  
  return <div className={css.lineItem}>
              <span className={css.itemLabel}>
                <FormattedMessage id={(adults2 || adults1) > 1 ?  `BookingBreakdown.adults_fee` :  `BookingBreakdown.adult_fee`} />
              </span>
              <span className={css.itemValue}>{adults2 || adults1}</span>
            </div>
};

export default LineItemAdultMaybe;
