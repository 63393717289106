
// 
export const assignFee = (extra_fee) => {
    let fees = null;
    if(extra_fee && extra_fee.length > 0){
        fees = [];
      for(let i = 0 ; i < extra_fee.length; i++){
        switch (extra_fee[i]) {
          case 'bbq_fee':
            fees.push({amount: 2000, currency : "EUR", label: "bbq_fee"})
            break;
          case 'barnum_fee':
            fees.push( {amount: 1000, currency : "EUR", label: "barnum_fee"})
            break;
          case 'kitchen_fee':
            fees.push({amount: 1000, currency : "EUR", label: "kitchen_fee"})
            break;
          case 'sound_fee':
            fees.push({amount: 2000, currency : "EUR", label: "sound_fee"})
            break;
          case 'spa_fee':
            fees.push({amount: 2000, currency : "EUR", label: "spa_fee"})
            break;
          default:
            break;
        }
      }
    }
    return fees
}