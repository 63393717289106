/* Stripe related configuration.

NOTE: REACT_APP_STRIPE_PUBLISHABLE_KEY is mandatory environment variable.
This variable is set in a hidden file: .env
To make Stripe connection work, you also need to set Stripe's private key in the Flex Console.
*/

export const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

/**
 * Default merchant category code (MCC)
 * MCCs are used to classify businesses by the type of goods or services they provide.
 *
 * In FTW we use code 5734 Computer Software Stores as a default for all the connected accounts.
 *
 * See the whole list of MCC codes from https://stripe.com/docs/connect/setting-mcc#list
 */
export const defaultMCC = '5734';

/*
Stripe only supports payments in certain countries, see full list
at https://stripe.com/global

You can find the bank account formats from https://stripe.com/docs/connect/payouts-bank-accounts
*/

export const stripeCountryDetails = [
  {
    // France
    code: 'FR',
    currency: 'EUR',
    accountConfig: {
      iban: true,
    },
  },
];

/*
NOTE: This configuration will not be updated!
We might remove this code in the later releases.

With Connect Onboarding Stripe will handle collecting most of the information about user. For new setup we only need the list of countries and accountConfig.
If you want to handle the whole onboarding flow on your on application, you can use the old PayoutDetailsForm as a starting point. That form uses this configuration option.
You should make sure that the list of countries is up-to-date and that the config contains all the required infomation you need to collect.

Remember to change the import from config.js if you want to use this config!

This setup is for API version '2019-02-19' and later.
If you have an older API version in use, you need to update your Stripe API.
You can check your Stripe API version from Stripe Dashboard -> Developers.
Stripe only supports payments in certain countries, see full list
at https://stripe.com/global
*/

export const stripeSupportedCountries = [
  {
    // France
    code: 'FR',
    currency: 'EUR',
    addressConfig: {
      addressLine: true,
      city: true,
      postalCode: true,
    },
    accountConfig: {
      iban: true,
    },
    companyConfig: {
      personalAddress: true,
      owners: true,
      businessURL: true
    },
    individualConfig: {
      businessURL: true
    }
  },
  {
    // Belgique
    code: 'BE',
    currency: 'EUR',
    addressConfig: {
      addressLine: true,
      city: true,
      postalCode: true,
    },
    accountConfig: {
      iban: true,
    },
    companyConfig: {
      personalAddress: true,
      owners: true,
      businessURL: true
    },
    individualConfig: {
      businessURL: true
    }
  },
  {
    // Suisse
    code: 'CH',
    currency: 'CHF',
    addressConfig: {
      addressLine: true,
      city: true,
      postalCode: true,
    },
    accountConfig: {
      iban: true,
    },
    companyConfig: {
      personalAddress: true,
      owners: true,
      businessURL: true
    },
    individualConfig: {
      businessURL: true
    }
  },
  {
    // Canada
    code: 'CA',
    currency: 'CAD',
    addressConfig: {
      addressLine: true,
      city: true,
      postalCode: true,
    },
    accountConfig: {
      iban: true,
    },
    companyConfig: {
      personalAddress: true,
      owners: true,
      businessURL: true
    },
    individualConfig: {
      businessURL: true
    }
  },
]
