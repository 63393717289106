import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}></p>

      <p>
        Les présentes conditions générales d’utilisation (ci-après les « CGU ») s’appliquent à tout visiteur (ci-après l’« Utilisateur ») du site web accessible à l’adresse https://www.jardins-prives.com (ci-après la « Plateforme »). La Société JARDINS PRIVÉS, immatriculée au RCS de Paris sous le n°820 229 474 (ci-après « JARDINS PRIVES ») est éditrice de la Plateforme. Elle propose sur sa Plateforme de mettre en relation des Utilisateurs cherchant un espace en plein air (jardin, terrasse, parc, etc. …) selon le mode de la location, en France ou des jardins à cultiver et à entretenir selon le concept du prêt de jardin et du prêt de potager entre particuliers.<br />
        Tout Utilisateur agissant en qualité de professionnel doit se conformer à l’ensemble de la législation applicable notamment en matière de commerce électronique et protection des consommateurs. L’erreur ou l’utilisation volontaire d’une fausse qualité de professionnel ou de consommateur est susceptible d’entraîner des sanctions.
      </p>
      <h2>1.     Informations légales</h2>
      <p>Les informations légales de JARDINS PRIVES et sa politique de confidentialité relative aux données personnelles, sont disponibles à l’adresse suivante : https://www.jardins-prives.com/politique-de-confidentialite
      </p>
      <h2>2.     Acceptation des CGU</h2>
      <p>
        Tout accès à la Plateforme implique l’acceptation des présentes CGU. L’Utilisateur reconnait avoir pris connaissance de l’ensemble des CGU et déclare les accepter sans réserve. Il reconnaît en outre avoir la capacité de conclure le présent contrat par l’acceptation des CGU. Les CGU sont accessibles à l’adresse suivante https://www.jardins-prives.com/conditions-utilisation et peuvent être imprimées.
      </p>
      <h2>3.     Définitions</h2>
      <p>
        « Annonce » désigne l’ensemble des éléments et données publiés en ligne d’un Utilisateur propriétaire sur la Plateforme, énonçant les conditions de Location d’un Bien, de Prêt de jardin ou de Prêt de potager, dont il est le propriétaire.<br />
        « Avis » désigne la fonctionnalité mise à la disposition des Utilisateurs des Services offerts par JARDINS PRIVES leur permettant d’évaluer les Utilisateurs propriétaires et les Utilisateurs réservataires. Le dépôt d’Avis est permis uniquement après qu’une Réservation ait eu lieu. Un Avis se présente sous deux formes :<br />
        <ul>
          <li>Une évaluation représentée par une note allant de 1 à 5 étoiles est affichée sur l’annonce et le profil des Utilisateurs propriétaires. 1 Etoile égal peu satisfaisant et 5 étoiles égal excellent.</li>
          <li>Une évaluation représentée par une note allant de 1 à 5 étoiles est affichée sur le profil des Utilisateurs réservataires. 1 Etoile égal peu satisfaisant et 5 étoiles égal excellent.</li>
          <li>L’Utilisateur ne peut mettre une étoile qu’après avoir mis un commentaire correspondant.</li>
        </ul>
        Un Commentaire doit être précis et ne peut contenir aucun propos discriminatoire, offensant, diffamatoire. Les commentaires ne sont pas vérifiés par JARDINS PRIVES et peuvent être infondés ou trompeurs. Les Avis reflètent l’opinion des Utilisateurs des Services et non celle de JARDINS PRIVES.<br />
        JARDINS PRIVES n’effectue aucune procédure de contrôle de ces Avis.<br />
        « Bien » signifie un espace en plein air ou clos situé en France, dont la location ou le Prêt sont proposés sur la Plateforme par un Utilisateur propriétaire. Un Bien peut notamment être un jardin ou un potager.<br />
        « Compte Utilisateur » désigne le compte créé par un Utilisateur sur la Plateforme dans les conditions de l’article « 5. Création et utilisation d’un Compte Utilisateur » des présentes CGU.<br />
        « Intempéries » désigne les précipitations fortes, modérées ou faibles établies par Météo France dans les départements couverts par le service de prévision consultable à l’adresse https://meteofrance.com/ permettant ou non l’annulation d’une Réservation dans les conditions de l’article 12.<br />
        « Location » : désigne l’accord par lequel un Utilisateur propriétaire loue un Bien à un Utilisateur réservataire pour une durée et un prix déterminé.<br />
        « Plateforme » ou « Plateforme jardins-prives.com » désigne le site web accessible à l’adresse https://www.jardins-prives.com/ qui constitue la plateforme technique mise à disposition par JARDINS PRIVES, objet des CGU.<br />
        « Prêt de jardin » : désigne l’accord par lequel un Utilisateur propriétaire met à disposition temporairement et gratuitement son Bien à un Utilisateur réservataire, en échange de l’entretien du Bien par ce dernier, selon des conditions qu’ils déterminent.<br />
        « Prêt de potager » : désigne l’accord par lequel un Utilisateur propriétaire met à dispositions temporairement et gratuitement une partie de son Bien à un Utilisateur réservataire, pour qu'il cultive un potager en échange du partage des récoltes, selon les conditions qu’ils déterminent.<br />
        « Réservation » désigne l’accord entre des Utilisateurs pour la Location, ou le Prêt de jardin ou le Prêt de potager, selon les modalités énoncées et acceptées par eux dans l’Annonce, objet de la Réservation.<br />
        « Services(s) » signifie, au singulier comme au pluriel, le ou tous services offerts par JARDINS PRIVES sur la Plateforme ou mis à disposition par JARDINS PRIVES et accessibles via la Plateforme.<br />
        « Utilisateur » désigne tout visiteur du site web accessible au public à l’adresse https://www.jardins-prives.com/ et/ou ayant créé un Compte Utilisateur.<br />
        « Utilisateur réservataire » désigne tout Utilisateur ayant effectué une Réservation d’un Bien.<br />
        « Utilisateur propriétaire » désigne tout Utilisateur qui met en ligne sur le site web  https://www.jardins-prives.com/ une Annonce et qui propose la Location, ou le Prêt de jardin ou de potager dont il est propriétaire.<br />
        « Utilisateur propriétaire particulier » désigne l’Utilisateur propriétaire qui agit exclusivement à des fins privées.<br />
        « Utilisateur propriétaire professionnel » désigne l’Utilisateur propriétaire qui agit à des fins professionnelles.<br />
      </p>
      <h2>4.     Conditions d'utilisation de la Plateforme.</h2>

      <p>
        La Plateforme est fournie "en l'état" sans garantie d'aucune sorte. JARDINS PRIVES ne saurait garantir la mise en place d’un service ininterrompu et/ou d’un accès continu à l’ensemble des services proposés en quelque endroit que ce soit. JARDINS PRIVES peut apporter, à tout moment, des améliorations ou des modifications à la Plateforme. JARDINS PRIVES exclut toute garantie de qualité, de véracité, d’exactitude, d'adéquation à un usage particulier, de propriété et d'absence de contrefaçon. Aucun contenu ou information fournis par la Plateforme ne sauraient être interprétés comme constitutifs de garantie.<br />
        JARDINS PRIVES ne sera en aucun cas responsable, quel que soit le fondement de l'action en responsabilité invoqué, des dommages directs et indirects (ni de tout dommage qui résulterait d'une mauvaise interprétation d'une publication, d'une désinformation ou d'une diffamation) résultant de, ou liés à l'utilisation ou à la mise en page des informations ou à l’utilisation des Services.<br />
        Est strictement interdite toute utilisation des contenus disponibles sur la Plateforme de manière illégale ou de façon à nuire à JARDINS PRIVES, aux Utilisateurs ou à tous tiers ou, d’une quelconque manière, d’entraver, fausser ou interrompre le bon déroulement de l’activité de la Plateforme.<br />
        L’Utilisateur garantit JARDINS PRIVES contre toute demande, réclamation ou action fondée sur la potentielle atteinte aux droits d’un tiers par ses actions.  JARDINS PRIVES se réserve la possibilité de supprimer une publication ou un Avis à tout moment.<br />
        JARDINS PRIVES ne pourra pas être tenue responsable de toute utilisation non conforme des publications sur la Plateforme par tout tiers. La responsabilité de JARDINS PRIVES ne saurait en aucun cas être recherchée en raison des Avis laissés par les Utilisateurs, des publications sur la Plateforme, ou encore des informations mentionnées sur leurs comptes par les Utilisateurs.<br />
        En acceptant les présentes CGU, l’Utilisateur, qu’il soit Utilisateur propriétaire ou réservataire, donne expressément mandat à JARDINS PRIVES de, en exécution stricte des présentes CGU, en son nom et pour son compte selon qu’il soit Utilisateur propriétaire ou réservataire, de recevoir paiement, de procéder à tout paiement ou de prélever voire rembourser, et plus généralement de procéder à toutes opérations financières quelles qu’elles soient, en exécution de la location consentie via la Plateforme.<br />
        Tout paiement par l’Utilisateur réservataire s’effectuera sur la plateforme sécurisée STRIPE de JARDINS PRIVES puis sera transféré sur le compte bancaire de l’Utilisateur propriétaire dans un délai compris entre trois (3) et sept (7) jours à compter du jour de la Location.<br />
      </p>
      <h2>5.     Création et utilisation d’un Compte Utilisateur</h2>
      <p>
        L'utilisation des Services de JARDINS PRIVES nécessite la création d’un Compte Utilisateur.<br />
        La création d’un Compte Utilisateur ne crée aucune obligation à la charge de JARDINS PRIVES et ne crée aucun droit au bénéfice de l’Utilisateur. JARDINS PRIVES est en droit de modifier les conditions d’accès et d’utilisation de tout ou partie du Compte Utilisateur à sa seule discrétion. JARDINS PRIVES fera son possible pour en informer l’Utilisateur sans qu’il ne s’agisse d’une obligation opposable.
        <p></p>
        a.     Création <br />
        L’Utilisateur déclare avoir plus de 18 ans.<br />
        Si l’Utilisateur s’inscrit à titre professionnel, il reconnait avoir l’autorité et le pouvoir juridique pour engager l’entité au nom de laquelle il agit.<br />
        L’Utilisateur garantit fournir des informations exactes et loyales et mettre à jour ces informations en cas de modifications.<br />
        Les identifiants utilisés pour créer un Compte Utilisateur doivent respecter l’ordre public et le droit des tiers. Sont notamment interdits les identifiants à caractère pornographique, raciste, violent, ainsi que les identifiants empruntant ou rappelant une marque commerciale dont l’Utilisateur ne serait pas propriétaire ni titulaire d’un droit d’usage, et de manière générale tous termes ou expressions susceptibles de porter atteinte aux droits des tiers.<br />
        Ces identifiants sont strictement personnels et ne doivent pas être communiqués à des tiers. L’Utilisateur est seul gardien et entièrement responsable de la conservation de ce caractère confidentiel et de l’utilisation de ses identifiants. Il doit prendre toutes les mesures nécessaires pour empêcher une utilisation non autorisée ou frauduleuse de son Compte. JARDINS PRIVES ne demande jamais et pour quelle que raison que ce soit à un Utilisateur de lui communiquer son identifiant et toute demande en ce sens devra être considérée comme une demande frauduleuse.<br />
        Dans le cas où la création du Compte Utilisateur se fait par usage d’un service d’authentification fourni par un service tiers (notamment, Facebook, Twitter, Google+), les informations d’identification de l’Utilisateur seront obtenues par le biais de ces services d’authentification. L’Utilisateur s’engage de même à ce que les informations d’identification déclarées à ces services d’authentification soient exactes et à les mettre à jour de la même manière. JARDINS PRIVES ne sera pas tenue pour responsable des dommages, des pertes ou des dépenses subies ou encourues par l’Utilisateur ou un tiers du fait de l'accès obtenu par une personne non autorisée aux espaces réservés aux Utilisateurs dès lors que cet accès ne résulte pas d'une faute de JARDINS PRIVES.<br />
        Le non-respect de toutes obligations d’identification et de fourniture d’informations exactes de l’Utilisateur pourra donner lieu à la suspension ou à l’annulation du Compte Utilisateur, et à la résiliation et/ou suspension du Service.<br />
        <p></p>
        b.       Utilisation<br />
        Le Compte Utilisateur est créé et utilisé sous la seule et unique responsabilité de celui-ci.<br />
        Le Compte Utilisateur permet :<br />
        -  d’accéder à un espace privé sur la Plateforme ;<br />
        -  publier des Annonces accessibles aux autres Utilisateurs ;<br />
        -  d’accéder à une messagerie permettant de rentrer en contact avec les autres Utilisateurs ;<br />
        -  de recevoir des notifications en provenance de la Plateforme ;<br />
        -  effectuer la Réservation d’un Bien ;<br />
        - de percevoir sur son compte bancaire via JARDINS PRIVES les paiements effectués par les Utilisateurs en règlement des Réservations<br />
        -  de contacter l’équipe de JARDINS PRIVES par voie de messagerie.<br />
        L’Utilisateur peut fermer son Compte Utilisateur à tout moment sans préavis et sans frais.<br />
        JARDINS PRIVES se réserve le droit, sans préavis, ni indemnité, de fermer temporairement ou définitivement un Compte Utilisateur notamment en cas de manquement de l’Utilisateur aux présentes CGU.<br />
        La fermeture du Compte Utilisateur pour quelque motif que ce soit est susceptible de causer la perte de l’ensemble des données et avantages conférés à l’Utilisateur. Dans ces conditions, il est à la charge de l’Utilisateur de prendre les mesures nécessaires pour conserver ses données.<br />
      </p>
      <h2>6.     Publier une Annonce</h2>
      <p>
        L’Utilisateur propriétaire reconnait et accepte expressément que les Annonces, pouvant contenir des informations personnelles qu’il publie de son Compte Utilisateur soient accessibles à toute personne accédant à la Plateforme.<br />
        L’Utilisateur propriétaire peut publier via son Compte Utilisateur une Annonce.<br />
        L’Utilisateur propriétaire doit déclarer sous son profil s’il est particulier ou professionnel.<br />
        S’il agit à titre professionnel alors qu’il se présente comme un particulier, il s’expose aux sanctions encourues en application des dispositions de l’article L 132-2 du Code de la Consommation.<br />
        Cette annonce devra contenir, de manière impérative, les mentions suivantes :<br />
        -          un descriptif du Bien ;<br />
        -          la localisation du Bien ;<br />
        -          la surface du Bien ;<br />
        -          les plages de Réservation ;<br />
        -          le nombre de personnes autorisées ;<br />
        -          l’option choisie en cas d’annulation d’une Réservation.<br />
        L’Utilisateur propriétaire déclare avoir conscience qu’il est responsable du contenu de son Annonce et sera responsable de toutes les conséquences pouvant en découler. Ne seront en aucun cas acceptées les annonces contenant du contenu illicite (apologie de crime contre l’humanité, apologie du terrorisme, incitation à la haine raciale, diffusion d’images violentes ou pédophiles, incitation à la violence, atteinte à la dignité humaine, harcèlement, injures raciales, injures sexistes, diffamation, atteinte aux droits à l’image et la vie privée, …)<br />
        Afin de se prémunir contre toute dégradation, l’Utilisateur propriétaire peut demander un dépôt de garantie dans son Annonce. Dans ce cas, le dépôt de garantie sera géré par JARDINS PRIVES au même titre que le paiement du Prix de la réservation au sein du compte mis en place par JARDINS PRIVES dans le cadre du mandat qui lui a été consenti par l’Utilisateur. Dans le cadre de la gestion des réclamations, les données de paiement peuvent être conservées pendant 13 mois suivant la date de débit pour le paiement de la Réservation par le prestataire STRIPE. Dans ce cas, les données sont conservées à des fins de preuve et doivent être conservées en archivage intermédiaire et ne peuvent être utilisées qu’en cas de contestation.<br />
        Si l’Utilisateur réservataire y consent spécifiquement, les données de paiement peuvent être conservées jusqu’à ce que l’Utilisateur retire son consentement ou jusqu’à l’expiration de la validité des coordonnées bancaires afin de faciliter les Réservations ultérieures.<br />
        JARDINS PRIVES se réserve le droit de supprimer toute Annonce paraissant porter atteinte aux droits de tiers, à des dispositions légales ou signalée comme contenu illicite sans préavis.<br />
      </p>
      <h2>7.     Effectuer une Réservation</h2>
      <p>
        JARDINS PRIVES fournit un service de nature purement technique et est rémunéré à cet effet. En conséquence, il ne contrôle pas les Annonces qui sont publiées sur la Plateforme quant à leur réalité et légalité. Aussi les Utilisateurs reconnaissent avoir conscience que la responsabilité de JARDINS PRIVES ne pourra être engagée en aucun cas en raison des Annonces, et des Réservations effectuées via l’utilisation de la Plateforme.<br />
        Dans le cadre de l’utilisation de la Plateforme, se définit comme une Réservation toute demande de Réservation d’un Utilisateur acceptée et validée par un Utilisateur propriétaire selon le processus décrit ci-après.<br />
        Le mécanisme de mise en œuvre d’une Réservation de Location est le suivant :<br />
        <p></p>
        -  Etape 1 : Demande de Réservation d’un Utilisateur<br />
        Chaque Utilisateur peut effectuer une demande de Réservation suite à une Annonce pour une date ou une période donnée via son Compte Utilisateur.<br />
        Après confirmation de l’Utilisateur propriétaire sur la date ou la période et les conditions de réservations, l’Utilisateur réservataire doit effectuer le paiement du Prix de la Réservation afin de valider sa Réservation. Le paiement s’effectuera par l’intermédiaire du compte STRIPE de JARDINS PRIVES.<br />
        Le montant du Prix de la Réservation ne sera pas prélevé tant que l’Utilisateur propriétaire n’aura pas accepté la Réservation.<br />
        En cas de refus de la demande de Réservation ou d’absence de réponse dans un délai de 3 jours, aucun paiement ne sera débité.<br />
        <p></p>
        -  Etape 2 – Acceptation de la demande de Réservation de l’Utilisateur propriétaire<br />
        L’Utilisateur propriétaire destinataire de la demande de Réservation reçoit un message de demande de Réservation par un Utilisateur réservataire. Cette information est également consultable sur le Compte Utilisateur de l’Utilisateur propriétaire.<br />
        L’Utilisateur propriétaire doit accepter la demande de Réservation dans un délai de trois (3) jours ouvrés.<br />
        Un Utilisateur propriétaire n’a pas à motiver son refus de demande de Réservation. L’absence de réponse d’un Utilisateur propriétaire à une demande de Réservation vaut refus de la demande de Réservation.<br />
        <p></p>
        -  Etape 3 : Confirmation de la Réservation<br />
        La Réservation est effectuée lorsque l’Utilisateur propriétaire a accepté la demande de Réservation.<br />
        Un message de confirmation est alors envoyé à l’Utilisateur réservataire sur son adresse électronique.<br />
        <p></p>
        Le mécanisme de mise en œuvre d’un Prêt de jardin ou de potager est le suivant :<br />
        La demande de Réservation se fait uniquement par échange de messages électroniques entre l’Utilisateur réservataire et l’Utilisateur propriétaire puisque cette transaction est gratuite.<br />
        L’Utilisateur réservataire doit renseigner à l’Utilisateur propriétaire :<br />
        Le jour de sa Réservation,<br />
        Son heure d’arrivée,<br />
        Le nombre d’heures qu’il pense rester dans le jardin ou le potager pour l’entretenir.<br />
        Ces informations doivent être adressées par l’Utilisateur réservataire via la messagerie de la Plateforme de JARDINS PRIVES à l’Utilisateur propriétaire.<br />
        L’Utilisateur propriétaire et l’Utilisateur réservataire devront se mettre d’accord sur les conditions du partage des fruits de la récolte ou de l’entretien du jardin.<br />
        L’Utilisateur propriétaire doit accepter la demande de Réservation dans un délai de trois (3) jours ouvrés.<br />
        Un Utilisateur propriétaire n’a pas à motiver son refus de demande de Réservation. L’absence de réponse d’un Utilisateur propriétaire à une demande de Réservation vaut refus de la demande de Réservation.<br />

      </p>

      <h2>8.     Prix des Réservations de Location </h2>
      <p>
        Le prix est indiqué sur les Annonces des Utilisateurs propriétaires accessibles sur la Plateforme, ce prix total étant appelé au sens des présentes CGU le ou les « Prix de la réservation ».<br />
        Chaque Utilisateur propriétaire fixe librement le Prix de ses Annonces<br />
        Pour les Réservations de Location, les Prix de la réservation sont affichés en euros, toutes taxes françaises comprises, le cas échéant (TVA française et autres taxes applicables) sauf indication contraires.<br />
        Le Prix de la réservation comprend le paiement au titre de l’utilisation de la Plateforme mise à disposition par JARDINS PRIVES (ci-après les « Frais techniques et de gestion »). Les Frais techniques et de gestion correspondent à vingt-deux pour cent toutes taxes comprises (22% TTC) du Prix de la réservation. Les Frais techniques et de gestion couvrent le fonctionnement de la Plateforme, sa maintenance corrective et évolutive.<br />
        Les Frais techniques et de gestion seront réglés par l’Utilisateur réservataire à hauteur de vingt-deux (22) pour cent TTC du Prix de la réservation.<br />
        L’Utilisateur réservataire paiera les vingt-deux (22) % de Frais techniques et de gestion lors de la Réservation.<br />
        La plateforme STRIPE calculera le montant qui est alloué à l’Utilisateur propriétaire et ajoutera ensuite les 22 % des frais de services avant paiement.<br />
        En cas de Prêt de jardin, ou de Prêt de potager entre Utilisateurs, les conditions de Réservations sont gratuites. Aucun Frais techniques et de gestion ne seront prélevés. <br />

      </p>
      <h2>9.     Modalités de Paiement de l’Utilisateur réservataire</h2>
      <p>
        L’Utilisateur Réservataire dispose des moyens de paiement suivants :<br />
        -  par carte bancaire de crédit ou privative en indiquant soit directement dans la zone prévue à cet effet (saisie sécurisée par cryptage SSL) le numéro de sa carte, sans espaces entre les chiffres, sa date d’expiration, le cryptogramme visuel au dos de la carte bancaire, son numéro de téléphone mobile et son adresse postale.<br />
        L’Utilisateur réservataire garantit disposer des autorisations nécessaires pour utiliser le mode de paiement choisi.<br />
        Le traitement du paiement de l’Utilisateur réservataire et de ses données de carte bancaire est effectué par notre prestataire STRIPE.<br />
        L’Utilisateur réservataire est seul responsable de la transmission des données de sa carte de crédit ou de débit.<br />
        La finalisation du paiement du Bien par l’Utilisateur réservataire vaut engagement ferme de contracter avec l’Utilisateur propriétaire en considération de la description du Bien dans l’Annonce.<br />
      </p>
      <h2>10.     Paiement de l’Utilisateur propriétaire en cas de Location  </h2>
      <p>
        L’Utilisateur propriétaire est payé exclusivement via le compte bancaire que celui-ci a renseigné sur son Compte Utilisateur.<br />
        Dans le cadre des mandats qui sont confiés par l’Utilisateur, qu’il soit Utilisateur propriétaire ou réservataire, les paiements effectués par l’Utilisateur réservataire arriveront sur le compte STRIPE de JARDINS PRIVES dédié à la réception du Prix de réservation, qui émettra le règlement vers le compte bancaire de l’Utilisateur propriétaire dans un délai compris entre trois (3) et sept (7) jours à l’issue de la Location.<br />
        Les Utilisateurs s’interdisent d’utiliser la Plateforme pour demander, faire ou accepter une Réservation en dehors de la Plateforme, afin d’éviter les frais de service ou pour toute autre raison.<br />
        Le cas échéant, l’Utilisateur reconnait qu’il : (i) viole les présentes CGU ; (ii) assume tous risques et toutes responsabilités relatives à ce paiement, et (iii) dégage JARDINS PRIVÉS de toute responsabilité pour ce paiement.<br />
      </p>
      <h2>11.     Clauses spécifiques à l’Utilisateur agissant en qualité de professionnel</h2>
      <p>
        Tout Utilisateur agissant en qualité de professionnel s’engage à le signaler à JARDINS PRIVES lors de la création de son Compte Utilisateur.<br />
        Tout Utilisateur professionnel s’engage à le signaler de manière explicite lors de son utilisation de la Plateforme, notamment dans ses Annonces, ses demandes de Réservations, ses Réservations.<br />
        JARDINS PRIVES rappelle à l’Utilisateur agissant en qualité de professionnel qu’il a l’obligation de respecter les lois et règlements applicables et notamment à l’égard des consommateurs et d’exercice d’une activité commerciale en ligne. Il s’engage ainsi au respect de l’ensemble de ses obligations, déclaratives, d’informations, de garantie, de rétractation.<br />
        L’Utilisateur professionnel devra notamment respecter toutes les dispositions sur le contrat conclu à distance prévues aux articles L221-1 et suivants du Code de la Consommation et notamment le droit de rétractation de 14 jours.<br />
        En cas de contrat de location d’un Bien par un Utilisateur propriétaire professionnel à un Utilisateur réservataire consommateur, ce dernier bénéficie du droit de rétraction de 14 jours prévue à l’article L 221-18 du Code de la Consommation.<br />

      </p>

      <h2>12.     Annulation d’une Réservation</h2>
      <p>
        Les Utilisateurs reconnaissent quel que soit l’auteur et les modalités d’annulation de la Réservation, qu’en cas de paiement de Frais techniques et de gestion, ces frais resteront acquis à JARDINS PRIVES.
        <p></p>
        a.     Annulation par l'Utilisateur Réservataire<br />

        L’Utilisateur réservataire peut demander l’annulation de sa Réservation de Location selon l’option d’annulation précisée dans l’Annonce objet de la Réservation.<br />
        L’Utilisateur propriétaire peut choisir entre les trois (3) types d’annulation suivants :<br />
        -  Annulation flexible : Annulation 24 heures avant l’heure prévue d’arrivée.<br />
        Exemple : Pour bénéficier d'un remboursement intégral, l'annulation doit avoir lieu 24 heures avant l'heure d'arrivée indiquée sur la Réservation. Si l’Utilisateur réservataire annule moins de 24 heures avant l’heure d’arrivée, la Réservation ne lui sera pas remboursée.<br />
        -  Annulation Modérée : Annulation 5 jours avant l’heure prévue d’arrivée.<br />
        Si l’Utilisateur réservataire annule moins de 5 jours avant l’heure d'arrivée, la Réservation ne lui sera pas remboursée.<br />
        -  Annulation stricte : Annulation 10 jours avant l’heure prévue d’arrivée.<br />
        Si l’Utilisateur réservataire annule moins de 10 jours avant l’heure d'arrivée, la Réservation ne lui sera pas remboursée.<br />
        L’Utilisateur réservataire doit envoyer dans les délais précités, quel que soit les conditions d’annulation applicables, un message à l’Utilisateur propriétaire concerné via son Compte Utilisateur.<br />

        En cas d’Intempéries rendant difficile la jouissance du Bien, l’Utilisateur propriétaire et l’Utilisateur réservataire feront leur affaire personnelle pour trouver une solution amiable et décider de reporter ou d’annuler la Réservation. La décision finale revenant à l’Utilisateur propriétaire. JARDINS PRIVES ne devra en aucun cas être concerné par cette cause d’annulation de Réservation.<br />
        Lorsque l’Utilisateur réservataire annule une Réservation selon les modalités précitées, l’intégralité du Prix de réservation sera remboursée à l’Utilisateur réservataire dans un délai compris entre cinq (5) et dix (10) jours à compter du jour de l’annulation.<br />
        En cas de non-respect par l’Utilisateur réservataire des délais prévus à l’Annonce pour annuler une Réservation, le remboursement du Prix de réservation ne sera pas possible, sauf accord entre l’Utilisateur propriétaire et l’Utilisateur réservataire.<br />

        <p> </p>
        b.     Annulation par l'Utilisateur Propriétaire <br />
        Un Utilisateur propriétaire peut annuler une Réservation de Location sur la Plateforme de JARDIN PRIVES en cas de force majeure (exemples guerre, catastrophe naturelle, etc) prévus par la jurisprudence.<br />
        L’Utilisateur propriétaire doit annuler la Réservation sur la Plateforme afin d’informer l’Utilisateur réservataire que la Réservation est annulée.<br />
        Lorsque l’Utilisateur propriétaire annule une Réservation, l’intégralité du Prix de réservation sera remboursée à l’Utilisateur réservataire dans un délai compris entre cinq (5) et dix (10) jours à compter du jour de l’annulation.<br />
      </p>
      <h2>13.      Règles et engagement de bonne conduite de chaque Utilisateur</h2>
      <p>
        JARDINS PRIVES NE SE PORTE GARANT POUR AUCUN UTILISATEUR.<br />
        a.     Engagements des Utilisateurs réservataires<br />
        <p></p>
        Les Utilisateurs réservataires s’engagent à respecter les principes de bonne conduite énumérés ci-après :
        1.       Avant l’entrée dans les lieux :<br />
        - Pour les Réservation de Location : Communiquer de bonne foi aux Utilisateurs propriétaires toutes les informations nécessaires sur la nature de l’événement pour lequel ils souhaitent effectuer une Réservation, le nombre de participants (adultes & enfants), la date de location et la durée souhaitée.<br />

        - Pour le Prêt de jardin ou le Prêt de potager : Communiquer de bonne foi aux Utilisateurs propriétaires toutes les informations nécessaires sur la nature de leur motivation pour laquelle ils souhaitent effectuer une Réservation, leur expérience en jardinage ou en culture potagère, le type de jardin qu’ils souhaitent, le nombre de participants (adultes et enfants), la date de Réservation et la durée souhaitée.<br />
        <p></p>
        2.       Le jour de l’entrée dans les lieux :<br />
        - Respecter les plages horaires ou calendaires sélectionnées lors de la Réservation ;<br />
        - Respecter les plantations, plates-bandes ou toute autre aménagement floral du Bien ;<br />
        - Ne pas couper ou détruire de fleurs, de plantations, arbustes, arbres, etc… de quelque nature que ce soit sans l’accord de l’Utilisateur propriétaire ;<br />
        - Surveiller la sécurité des enfants aux abords des piscines ;<br />
        - Avoir une tenue descente et particulièrement aux abords des piscines ;<br />
        - Prendre soin des matériels et équipements (ex : barbecue, outils de jardins, …) mis à leur disposition ;<br />
        - Ne pas créer de nuisances sonores qui pourraient déranger la tranquillité du voisinage excepté dans le respect des horaires autorisées par la municipalité (musique, tonte de pelouse, …) ;<br />
        - Ne pas faire de feu et ne pas utiliser de produits inflammables autres que les produits mis à disposition par l’Utilisateur propriétaire pour l’allumage des barbecues ou la destruction des déchets végétaux ;<br />
        - Veiller à ce que les chiens n’aboient pas de manière intempestive ;<br />
        - Ne pas laisser les animaux uriner ou faire leurs besoins sur le lieu du Bien<br />
        - Ne pas laisser d’animaux se baigner dans les piscines.<br />
        <p></p>
        3.       Avant de libérer les lieux :<br />
        - Laisser les matériels et équipements dans le même état d’usage que lors de leur mise à disposition ;<br />
        - Ne laisser aucun détritus, sacs poubelle sur les lieux et les déposer dans les poubelles ou containers prévus par l’Utilisateur propriétaire à cet effet ;<br />
        - Respecter le tri des ordures ménagères lorsque les lieux disposent de bacs de tris prévus à cet effet (carton, verre, ordures ménagères, végétaux…) ;<br />
        <p></p>
        En tout état de cause, les Utilisateurs réservataires s’engagent à avoir un comportement courtois et de bonnes moeurs pendant toute la durée de leur présence sur les lieux.<br />
        b.     Engagement des Utilisateurs propriétaires <br />
        <p></p>
        L’Utilisateur propriétaire déclare et garantit être le propriétaire ou disposer des droits nécessaires sur le Bien qu’il propose à la Réservation. Il s’engage à fournir tout justificatif à première demande.<br />
        L’Utilisateur propriétaire garantit que ni son Annonce, ni la Réservation, ne violent le droit de tiers, ni aucune disposition légale applicable.<br />
        L’Utilisateur propriétaire s’engage à ce que ses Annonces correspondent au Bien destiné à la Réservation.<br />
        En cas de Location de jardin avec piscine, l’Utilisateur propriétaire doit respecter la réglementation prévue en matière de sécurité.<br />
        S’agissant des piscines creusées, l'Utilisateur propriétaire doit s’assurer que sa piscine est pourvue d'un dispositif de sécurité prévue aux articles L. 128-1 et suivants du Code de la construction et de l'habitation. Il doit également disposer d’alarmes de sécurité respectant la loi et notamment le décret n° 2009-873 du 16 juillet 2009.<br />
        Pour les piscines hors sol, l’Utilisateur propriétaire a une obligation générale de sécurité de sa piscine prévue à l'article L. 421-3 du Code de la consommation.<br />
        La présence d’un dispositif de sécurité ne dispense jamais la nécessité d’une surveillance active et permanente des enfants par un adulte.<br />
        En cas d’annulation de la Réservation dans le respect des délais d’annulation mentionnés sur l’Annonce, l’intégralité du Prix de réservation sera remboursée à l’Utilisateur réservataire dans un délai compris entre cinq (5) et dix (10) jours à compter du jour de l’annulation.<br />
      </p>
      <h2>14.      Responsabilité et Exclusion de garantie</h2>
      <p>
        Tout Utilisateur reconnait utiliser la Plateforme et prendre des engagements avec d’autres Utilisateurs à ses risques et périls.<br />
        JARDINS PRIVES exclut expressément toute garantie de convenance à un usage particulier, de jouissance paisible ou de non-contrefaçon ainsi que toute garantie découlant des usages commerciaux.<br />
        Chaque Utilisateur est responsable de ses communications et interactions avec les autres Utilisateurs de la Plateforme, de ses publications, Annonces, commentaires.<br />
        JARDINS PRIVES ne fait aucune garantie concernant le comportement des Utilisateurs.<br />
        Il est rappelé aux Utilisateurs de la Plateforme en qualité de consommateurs, que JARDINS PRIVES est responsable de plein droit sur le fondement des dispositions légales applicables de la bonne exécution de ses obligations au titre des présentes CGU sauf en cas de manquement qui serait imputable, soit à l’Utilisateur lui-même, soit au fait imprévisible et insurmontable d'un tiers au contrat, soit à un cas de force majeure.<br />
        JARDINS PRIVES ne pourra être tenu responsable des dommages indirects, ainsi que des manques à gagner, pertes de données, atteintes à la réputation, interruptions du service, dommages informatiques ou défaillances du système, ou du coût de l’annulation de la Réservation, que ces dommages soient directs ou indirects, lorsque ces dommages résultent ou sont en lien avec l’utilisation ou l’impossibilité d’utilisation de la Plateforme, la communication, l’interaction ou la rencontre avec d’autres Utilisateurs, que ces dommages soient fondés sur une garantie, sur la responsabilité contractuelle ou, dans la mesure permise par la loi sur la responsabilité du fait des produits ou tout autre fondement juridique.<br />
        L’Utilisateur garantit et indemnisera à première demande JARDINS PRIVES contre tout dommage subi par JARDINS PRIVES et contre toute action en responsabilité qui serait engagée à l’encontre de JARDINS PRIVES sur les modalités de la Réservation du Bien, la violation d’un droit quelconque d’un tiers.<br />
        Au titre, des présentes CGU, la responsabilité de JARDINS PRIVES sera pécuniairement limitée à l’équivalent du Prix de la Location entre les Utilisateurs.<br />
        JARDINS PRIVES s’engage à apporter tout le soin raisonnablement possible à l’exécution de ses obligations. Les parties conviennent que JARDINS PRIVES a une obligation de moyen.<br />
      </p>
      <h2>15.      Notification de contenu illicite</h2>
      <p>
        Conformément aux dispositions de la loi n°2004-575 du 21 juin 2004, JARDINS PRIVES, en sa qualité d’intermédiaire technique, n'est pas soumis à une obligation générale de surveillance des informations sur la Plateforme ni à une obligation générale de rechercher des faits ou des circonstances révélant des activités illicites.<br />
        Toute personne désireuse de porter à la connaissance de JARDINS PRIVES, la diffusion de contenus manifestement illicites doit fournir l'ensemble des informations permettant de localiser le contenu en question sur la Plateforme et déterminer le caractère illicite des contenus concernés.<br />
        Pour ce faire, il est possible de faire parvenir :<br />
        •  un courrier recommandé avec accusé de réception à l'adresse : Jardins privés – 9 rue Anatole de la Forge – 75017 Paris et un courrier électronique ou courriel à l’adresse suivante : contact@jardins-prives.com<br />
        <p></p>
        Cette notification devra préciser :<br />
        •  la date de la notification ;<br />
        • l’identité de l’Utilisateur : nom, prénoms, profession, domicile, nationalité, date, lieu de naissance, son adresse électronique (s’il s'agit d'une personne physique) ou forme, dénomination, siège social, organe de représentation légale, son adresse électronique (s’il s'agit d'une personne morale) ;<br />
        • l’identification de l’Utilisateur et/ou du contenu incriminé ;<br />
        • la description des faits litigieux et leur localisation précise sur la Plateforme, notamment l'url de la page du contenu en question ;<br />
        • les motifs pour lesquels le contenu doit être retiré, comprenant la mention des dispositions légales et des justifications de faits ;<br />
        •   la copie de la correspondance adressée à la personne ayant publié le contenu illicite demandant son interruption, son retrait ou sa modification, ou la justification de ce que ladite personne n'a pas pu être contactée.<br />
        JARDINS PRIVES appréciera le caractère illicite des contenus notifiés et, si nécessaire, agira promptement pour retirer le contenu concerné ou en rendre l'accès impossible.  Conformément aux dispositions de l’article 6-I-4 de la loi n°2004-575 du 21 juin 2004, le fait, pour toute personne, de présenter à JARDINS PRIVES un contenu ou une activité comme étant illicite dans le but d'en obtenir le retrait ou d'en faire cesser la diffusion, alors qu'elle sait cette information inexacte, est puni d'une peine pouvant atteindre un an d'emprisonnement et 15.000 EUR d'amende. En cas de réception d’une notification constatant des contenus constituant des infractions d’apologie des crimes contre l'humanité, d'incitation à la haine raciale, ou relatives à la pornographie enfantine, JARDINS PRIVES en informera les autorités publiques compétentes.<br />
      </p>
      <h2>16.     Liens vers d'autres plateformes Web</h2>
      <p>
        Les liens vers d’autres plateformes web font quitter la Plateforme. Les plateformes cibles ne sont pas sous le contrôle de JARDINS PRIVES qui n'est nullement responsable du contenu de ces plateformes.
      </p>
      <h2>17.     Données personnelles</h2>
      <p>
        JARDINS PRIVES accorde une grande importance à la vie privée de ses Utilisateurs et respecte le cadre juridique en vigueur à cet égard, notamment le Règlement général sur la protection de données n°2016/679 (dit « RGPD »).<br />
        JARDINS PRIVES, est responsable du traitement des données personnelles concernant la gestion des Comptes Utilisateurs et prospects à qui elle propose ses services, dans le cas de l’utilisation de la Plateforme à savoir :<br />
        aux fins d’assurer l’inscription de l’Utilisateur à la Plateforme et de permettre son identification et l’ouverture d’une session personnalisée sur la Plateforme et la gestion du Compte utilisateur (certains services requièrent une identification en vue de leur utilisation),<br />
        aux fins de permettre à l’Utilisateur de publier et accéder aux avis publiés sur la Plateforme,<br />
        aux fins du paiement éventuel en cas de transaction sur la Plateforme,<br />
        aux fins d’assurer la transmission des messages échangés via la Plateforme ;<br />
        de la géolocalisation des personnes et des Biens ;
        aux fins d’améliorer les fonctionnalités de la Plateforme et de faciliter la navigation et le confort de navigation sur celle-ci, afin de répondre au mieux aux attentes des Utilisateurs<br />
        aux fins légitimes d’envoi des messages marketing, publicitaires, promotionnels ou toutes autres informations aux Utilisateurs en relation avec la Plateforme.<br />

        L’Utilisateur s’engage à ne communiquer que des informations réelles, loyales et licites, et tiendra informé JARDINS PRIVES de toute modification ou changement les concernant.<br />

        Dans le cadre de l’utilisation de la Plateforme, JARDINS PRIVES peut être amenée à communiquer certaines données personnelles collectées via la Plateforme à des tiers dans les cas suivants :<br />

        Lorsque JARDINS PRIVES recourt aux services de prestataires techniques qui l’aident à fournir les services et plus précisément pour : (i) le développement, l’infogérance et la maintenance de la Plateforme, (ii) l’hébergement de la Plateforme, (iii) l’envoi de messages électroniques via le la Plateforme et (iv) l’encaissement pour compte de tiers. Ces prestataires disposent d’un accès limité aux données personnelles des Utilisateurs, dans le cadre de l’exécution de leurs prestations respectives ;<br />

        Lorsque la loi ou un règlement l’exige, pour donner suite aux injonctions des autorités administratives et/ou judiciaires autorisées.<br />

        JARDINS PRIVES conserve les informations personnelles des Utilisateurs pour la durée strictement nécessaire à la gestion de la relation commerciale. Cependant, toutes données permettant d’établir la preuve d’un droit ou d’un contrat, ou conservées au titre du respect d’une obligation légale ou règlementaire, peuvent faire l’objet d’une politique d’archivage, et être conservées à cette fin conformément aux dispositions en vigueur (délais de prescription, obligations légales de conservation, etc.).<br />

        Les données personnelles de prospects non-clients sont conservées trois (3) ans à compter de leur collecte ou du dernier contact émanant du prospect, sauf accord pour une durée plus longue.<br />

        L’Utilisateur dispose d’un droit d’accès, de rectification, ou d’effacement des données personnelles le concernant, et le cas échéant, d’un droit à la portabilité de ses données, et du droit de ne pas faire l’objet d’une décision individuelle automatisée (y compris le profilage). L’Utilisateur peut demander la limitation du traitement, s’opposer au traitement de ses données, ou le cas échéant retirer son consentement à leur traitement. L’Utilisateur dispose également du droit de définir des directives sur le sort de ses données après sa mort.<br />

        Pour exercer ces droits, l’Utilisateur peut s’adresser à :<br />

        Par courrier : Jardins privés – 9 rue Anatole de la Forge – 75017 Paris<br />
        Par courrier électronique : contact@jardins-prives.com<br />

        L’Utilisateur dispose du droit d’introduire une réclamation auprès d’une autorité de contrôle et notamment auprès de la Commission Nationale de l’Informatique et des Libertés (CNIL) : https://www.cnil.fr/fr/plaintes<br />


        En revanche, les Utilisateurs assument la responsabilité afférente aux contenus qu’ils traitent en relation avec leur utilisation de la Plateforme. Les Utilisateurs sont responsables de traitement s’agissant des traitements de données à caractère personnel qu’ils réalisent en relation avec leur Annonces, leurs Réservation et leur utilisation de la Plateforme (messagerie, avis, etc.). JARDINS PRIVES, simple prestataire technique, a dans ce contexte la qualité de sous-traitant, et n’utilise les données que pour l’usage technique nécessaire à la mise en œuvre de la Plateforme.<br />

        JARDINS PRIVES en sa qualité de sous-traitant, s’engage à :<br />
        N’agir que sur instruction documentée de l’Utilisateur dans le cadre de l’utilisation de la Plateforme,<br />
        Assurer la sécurité des données conformément à l’art. 32 RGPD,<br />
        S’assurer que son personnel est tenu par une obligation de confidentialité,<br />
        À notifier dans des délais raisonnables en cas de violation de données personnelles, à aider l’Utilisateur à respecter ses obligations au titre du RGPD, dans la mesure du possible et dans un délai raisonnable<br />


      </p>
      <h2>18.      Cookies</h2>
      <p>
        Un cookie est un petit fichier texte qui peut être déposé sur un équipement terminal à l'occasion de la consultation d'un site Internet. Un fichier cookie permet à son émetteur d'identifier l’équipement terminal dans lequel il est enregistré, pendant la durée de validité ou d'enregistrement dudit cookie.<br />
        JARDINS PRIVES utilise plusieurs types de cookies<br />
        Les cookies nécessaires au fonctionnement de la Plateforme : ces cookies, comme les identifiants de session, permettent à l’Utilisateur d'utiliser les fonctionnalités de la Plateforme et de sécuriser la connexion de l’Utilisateur. Sans ces cookies l’Utilisateur ne peut utiliser normalement le Site. Nous déconseillons de les supprimer.<br />
        Les cookies de fonctionnalités de la Plateforme : ils ne sont pas indispensables à la navigation sur la Plateforme mais permettent d'en optimiser le fonctionnement et de donner accès à des fonctionnalités spécifiques. Ils permettent également d'adapter la présentation de la Plateforme aux préférences d'affichage de l’équipement terminal, et d'avoir une navigation fluide et sur mesure. Des exemples de cookies de fonctionnalités : langue utilisée, résolution d'affichage, système d'exploitation utilisé, annonces sauvegardées etc.<br />
        Les cookies de mesure d’audience de la Plateforme : Il s'agit de cookies qui permettent de connaître l'utilisation et les performances de la Plateforme et d'établir des statistiques, des volumes de fréquentation et d'utilisation des divers éléments de la Plateforme (contenus visités, parcours) permettant ainsi d'améliorer l'ergonomie de la Plateforme et l’expérience de navigation.<br />

        L’Utilisateur donne son accord au dépôt des Cookies, lorsqu’il clique sur le bouton « tout accepter » du bandeau l’informant de l’utilisation de Cookies.<br />
        Cet accord n’est valable que pour une durée de treize mois à compter du premier dépôt dans l’équipement terminal, sauf retrait du consentement de la part de l’Utilisateur ou exercice de son droit d’opposition.<br />
        L’Utilisateur peut décider de bloquer les cookies, ou demander à son navigateur Internet de l’avertir lorsqu'un site tente de déposer un cookie sur son équipement terminal.<br />
        Pour changer la gestion des cookies, il est possible de modifier les réglages dans l'onglet « confidentialité » ou équivalent du navigateur.<br />
        Attention, certaines fonctionnalités de la Plateforme peuvent ne plus fonctionner.<br />
        La configuration de chaque navigateur est différente. Elle est décrite dans le menu d'aide du navigateur, qui permettra de savoir de quelle manière modifier les souhaits en matière de cookies.<br />
      </p>
      <h2>19.      Propriété intellectuelle</h2>
      <p>
        La Plateforme et les éléments la composant sont protégés par le droit des dessins et modèles, le droit d'auteur, le droit des marques ainsi que par la réglementation applicable à la concurrence déloyale et ils ne peuvent être copiés ou imités en tout ou partie. Sauf autorisation expresse de JARDINS PRIVES, aucun logo, élément graphique, son ou image provenant de la Plateforme ne peut être copié ou diffusé. JARDINS PRIVES est une marque déposée. Tous les droits qui ne sont pas expressément concédés sont réservés.
      </p>
      <h2>20.      Assurances</h2>
      <p>
        Les assurances prévues sont les seules prévues par les parties. JARDINS PRIVES n’intervenant pas dans la relation contractuelle entre l’Utilisateur réservataire et l’Utilisateur propriétaire.<br />
        L’Utilisateur propriétaire et l’Utilisateur réservataire doivent être assurés en cas de dommage à autrui par une assurance responsabilité civile.<br />
        L’Utilisateur propriétaire doit s’assurer que l’Utilisateur réservataire dispose d’une assurance qui le couvre en cas de détériorations du Bien.<br />
        L’Utilisateur propriétaire devra informer son assurance habitation qu’il a décidé de louer son jardin.<br />

      </p>
      <h2>21.     Dispositions Diverses</h2>
      <p>
        L’Utilisateur reconnait qu’il dispose de la faculté de sauvegarder et d’éditer les présentes CGU en utilisant les fonctions standards de son navigateur.<br />
        Les contrats sont archivés pour une durée de dix (10) ans.<br />
        JARDINS PRIVES se réserve le droit de modifier à tout moment les CGU applicables à la Plateforme. Les présentes CGU sont opposables pendant toute la durée d’utilisation de la Plateforme et jusqu’à ce que de nouvelles CGU remplacent les présentes. Tout usage de la Plateforme par l’Utilisateur après les modifications des CGU vaut acceptation par ce dernier des nouvelles CGU. Les CGU figurant en ligne prévalent sur toute version imprimée de date antérieure.<br />

      </p>
      <h2>22.      Loi applicable et attribution de juridiction</h2>
      <p>
        Les présentes CGU, et plus généralement les relations entre JARDINS PRIVES et les Utilisateurs, sont régies par le droit français.
        <br />
        En cas de difficultés dans l’application des présentes CGU, l’Utilisateur s’adressera en priorité à JARDINS PRIVES en vue de rechercher une solution amiable avant tout contentieux devant les juridictions compétentes.<br />

      </p>
      <h2>23.      Médiation</h2>
      <p>
        Conformément à l'article L. 111-1 du Code de la consommation, l’Utilisateur réservataire est informé qu’il a le droit de recourir gratuitement à un médiateur de la consommation en vue de la résolution amiable du litige qui l’opposerait à l’Utilisateur propriétaire professionnel ou à JARDINS PRIVES.<br />
        Lorsque l’Utilisateur est un consommateur, il a la possibilité de recourir à une procédure de médiation conventionnelle ou à tout mode alternatif de règlement des litiges tels que définis par la loi.<br />
        Lorsque l’Utilisateur est un professionnel, vous pourrez soumettre vos litiges avec JARDINS PRIVES au Médiateur des entreprises disponible à l’adresse https://www.economie.gouv.fr/mediateur-des-entreprises.<br />

      </p>
      <p>©  [04/07/2023] - tous droits réservés
      </p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
