import React from 'react';
import AddressLinkMaybe from './AddressLinkMaybe';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build detail card headings
const DetailCardHeadingsMaybe = props => {
  const {
    showDetailCardHeadings,
    listingTitle,
    location,
    geolocation,
    showAddress,
  } = props;

  return showDetailCardHeadings ? (
    <div className={css.detailCardHeadings}>
      <h3 className={css.detailCardTitle}>{listingTitle}</h3>
      <AddressLinkMaybe location={location} geolocation={geolocation} showAddress={showAddress} />
    </div>
  ) : null;
};

export default DetailCardHeadingsMaybe;
