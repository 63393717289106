import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldCheckboxGroup, Form, FieldRadioButton, FieldBoolean } from '../../components';

// Local component
import EditListingAmenities from './EditListingAmenities'
import EditListingAmbiance from './EditListingAmbiance'


import css from './EditListingFeaturesForm.module.css';

/**
 * amenties
 * pet
 * parking
 * access_handicap
 * games
 * events
 * ambiance
 * water_point
 * light_night
 */

const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        category,
        intl,
        rootClassName,
        className,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;


      const optionsParking = findOptionsForSelectFilter('parking', filterConfig);
      const optionsGame = findOptionsForSelectFilter('games', filterConfig);
      const optionsEvent = findOptionsForSelectFilter('events', filterConfig);



      // Props for "view" select field
      const petKey = 'pet';
      const waterKey = 'water_point';
      const lightKey = 'night_light';
      const gamesKey = "games";
      const eventsKey = "events";
      const parkingKey = "parking";


      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <EditListingAmenities
            filterConfig={filterConfig}
            category={category}
          />
          {
            category !== "garden_share" ? (
              <>
                <h2><FormattedMessage id="EditListingFeaturesPanel.titleGame" /></h2>
                <FieldCheckboxGroup className={css.features} id={name} name={gamesKey} options={optionsGame} />
                {
                  category !== "tinyhouse" && <>
                    <h2><FormattedMessage id="EditListingFeaturesPanel.titleEvent" /></h2>
                    <FieldCheckboxGroup className={css.features} id={name} name={eventsKey} options={optionsEvent} />
                  </>
                }
                <EditListingAmbiance
                  filterConfig={filterConfig}
                  category={category}
                />
              </>
            )
              : null
          }


          <h2><FormattedMessage id="EditListingFeaturesPanel.titleParking" /></h2>
          <FieldCheckboxGroup className={css.features} id={name} name={parkingKey} options={optionsParking} />
          {
            category !== "garden_share" ? (

              <div>
                <h2><FormattedMessage id="EditListingFeaturesPanel.titleLight" /></h2>
                <div className={css.sectionRadio}>
                  {
                    ["yes", "no"].map((item, i) => {
                      return <FieldRadioButton
                        key={i}
                        id={`${i}-light`}
                        className={css.checkbox}
                        name={lightKey}
                        label={<FormattedMessage id={`FieldCheckbox.${item}`} />}
                        value={item}
                        showAsRequired={true}
                      />
                    })
                  }
                </div>
              </div>
            )
              : null
          }



          <div>
            <h2><FormattedMessage id="EditListingFeaturesPanel.titleWaterPoint" /></h2>
            <div className={css.sectionRadio}>
              {
                ["yes", "no"].map((item, i) => {
                  return <FieldRadioButton
                    key={i}
                    id={`${i}-water`}
                    className={css.checkbox}
                    name={waterKey}
                    label={<FormattedMessage id={`FieldCheckbox.${item}`} />}
                    value={item}
                    showAsRequired={true}
                  />
                })
              }
            </div>
          </div>


          <div>
            <h2><FormattedMessage id="EditListingFeaturesPanel.titlePet" /></h2>
            <div className={css.sectionRadio}>
              {
                ["yes", "no"].map((item, i) => {
                  return <FieldRadioButton
                    key={i}
                    id={`${i}-pet`}
                    className={css.checkbox}
                    name={petKey}
                    label={<FormattedMessage id={`FieldCheckbox.${item}`} />}
                    value={item}
                    showAsRequired={true}
                  />
                })
              }
            </div>
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default EditListingFeaturesForm;
