import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { ExternalLink } from '../../components';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton } from '../Button/Button';
import css from './CookieConsent.module.css';
import CookieModal from './CookieModal'

class CookieConsent extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      show: false, 
      showModal: false,
      security: false,
      ui: false,
    };
    this.onAcceptCookies = this.onAcceptCookies.bind(this);
    this.onRefuseCookies = this.onRefuseCookies.bind(this);
    this.saveCookieConsent = this.saveCookieConsent.bind(this);
  }

  componentDidMount() {
    const cookies = document.cookie.split('; ').reduce((acc, c) => {
      const [name, value] = c.split('=');
      return { ...acc, [name]: decodeURIComponent(value) };
    }, {});

    if (cookies.euCookiesAccepted !== '1') {
      this.setState({ show: true });
    }
    if (cookies.security === '1') {
      this.setState({ security: true });
    }
    if (cookies.ui === '1') {
      this.setState({ ui: true });
    }
  }

  onAcceptCookies() {
    this.saveCookieConsent();
    this.setState({ show: false });
  }

  onRefuseCookies() {
    this.setState({ security: false });
    this.setState({ ui: false });
    this.setState({ show: false });
  }

  saveCookieConsent() {
    // We create date object and modify it to show date 10 years into the future.
    let expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 10);
    // Save the cookie with expiration date
    document.cookie = 'euCookiesAccepted=1; path=/; expires=' + expirationDate.toGMTString();
  }

  handleCookieChange = (name) => {
    let accepted = false
    switch (name) {
      case 'security':
        accepted = !this.state.security
        this.setState({security: accepted})
        break;
      case 'ui':
        accepted = !this.state.ui
        this.setState({ui: accepted})
        break;
      default:
        break;
    }
    let expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 10);
    // Save the cookie with expiration date
    document.cookie = `${name}=${accepted ? '1' : '0'}; path=/; expires=` + expirationDate.toGMTString();
  }

  acceptAllCookie = () => {
    let expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 10);
    // Save the cookie with expiration date
    document.cookie = `security=1; path=/; expires=` + expirationDate.toGMTString();
    document.cookie = `ui=1; path=/; expires=` + expirationDate.toGMTString();
    this.setState({
      security: true,
      ui: true,
      showModal: false,
      show: false
    })
  }

  refuseAllCookie = () => {
    let expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 10);
    // Save the cookie with expiration date
    document.cookie = `security=0; path=/; expires=` + expirationDate.toGMTString();
    document.cookie = `ui=0; path=/; expires=` + expirationDate.toGMTString();
    this.setState({
      security: true,
      ui: true,
      showModal: false,
      show: false
    })
  }


  render() {
    const { className, rootClassName } = this.props;
    const isServer = typeof window === 'undefined';

    // Server side doesn't know about cookie consent
    if (isServer || !this.state.show) {
      return null;
    } else {
      const cookieLink = (
        <ExternalLink href="https://cookiesandyou.com" className={css.cookieLink}>
          <FormattedMessage id="CookieConsent.cookieLink" />
        </ExternalLink>
      );
      const classes = classNames(rootClassName || css.root, className);

      return (
        <div className={classes}>
          <div className={css.message}>
            <h3 className={css.title}><FormattedMessage id="CookieConsent.title" /></h3>
            <p><FormattedMessage id="CookieConsent.message" values={{ cookieLink }} /></p>
            <div style={{display: 'flex', justifyContent: "flex-start", alignItems: 'center'}}>
              <SecondaryButton style={{marginRight: 10}} onClick={() => this.setState({showModal: true})}>
                <FormattedMessage id="CookieConsent.settings" />
              </SecondaryButton>
              <PrimaryButton style={{marginRight: 10}} onClick={this.onAcceptCookies}>
                <FormattedMessage id="CookieConsent.allOk" />
              </PrimaryButton>
              <SecondaryButton style={{marginRight: 10}} onClick={this.onRefuseCookies}>
                <FormattedMessage id="CookieConsent.allKo" />
              </SecondaryButton>
            </div>
           
          </div>
          <CookieModal 
            showModal={this.state.showModal}
            security={this.state.security}
            ui={this.state.ui}
            handleChange={this.handleCookieChange}
            handleClose={() => this.setState({showModal:  false})}
            handleValidate={() => this.setState({showModal:  false, show:false})}
            handleOpen={() => this.setState({showModal:  true})}
            acceptAll={this.acceptAllCookie}
            refuseAll={this.refuseAllCookie}
          />
        </div>
      );
    }
  }
}

const { string } = PropTypes;

CookieConsent.defaultProps = {
  className: null,
  rootClassName: null,
};

CookieConsent.propTypes = {
  className: string,
  rootClassName: string,
};

export default CookieConsent;
