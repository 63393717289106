import React from 'react';
import { bool, object, shape } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

import PayoutDetailsAddress from './PayoutDetailsAddress';
import PayoutDetailsCompany from './PayoutDetailsCompany';
import PayoutDetailsBankDetails from './PayoutDetailsBankDetails';
import PayoutDetailsAccountOpener from './PayoutDetailsAccountOpener';
import PayoutDetailsIdentityFile from './PayoutDetailsIdentityFile';
import { stripeCountryConfigs } from './PayoutDetailsForm';
import css from './PayoutDetailsForm.module.css';

const CompanyAccountComponent = props => {
  const { fieldRenderProps, intl, appConfig, currentUserId, currentUser, method } = props;
  const { disabled, form, values } = fieldRenderProps;
  const { country, accountOpener } = values;
  const { push } = form && form.mutators ? form.mutators : {};

  const companyConfig =
    country && stripeCountryConfigs(country).companyConfig
      ? stripeCountryConfigs(country).companyConfig
      : {};

  const showBusinessURLField = !!companyConfig.businessURL;
  const showCompanyPhoneNumberField = !!companyConfig.companyPhone;
  const showPersonalEmailField = !!companyConfig.personalEmail;
  const showPersonalAddressField = !!companyConfig.personalAddress;
  const showPersonalIdNumberField =
    !!companyConfig.personalIdNumberRequired || !!companyConfig.ssnLast4Required;
  const showPersonalPhoneNumberField = !!companyConfig.personalPhone;
  const showOwnerFields = !!companyConfig.owners;

  const hasBusinessURL =
    values &&
    values.company &&
    values.company.businessProfile &&
    values.company.businessProfile.url;

  if ( !hasBusinessURL && currentUserId) {
    const pathToProfilePage = uuid =>
      createResourceLocatorString('ProfilePage', routeConfiguration(), { id: uuid }, {});
    const defaultBusinessURL =
      appConfig && appConfig.canonicalRootURL && !appConfig.dev
        ? `${config.canonicalRootURL}${pathToProfilePage(currentUserId.uuid)}`
        : `https://jardins-prives.com${pathToProfilePage(currentUserId.uuid)}`;

    form.change('company.businessProfile.url', defaultBusinessURL);
  }

  if(values && values.individual && !values.individual.email ){
    form.change('accountOpener.email', currentUser.attributes.email);
  }
  console.log("MEG", method)
  return (
    <React.Fragment>
      {country ? (
        <React.Fragment>
          <PayoutDetailsCompany
            country={country}
            disabled={disabled}
            fieldId="company"
            intl={intl}
            method={method}
            showPhoneNumberField={showCompanyPhoneNumberField}
            showBusinessURLField={showBusinessURLField}
          />
          <PayoutDetailsAddress
            country={country}
            intl={intl}
            disabled={disabled}
            form={form}
            fieldId="company.address"
          />
          { method === 'CREATE' && <PayoutDetailsBankDetails country={country} disabled={disabled} fieldId="company" /> }
          {
            method === 'CREATE' && 
            <>
              <h3 className={css.subTitle}><FormattedMessage id={"PayoutDetailsForm.owner"} /></h3>
              <p></p>
              <PayoutDetailsIdentityFile 
                fieldId="accountOpener"
                intl={intl}
                front={accountOpener && accountOpener.verification && accountOpener.verification.document && accountOpener.verification.document.front || null }
                back={accountOpener && accountOpener.verification && accountOpener.verification.document && accountOpener.verification.document.back || null }
              />
              <PayoutDetailsAccountOpener
                country={country}
                disabled={disabled}
                fieldId="accountOpener"
                form={form}
                intl={intl}
                showEmailField={showPersonalEmailField}
                showOrganizationTitleField
                showOwnerField={showOwnerFields}
                showPersonalAddressField={showPersonalAddressField}
                showPersonalIdNumberField={showPersonalIdNumberField}
                showPhoneNumberField={showPersonalPhoneNumberField}
                values={values}
              />
            </>
          }
          
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

CompanyAccountComponent.defaultProps = {
  id: null,
  disabled: false,
};

CompanyAccountComponent.propTypes = {
  fieldRenderProps: shape({
    disabled: bool,
    form: object.isRequired,
    values: object,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const PayoutDetailsCompanyAccount = compose(injectIntl)(CompanyAccountComponent);

export default PayoutDetailsCompanyAccount;
