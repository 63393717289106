import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { ListingLink } from '../../components';
import { EditListingCapacityForm } from '../../forms';
import config from '../../config.js';
import { LISTING_STATE_DRAFT } from '../../util/types';


// Create this file using EditListingDescriptionPanel.module.css
// as a template.
import css from './EditListingCapacityPanel.module.css';

const EditListingCapacityPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const panelTitle = currentListing.id ? (
    <FormattedMessage
      id="EditListingCapacityPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingCapacityPanel.createListingTitle" />
  );

  const capacityOptions = findOptionsForSelectFilter(
    'capacity',
    config.custom.filters
  )

  return (
    <div className={classes}>
      <h2><FormattedMessage id={`EditListingCapacityForm.titleForm_${publicData.category}`} /></h2>
      <EditListingCapacityForm
        className={css.form}
        category={publicData.category}
        initialValues={{ 
            capacity_access: publicData.capacity_access,
            capacity_max_people: publicData.capacity_max_people,
            capacity_surface_garden: publicData.capacity_surface_garden,
            capacity_surface_tinyhouse: publicData.capacity_surface_tinyhouse
        }}
        onSubmit={values => {
          const { capacity_access, capacity_max_people, capacity_surface_garden, capacity_surface_tinyhouse } = values;
          const updateValues = {
            publicData: {
              capacity_access, 
              capacity_max_people: parseInt(capacity_max_people, 10) || 5, 
              capacity_surface_garden,
              capacity_surface_tinyhouse
            },
          };
          onSubmit(updateValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateError={errors.updateListingError}
        updateInProgress={updateInProgress}
        capacityOptions={capacityOptions}
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingCapacityPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingCapacityPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingCapacityPanel;