import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingDescriptionForm } from '../../forms';
import config from '../../config';
import {formatMessage} from '../../util/formatMessage'
import css from './EditListingDescriptionPanel.module.css';

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    updateCurrenCategory
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData } = currentListing.attributes;

  const categoriesOptions = findOptionsForSelectFilter(
    'category',
    config.custom.filters
  )

  const classifieldsTypeOptions = findOptionsForSelectFilter(
    'classifields_type',
    config.custom.filters
  )
  const gardenTypeOptions = findOptionsForSelectFilter(
    'garden_type',
    config.custom.filters
  )

  var cate = "garden_rent";
  if (publicData) {
    if (publicData.category) {
      cate = publicData.category;
    }
  }
  
  return (
    <div className={classes}>
      <EditListingDescriptionForm
        updateCurrenCategory={updateCurrenCategory}
        className={css.form}
        category={cate}
        initialValues={{  title, 
                          description, 
                          category: cate, 
                          garden_type: publicData.garden_type, 
                          classifields_type: publicData.classifields_type
                        }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { title, description, category, garden_type, classifields_type } = values;
          const updateValues = {
            title: title.trim(),
            description: formatMessage(description),
            publicData: { category , garden_type, classifields_type },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        isNewListing={!currentListing.id ? true : false}
        categoriesOptions={categoriesOptions}
        classifieldsTypeOptions={classifieldsTypeOptions}
        gardenTypeOptions={gardenTypeOptions}
      />
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDescriptionPanel;
