import React from 'react';
import { Field } from 'react-final-form'
import css from './PayoutDetailsForm.module.css';
import MyFileInput from './MyFileInput'
import { FormattedMessage } from 'react-intl/dist/react-intl';


const PayoutDetailsIdentityFile = props => {
  const {
    fieldId,
    disabled,
    showAsRequired,
    front,
    initialValues,
    fieldRenderProps,
    stripeConnected,
    back,
    intl
  } = props;
  if (!fieldRenderProps) {
    return <></>;
  }
  const { form } = fieldRenderProps;
  return (
    <div className={css.sectionContainer}>
      <div>
        <h3><FormattedMessage id="PayoutDetailsForm.identityFileTitle" /></h3>
        <p><FormattedMessage id="PayoutDetailsForm.identityFileSubTitle" /></p>
      </div>
      <div className={css.wrapperinputFile}>
        <Field name={`${fieldId}.verification.document.front`} showAsRequired={showAsRequired}>
          {
            props => (
              <div className={css.fileIdentity}>
                <MyFileInput
                  form={form}
                  stripeConnected={stripeConnected}
                  name="front"
                  title={intl.formatMessage({
                    id: 'PayoutDetailsForm.identityFileInputFrontTitle',
                  })}
                  span={intl.formatMessage({
                    id: 'PayoutDetailsForm.identityFileInputFrontSpan',
                  })}
                  paragraph={intl.formatMessage({
                    id: 'PayoutDetailsForm.identityFileInputParagraph',
                  })}
                  hasFile={front}
                  {...props.input}
                />
              </div>)
          }
        </Field>
        <Field name={`${fieldId}.verification.document.back`}>
          {props => (
            <div className={css.fileIdentity}>
              <MyFileInput
                form={form}
                stripeConnected={stripeConnected}
                title={intl.formatMessage({
                  id: 'PayoutDetailsForm.identityFileInputBackTitle',
                })}
                span={intl.formatMessage({
                  id: 'PayoutDetailsForm.identityFileInputBackSpan',
                })}
                paragraph={intl.formatMessage({
                  id: 'PayoutDetailsForm.identityFileInputParagraph',
                })}
                name="back"
                hasFile={back}
                {...props.input}
              />
            </div>
          )}
        </Field>
      </div>
    </div>
  )
}


export default PayoutDetailsIdentityFile;
