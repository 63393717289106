import React from 'react';
import { Field } from 'react-final-form'
import css from './PayoutDetailsForm.module.css';
import MyFileInput from './MyFileInput'
import { FormattedMessage } from 'react-intl/dist/react-intl';


const PayoutDetailsCompanyFile = props => {
  const {
    fieldId,
    disabled,
    company_front,
    initialValues,
    fieldRenderProps,
    stripeConnected,
    intl
  } = props;
  if (!fieldRenderProps) {
    return <></>;
  }

  const { form } = fieldRenderProps;

  return (
    <div className={css.sectionContainer}>
      <div>
        <h3><FormattedMessage id="PayoutDetailsForm.companyFileTitle" /></h3>
        <p><FormattedMessage id="PayoutDetailsForm.companyFileSubTitle" /></p>
      </div>
      <Field name={fieldId}>
        {props => (
          <div>
            <MyFileInput
              form={form}
              stripeConnected={stripeConnected}
              name="company_file"
              title={intl.formatMessage({
                id: 'PayoutDetailsForm.companyFileInputTitle',
              })}
              span={intl.formatMessage({
                id: 'PayoutDetailsForm.companyFileInputSpan',
              })}
              paragraph={intl.formatMessage({
                id: 'PayoutDetailsForm.companyFileInputParagraph',
              })}
              hasFile={company_front}
              {...props.input}
            />
          </div>
        )}
      </Field>
    </div>
  );
};


export default PayoutDetailsCompanyFile;
