import React from 'react'
import { FormattedMessage } from '../../util/reactIntl';
import { toStringDate } from '../../util/dates'
import { Divider } from '@material-ui/core'

import css from './TransactionPanel.module.css';


const TransactionReminder = (props) => {
    const { price, currentTransaction, category, intl, isProvider } = props
   
    const renderDate = currentTransaction.booking &&
        currentTransaction.booking.attributes &&
        currentTransaction.booking.attributes.displayStart ?
        toStringDate(currentTransaction.booking.attributes.displayStart)
        : currentTransaction.attributes &&
            currentTransaction.attributes.protectedData &&
            currentTransaction.attributes.protectedData.date ?
            toStringDate(currentTransaction.attributes.protectedData.date)
            : <FormattedMessage id="TransactionPanel.dateUnknown" />
    const hasEvent = currentTransaction.attributes &&
        currentTransaction.attributes.protectedData &&
        currentTransaction.attributes.protectedData.event_type ? currentTransaction.attributes.protectedData.event_type : null
    const hasTimeArriving = currentTransaction.attributes &&
        currentTransaction.attributes.protectedData &&
        currentTransaction.attributes.protectedData.event_type ? currentTransaction.attributes.protectedData.time_arriving : null

    const nbAdult = currentTransaction.attributes &&
        currentTransaction.attributes.protectedData &&
        currentTransaction.attributes.protectedData.nb_adult ? currentTransaction.attributes.protectedData.nb_adult : 1

    const nbChild = currentTransaction.attributes &&
        currentTransaction.attributes.protectedData &&
        currentTransaction.attributes.protectedData.nb_child ? currentTransaction.attributes.protectedData.nb_child : 0

    const totalPerson = parseInt(nbChild) + parseInt(nbAdult)

    const nbUnits = currentTransaction.attributes.protectedData.nb_unit || 1;

    const subTotal = price && (price.amount * nbUnits * totalPerson) / 100;

    if (category === 'garden_share') {
        return <div className={css.infosBooking}>
            <p style={{ textTransform: "capitalize" }}>
                <b className={css.caption}>{intl.formatMessage({ id: "TransactionPanel.date" })}</b><br />
                {renderDate}

            </p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ margin: 0 }}>
                    <b>Début</b><br />
                    {
                        currentTransaction.attributes.protectedData.time_arriving != null ?
                            currentTransaction.attributes.protectedData.time_arriving !== "not_defined" ?
                                currentTransaction.attributes.protectedData.time_arriving + "h"
                                :
                                null
                            :
                            currentTransaction.infosBooking != null ?
                                currentTransaction.infosBooking.availability_from !== "not_defined" ?
                                    currentTransaction.infosBooking.availability_from + "h"
                                    :
                                    null
                                :
                                props.currentListing.attributes.publicData.availability_from !== "not_defined" ?
                                    props.currentListing.attributes.publicData.availability_from + "h"
                                    :
                                    null
                    }

                </p>
                {category === 'garden_share' ? "" :
                    <p style={{ margin: 0 }}>
                        <b>Fin</b><br />
                        {
                            currentTransaction.infosBooking != null ?
                                currentTransaction.infosBooking.availability_to !== "not_defined" ?
                                    currentTransaction.infosBooking.availability_to + "h"
                                    :
                                    null
                                :
                                props.currentListing.attributes.publicData.availability_to !== "not_defined" ?
                                    props.currentListing.attributes.publicData.availability_to + "h"
                                    :
                                    null
                        }
                    </p>
                }
            </div>
            <p style={{ textTransform: "capitalize" }}>
                <b className={css.caption}>{intl.formatMessage({ id: "TransactionPanel.eventType" })}</b><br />
                {hasEvent ? <FormattedMessage id={`FieldCheckbox.${[hasEvent]}`} /> : '-'}
            </p>
        </div>
    }

    // if(isProvider){
    //<span>{((price.amount * (currentTransaction.attributes.protectedData.nb_unit || 1)) * totalPerson) / 100}€</span>

    return <div className={css.infosBooking}>
        <p style={{ textTransform: "capitalize" }}>
            <b className={css.caption}>{intl.formatMessage({ id: "TransactionPanel.date" })}</b><br />
            {renderDate}
        </p>
        <p style={{ textTransform: "capitalize" }}>
            <b className={css.caption}>{intl.formatMessage({ id: "TransactionPanel.eventType" })}</b><br />
            {hasEvent ? <FormattedMessage id={`FieldCheckbox.${[hasEvent]}`} /> : '-'}
        </p>
        <p style={{ textTransform: "capitalize" }}>
            <b className={css.caption}>{intl.formatMessage({ id: "TransactionPanel.timeArriving" })}</b><br />
            {hasTimeArriving ? hasTimeArriving + "H00" : '-'}
        </p>
        <Divider />
        <p style={{ textTransform: "capitalize", display: "flex", justifyContent: "space-between" }}>
            <b>Nbr {currentTransaction.listing.attributes.publicData.category === 'tinyhouse' ? "nuits" : "heures"}</b>
            <span>{nbUnits}</span>
        </p>
        <p style={{ textTransform: "capitalize", display: "flex", justifyContent: "space-between" }}>
            <b>Nbr adultes</b>
            <span>{nbAdult}</span>
        </p>
        <p style={{ textTransform: "capitalize", display: "flex", justifyContent: "space-between" }}>
            <b>Nbr enfants</b>
            <span>{nbChild}</span>
        </p>
        <Divider />
        <p style={{ textTransform: "capitalize", display: "flex", justifyContent: "space-between" }}>
            <b>Prix par heure / personne</b>
            <span>{(price.amount / 100)}€</span>
        </p>
        <p style={{ textTransform: "capitalize", display: "flex", justifyContent: "space-between" }}>
            <b>Sous total</b>
            <span>{subTotal}€</span>

        </p>
        <p style={{ textTransform: "capitalize", display: "flex", justifyContent: "space-between" }}>
            <b>Frais de service *</b>
            <span>{parseFloat(subTotal * 0.22).toFixed(2)}€</span>
        </p>
        <p className={css.feeInfo}>
            <FormattedMessage id="BookingBreakdown.commissionFeeNote" />
        </p>

    </div>

    // }

    // return null
}

export default TransactionReminder;

// {
//     stateData.showBookingPanel &&
//     <div className={css.infosBooking}>
//     <p style={{textTransform: "capitalize"}}>
//         <b className={css.caption}>{intl.formatMessage({id: "TransactionPanel.date"})}</b><br />{
//           currentTransaction.booking &&
//           currentTransaction.booking.attributes &&
//           currentTransaction.booking.attributes.displayStart ?
//             toStringDate(currentTransaction.booking.attributes.displayStart)
//             : currentTransaction.attributes &&
//               currentTransaction.attributes.protectedData &&
//               currentTransaction.attributes.protectedData.date ?
//                 toStringDate(currentTransaction.attributes.protectedData.date)
//               : <FormattedMessage id="TransactionPanel.dateUnknown" />
//           }
//       </p>
//       {
//          !isClassifields && category !== "garden_share" &&
//             currentTransaction.attributes &&
//                 currentTransaction.attributes.protectedData &&
//                 currentTransaction.attributes.protectedData.event_type &&
//           <p style={{textTransform: "capitalize"}}>
//             <b className={css.caption}>{intl.formatMessage({id: "TransactionPanel.eventType"})}</b><br />{
//               <FormattedMessage id={`FieldCheckbox.${[currentTransaction.attributes.protectedData.event_type]}`} /> || "-"
//             }
//           </p>
//       }
//     </div>
//   }
//     { !stateData.showBookingPanel && <Divider /> }
//     { !stateData.showBookingPanel &&
//       currentTransaction && category !== "classifields" && category !== "garden_share" &&
//       <div className={css.infosBooking}>
//            <p style={{textTransform: "capitalize", display: "flex", justifyContent: "space-between"}}>
//            <b className={css.caption}>{intl.formatMessage({id: "TransactionPanel.nbAdult"})}</b>
//            <span>{
//               currentTransaction.attributes &&
//               currentTransaction.attributes.protectedData &&
//               currentTransaction.attributes.protectedData.nb_adult || "-"
//             }</span>
//           </p>
//           <p style={{textTransform: "capitalize", display: "flex", justifyContent: "space-between"}}>
//           <b className={css.caption}>{intl.formatMessage({id: "TransactionPanel.nbChild"})}</b>
//           <span></span>{
//               currentTransaction.attributes &&
//               currentTransaction.attributes.protectedData &&
//               currentTransaction.attributes.protectedData.nb_child
//             }
//           </p>
//       </div>
//     }
//     {
//       price &&  !stateData.showBookingPanel && category !== "garden_share" &&
//       <>
//       <Divider />
//       <div className={css.infosBooking}>
//       <p style={{textTransform: "capitalize", display: "flex", justifyContent: "space-between"}}>
//         <b>Prix par heure / personne</b>
//         <span>{(price.amount * (currentTransaction.attributes.protectedData.nb_unit || 1)) / 100}€</span>
//       </p>
//       <p style={{textTransform: "capitalize", display: "flex", justifyContent: "space-between"}}>
//         <b>Total</b>
//         <span>{((price.amount *
//                 (currentTransaction.attributes.protectedData.nb_unit || 1) || 1) *
//                 (currentTransaction.attributes.protectedData.nb_adult || 1 +
//                   currentTransaction.attributes.protectedData.nb_child || 0
//                 )) / 100}€</span>
//       </p>
//     </div>
//       </>
//     }
