import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { TRANSITIONS } from '../../util/transaction';
import { addMarketplaceEntities, addMarketplaceFullEntities } from '../../ducks/marketplaceData.duck';
import { toDate } from '../../util/dates'

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );
/*
const SOON = ["transition/confirm-payment", "transition/accept"]
const ENQUIRE = ["transition/enquire"]
const PAST = [
  "transition/request-payment",
  "transition/request-payment-after-enquiry",
  "transition/expire-payment",
  "transition/decline",
  "transition/expire",
  "transition/accept",
  "transition/cancel",
  "transition/complete",
  "transition/expire-review-period",
  "transition/review-1-by-customer",
  "transition/review-1-by-provider",
  "transition/review-2-by-provider",
  "transition/expire-provider-review-period",
  "transition/review-2-by-customer",
  "transition/expire-customer-review-period"
]
*/
const ALL = [
  "transition/request-payment",
  "transition/request-payment-after-enquiry",
  "transition/expire-payment",
  "transition/decline",
  "transition/expire",
  "transition/accept",
  "transition/cancel",
  "transition/complete",
  "transition/expire-review-period",
  "transition/review-1-by-customer",
  "transition/review-1-by-provider",
  "transition/review-2-by-provider",
  "transition/expire-provider-review-period",
  "transition/review-2-by-customer",
  "transition/expire-customer-review-period",
  "transition/confirm-payment",
  "transition/enquire"
];

/*
const BOOKING_WAITING_PAYMENT = [
  "transition/request-payment",
  "transition/request-payment-after-enquiry",
  "transition/confirm-payment",
];
*/

const BOOKING_SOON = [
  "transition/request-payment",
  "transition/request-payment-after-enquiry",
  "transition/confirm-payment",
  "transition/accept",
];

const BOOKING_DONE = [
  "transition/complete",
  "transition/expire-review-period",
  "transition/review-1-by-customer",
  "transition/review-1-by-provider",
  "transition/review-2-by-provider",
  "transition/expire-provider-review-period",
  "transition/review-2-by-customer",
  "transition/expire-customer-review-period",
];

const BOOKING_NOT_DONE = [
  "transition/expire-payment",
  "transition/decline",
  "transition/expire",
  "transition/cancel"
];

const ENQUIRE = ["transition/enquire"]

const ENQUIRE_DONE = ["transition/enquire"]


// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 10;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { tab } = params;



  let transactionLastStatus = ALL;

  if (tab === 'toutes') {
    transactionLastStatus = ALL;
  }
  if (tab === 'demandes-en-cours') {
    transactionLastStatus = ENQUIRE;
  }
  if (tab === 'demandes-passees') {
    transactionLastStatus = ENQUIRE_DONE;
  }

  if (tab === 'reservations-en-cours') {
    transactionLastStatus = BOOKING_SOON;
  }
  if (tab === 'recus') {
    transactionLastStatus = BOOKING_SOON;
  }
  if (tab === 'reservations-cloturees') {
    transactionLastStatus = BOOKING_DONE;
  }

  //let only = tab !== 'recus' ? 'order' : 'sale'
  dispatch(fetchOrdersOrSalesRequest());

  const { page = 1 } = parse(search);
  const apiFullQueryParams = {
    lastTransitions: ["transition/confirm-payment"],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions'
    ],
    page: 1,
    per_page: 30,
  };

  const currentUser = getState().user.currentUser;
  if (currentUser != null) {
    sdk.transactions
      .query(apiFullQueryParams)
      .then(response => {
        dispatch(addMarketplaceFullEntities(response));
        return response;
      })
      .catch(e => {
        throw e;
      });
  }

  const apiQueryParams = {
    // only: 'order',
    lastTransitions: transactionLastStatus,
    include: ['provider', 'provider.profileImage', 'customer', 'customer.profileImage', 'booking', 'messages', 'pop'],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'protectedData'
    ],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    per_page: INBOX_PAGE_SIZE,
  };


  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {

      if (tab === 'demandes-passees' || tab === 'demandes-en-cours') {
        // remove protected data >= now
        console.log("demandes-passees", response);
        let messagesIdToKeep = [];
        let data = [];
        if (response && response.data && response.data.data && response.data.data.length > 0) {

          for (let i = 0; i < response.data.data.length; i++) {
            if (tab === 'demandes-passees') {
              if (response.data.data[i].attributes && response.data.data[i].attributes.protectedData
                && response.data.data[i].attributes.protectedData.date &&
                toDate(response.data.data[i].attributes.protectedData.date) < Date.now()
              ) {
                data.push(response.data.data[i]);
                let messages = response.data.data[i].relationships.messages.data;
                for (let j = 0; j < messages.length; j++) {
                  messagesIdToKeep.push(messages[j].id.uuid);
                }
              }
            }
            if (tab === 'demandes-en-cours') {
              if (response.data.data[i].attributes && response.data.data[i].attributes.protectedData
                && response.data.data[i].attributes.protectedData.date &&
                toDate(response.data.data[i].attributes.protectedData.date) >= Date.now()
              ) {
                data.push(response.data.data[i]);
                let messages = response.data.data[i].relationships.messages.data;
                for (let j = 0; j < messages.length; j++) {
                  messagesIdToKeep.push(messages[j].id.uuid);
                }
              }
            }
          }

          let included = [];

          for (let i = 0; i < response.data.included.length; i++) {
            if (response.data.included[i].type == "message") {
              for (let j = 0; j < messagesIdToKeep.length; j++) {
                if (response.data.included[i].id.uuid == messagesIdToKeep[j]) {
                  included.push(response.data.included[i]);
                  break;
                }
              }
            }
            else {
              included.push(response.data.included[i]);
            }
          }

          response.data.data = data;
          response.data.included = included;
        }

      }
      if (tab === 'demandes-en-cours') {
        // remove protected data < now
      }
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));

      return response;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });


};
