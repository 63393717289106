const validateFeature = (publicData) => {
    switch (publicData.category) {
      case "garden_rent":
        return !!publicData && !!publicData.parking 
                  && !!publicData.ambiances 
                  && !!publicData.amenities_garden_rent 
                  && !!publicData.events
                  && !!publicData.pet
                  && !!publicData.water_point
                  && !!publicData.night_light
      case "garden_share":
        return !!publicData && !!publicData.parking 
          && !!publicData.amenities_garden_share 
          && !!publicData.pet
      case "tinyhouse":
        return !!publicData && !!publicData.parking 
                  && !!publicData.ambiances 
                  && !!publicData.amenities_garden_rent 
                  && !!publicData.events 
                  && !!publicData.amenities_tinyhouse
                  && !!publicData.pet
                  && !!publicData.water_point
                  && !!publicData.night_light
      default:
        return false
    }
}

const validateCapacity = (publicData) => {
    switch (publicData.category) {
      case "tinyhouse":
        return !!(publicData 
            && publicData.capacity_surface_garden
          );
      default:
        return !!(publicData 
            && publicData.capacity_surface_garden
            && publicData.capacity_access
          );
    }
}

export {
    validateCapacity,
    validateFeature
}