import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';




const FilterKeyworld = (props) => {
  const [keyword, setKeyword] = React.useState('');

  const handleChange = (value) => {
      setKeyword(value)
      props.history.push(`${currentQuery}&keywords=${value}`)
  }

  return (
    <div>
       <input type="text" value={keyword} onChange={(e) => handleChange(e.target.value) }/>
    </div>
  );
}

export default FilterKeyworld;