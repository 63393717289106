import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './BookingBreakdown.module.css';


const LineItemBookingHoursMaybe = props => {
  const {transaction} = props

  if(!transaction){
    return null
  }
  const arrivalTime1 = transaction.attributes.protectedData && 
  transaction.attributes.protectedData.time_arriving ? 
  transaction.attributes.protectedData.time_arriving : null
  
const arrivalTime2 = transaction.attributes.lineItems[0] && 
  transaction.attributes.lineItems[0].timeArriving ? 
  transaction.attributes.lineItems[0].timeArriving : null 
  
if(!arrivalTime1 && !arrivalTime2) return null 



  
  return <div className={css.lineItem}>
              <span className={css.itemLabel}>
                <FormattedMessage id={(arrivalTime1 || arrivalTime2)  > 1 ?  `BookingListingsItem.arrivalHour` :  `BookingListingsItem.arrivalHour`} />
              </span>
              <span className={css.itemValue}>{(arrivalTime1 || arrivalTime2)} h</span>
            </div>
};

export default LineItemBookingHoursMaybe;
