import React from 'react'
import { FieldCheckboxGroup} from '../../components';
import { findOptionsForSelectFilter } from '../../util/search';
import { FormattedMessage } from '../../util/reactIntl';

import css from './EditListingFeaturesForm.module.css';

const EditListingAmenities = ({category, filterConfig}) => {


    const amenitiesRentKey = "amenities_garden_rent";
    const amenitiesShareKey = "amenities_garden_share";
    const amenitiesTinyhouseKey = "amenities_tinyhouse";
    const optionsTinyhouse = findOptionsForSelectFilter('amenities_tinyhouse', filterConfig);
    const optionsGardenRent = findOptionsForSelectFilter('amenities_garden_rent', filterConfig);
    const optionsGardenShare = findOptionsForSelectFilter('amenities_garden_share', filterConfig);

    switch (category) {
        case "garden_rent":
            return <>
                <h2><FormattedMessage id="EditListingFeaturesPanel.titleGardenRent" /></h2>
                <FieldCheckboxGroup 
                    className={css.features} 
                    id={amenitiesRentKey} 
                    name={amenitiesRentKey} 
                    options={optionsGardenRent} 
                />
             </>
        case "tinyhouse":
            return <>
                <h2><FormattedMessage id="EditListingFeaturesPanel.titleTinyhouse"/></h2>
                <FieldCheckboxGroup 
                    className={css.features} 
                    id={amenitiesTinyhouseKey} 
                    name={amenitiesTinyhouseKey} 
                    options={optionsTinyhouse} 
                />
                <h2>
                <FormattedMessage id="EditListingFeaturesPanel.titleGardenRent" /></h2>
                <FieldCheckboxGroup 
                    className={css.features} 
                    id={amenitiesRentKey} 
                    name={amenitiesRentKey} 
                    options={optionsGardenRent} 
                />
            </>
        case "garden_share":
            return <><h2><FormattedMessage id="EditListingFeaturesPanel.titleGardenShare"/></h2>
                <FieldCheckboxGroup 
                    className={css.features} 
                    id={amenitiesShareKey} 
                    name={amenitiesShareKey} 
                    options={optionsGardenShare} 
                />
            </>
        default:
            return null 
    }

}

export default EditListingAmenities;