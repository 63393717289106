import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '../../components';

import css from './SectionLocations.module.css';

import parisImage from './images/location_paris.jpg';
import lyonImage from './images/location_lyon.jpg';
import marselleImage from './images/location_marseille.jpg';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (name, image, searchQuery) => {
  const nameText = <span className={css.locationName}>{name}</span>;
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText}>
        <FormattedMessage
          id="SectionLocations.listingsInLocation"
          values={{ location: nameText }}
        />
      </div>
    </NamedLink>
  );
};

const SectionLocations = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionLocations.title" />
      </div>
      <div className={css.locations}>
        {locationLink(
          'Paris',
          parisImage,
          '?pub_category=garden_rent&address=Paris%2C%20France&bounds=48.9020129995121%2C2.46976999462145%2C48.8156060108013%2C2.22422400085346'
        )}
        {locationLink(
          'Lyon',
          lyonImage,
          '?pub_category=garden_rent&address=Lyon%2C%20Rhône%2C%20France&bounds=45.808215%2C4.898391%2C45.707468%2C4.771677'
        )}
        {locationLink(
          'Marseille',
          marselleImage,
          '?pub_category=garden_rent&address=Marseille%2C%20Bouches-du-Rhône%2C%20France&bounds=43.391079%2C5.532504%2C43.172598%2C5.228865'
        )}
      </div>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
