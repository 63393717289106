import React from 'react';
import { bool, node, object, oneOf, string } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import * as validators from '../../util/validators';
import { FieldBirthdayInput, FieldTextInput } from '../../components';
import css from './PayoutDetailsForm.module.css';


const MIN_STRIPE_ACCOUNT_AGE = 18;
const COUNTRY = "FR"

const identity = v => v;
const createDateDob = (dob) => {
  if (!dob) return null
  // const d = (new Date(dob.month.toString() +","+ dob.day.toString() +","+ dob.year.toString()))

  let d = new Date();

  d.setYear(dob.year);
  d.setMonth(dob.month - 1);
  d.setDate(dob.day);

  return d;
}

const PayoutDetailsPersonalDetails = props => {
  const {
    intl,
    disabled,
    values,
    fieldId,
  } = props;

  const firstNameLabel = intl.formatMessage({ id: 'PayoutDetailsForm.firstNameLabel' });
  const firstNamePlaceholder = intl.formatMessage({
    id: 'PayoutDetailsForm.firstNamePlaceholder',
  });
  const firstNameRequired = validators.required(
    intl.formatMessage({
      id: 'PayoutDetailsForm.firstNameRequired',
    })
  );
  const emailLabel = intl.formatMessage({ id: 'PayoutDetailsForm.emailLabel' });
  const emailPlaceholder = intl.formatMessage({
    id: 'PayoutDetailsForm.emailPlaceholder',
  });
  const emailRequired = validators.required(
    intl.formatMessage({
      id: 'PayoutDetailsForm.emailRequired',
    })
  );
  const phoneLabel = intl.formatMessage({ id: 'PayoutDetailsForm.phoneLabel' });
  const phonePlaceholder = intl.formatMessage({
    id: 'PayoutDetailsForm.phonePlaceholder',
  });
  const phoneRequired = validators.required(
    intl.formatMessage({
      id: 'PayoutDetailsForm.phoneRequired',
    })
  );

  const lastNameLabel = intl.formatMessage({ id: 'PayoutDetailsForm.lastNameLabel' });
  const lastNamePlaceholder = intl.formatMessage({
    id: 'PayoutDetailsForm.lastNamePlaceholder',
  });
  const lastNameRequired = validators.required(
    intl.formatMessage({
      id: 'PayoutDetailsForm.lastNameRequired',
    })
  );

  const birthdayLabel = intl.formatMessage({ id: 'PayoutDetailsForm.birthdayLabel' });
  const birthdayLabelMonth = intl.formatMessage({
    id: 'PayoutDetailsForm.birthdayLabelMonth',
  });
  const birthdayLabelYear = intl.formatMessage({ id: 'PayoutDetailsForm.birthdayLabelYear' });
  const birthdayRequired = validators.required(
    intl.formatMessage({
      id: 'PayoutDetailsForm.birthdayRequired',
    })
  );
  const birthdayMinAge = validators.ageAtLeast(
    intl.formatMessage(
      {
        id: 'PayoutDetailsForm.birthdayMinAge',
      },
      {
        minAge: MIN_STRIPE_ACCOUNT_AGE,
      }
    ),
    MIN_STRIPE_ACCOUNT_AGE
  );

  const validatePhone = validators.validPhone(
    intl.formatMessage(
      {
        id: 'PayoutDetailsForm.validPhone',
      }
    ),
    COUNTRY
  );

  // Note: fname and lname are input names for browser autofill functionality.
  return (
    <div className={css.sectionContainer}>
      <div className={css.formRow}>
        <FieldTextInput
          id={`${fieldId}.email`}
          name={`${fieldId}.email`}
          disabled={true}
          className={css.firstName}
          type="text"
          autoComplete="email"
          label={emailLabel}
          placeholder={emailPlaceholder}
          validate={emailRequired}
        />
        <FieldTextInput
          id={`${fieldId}.phone`}
          name={`${fieldId}.phone`}
          disabled={disabled}
          className={css.firstName}
          type="text"
          autoComplete="phone"
          label={phoneLabel}
          placeholder={phonePlaceholder}
          validate={validators.composeValidators(phoneRequired, validatePhone)}
        />
      </div>
      <div className={css.formRow}>
        <FieldTextInput
          id={`${fieldId}.firstName`}
          name={`${fieldId}.fname`}
          disabled={disabled}
          className={css.firstName}
          type="text"
          autoComplete="given-name"
          label={firstNameLabel}
          placeholder={firstNamePlaceholder}
          validate={firstNameRequired}
        />
        <FieldTextInput
          id={`${fieldId}.lastName`}
          name={`${fieldId}.lname`}
          disabled={disabled}
          className={css.lastName}
          type="text"
          autoComplete="family-name"
          label={lastNameLabel}
          placeholder={lastNamePlaceholder}
          validate={lastNameRequired}
        />
      </div>

      <div className={css.formRow} style={{ marginTop: 24 }}>
        <br />
        <FieldBirthdayInput
          id={`${fieldId}.birthDate`}
          name={`${fieldId}.birthDate`}
          disabled={disabled}
          className={css.field}
          label={birthdayLabel}
          labelForMonth={birthdayLabelMonth}
          labelForYear={birthdayLabelYear}
          format={identity}
          valueFromForm={values[fieldId] && values[fieldId].birthDate && createDateDob(values[fieldId].birthDate) ? createDateDob(values[fieldId].birthDate) : values[fieldId]}
          validate={validators.composeValidators(birthdayRequired, birthdayMinAge)}
        />
      </div>
    </div>
  );
};
PayoutDetailsPersonalDetails.defaultProps = {
  country: null,
  disabled: false,
  fieldId: null,
  sectionTitle: null,
  showEmailField: false,
  showOrganizationTitleField: false,
  showOwnerField: false,
  showOwnershipPercentageField: false,
  showPersonalIdNumberField: false,
  showPhoneNumberField: false,
  values: null,
};

PayoutDetailsPersonalDetails.propTypes = {
  accountType: oneOf(['company', 'individual']).isRequired,
  country: string,
  disabled: bool,
  fieldId: string,
  intl: intlShape.isRequired,
  sectionTitle: node,
  showEmailField: bool,
  showOrganizationTitleField: bool,
  showOwnerField: bool,
  showOwnershipPercentageField: bool,
  showPersonalIdNumberField: bool,
  showPhoneNumberField: bool,
  values: object,
};

export default PayoutDetailsPersonalDetails;
