import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldSelect, InlineTextButton } from '../../components';

import ManageAvailabilityCalendar from './ManageAvailabilityCalendar';
import css from './EditListingAvailabilityForm.module.css';


const startTimes = [
  //{key: 'not_defined', value: "not_defined"},
  { key: 9, value: "hour_9" },
  { key: 10, value: "hour_10" },
  { key: 11, value: "hour_11" },
  { key: 12, value: "hour_12" },
  { key: 13, value: "hour_13" },
  { key: 14, value: "hour_14" },
  { key: 15, value: "hour_15" },
  { key: 16, value: "hour_16" },
  { key: 17, value: "hour_17" },
  { key: 18, value: "hour_18" },
  { key: 19, value: "hour_19" },
  { key: 20, value: "hour_20" },
  { key: 21, value: "hour_21" },
  { key: 22, value: "hour_22" },
  { key: 23, value: "hour_23" },
  { key: 24, value: "hour_24" },
]

const endTimes = [
  //{key: 'not_defined', value: "not_defined"},
  { key: 9, value: "hour_9" },
  { key: 10, value: "hour_10" },
  { key: 11, value: "hour_11" },
  { key: 12, value: "hour_12" },
  { key: 13, value: "hour_13" },
  { key: 14, value: "hour_14" },
  { key: 15, value: "hour_15" },
  { key: 16, value: "hour_16" },
  { key: 17, value: "hour_17" },
  { key: 18, value: "hour_18" },
  { key: 19, value: "hour_19" },
  { key: 20, value: "hour_20" },
  { key: 21, value: "hour_21" },
  { key: 22, value: "hour_22" },
  { key: 23, value: "hour_23" },
  { key: 24, value: "hour_24" },
]

export class EditListingAvailabilityFormComponent extends Component {
  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            className,
            rootClassName,
            disabled,
            ready,
            handleSubmit,
            intl,
            invalid,
            pristine,
            saveActionMsg,
            initialValues,
            updated,
            updateError,
            updateInProgress,
            availability,
            availabilityPlan,
            listingId,
            category
          } = formRenderProps;

          const errorMessage = updateError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingAvailabilityForm.updateFailed" />
            </p>
          ) : null;

          const { availability_from, availability_to } = initialValues

          const classes = classNames(rootClassName || css.root, className);
          const submitReady = (updated && pristine) || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled = invalid || disabled || submitInProgress;

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              {errorMessage}
              <div className={css.calendarWrapper}>
                <ManageAvailabilityCalendar
                  availability={availability}
                  availabilityPlan={availabilityPlan}
                  listingId={listingId}
                />
              </div>

              {
                true && <>
                  <FormattedMessage id={"EditListingAvailabilityForm.timeTitle"} />
                  <div className={css.timeWrapper}>
                    <FieldSelect
                      id={`availability_from`}
                      name="availability_from"
                      className={css.field}
                      label={intl.formatMessage({ id: `EditListingAvailabilityForm.start_not_defined` })}
                      value={availability_from}
                    >
                      {
                        startTimes.map(item => {
                          return (<React.Fragment key={item.key}>
                            <option value={item.key}>
                              {intl.formatMessage({ id: `EditListingAvailabilityForm.${[item.value]}` })}
                            </option>
                          </React.Fragment>
                          )
                        })
                      }
                    </FieldSelect>

                    <FieldSelect
                      id={`availability_to`}
                      name="availability_to"
                      className={css.field}
                      label={intl.formatMessage({ id: `EditListingAvailabilityForm.end_not_defined` })}
                      value={availability_to}
                    >
                      {
                        endTimes.map(item => {
                          return (<React.Fragment key={item.key}>
                            <option value={item.key}>
                              {intl.formatMessage({ id: `EditListingAvailabilityForm.${[item.value]}` })}
                            </option>
                          </React.Fragment>
                          )
                        })
                      }
                    </FieldSelect>
                  </div>
                </>
              }

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingAvailabilityFormComponent.defaultProps = {
  updateError: null,
};

EditListingAvailabilityFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool.isRequired,
  availability: object.isRequired,
  availabilityPlan: propTypes.availabilityPlan.isRequired,
};

export default compose(injectIntl)(EditListingAvailabilityFormComponent);
