import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { arrayOf, array, bool, func, node, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { propTypes, LISTING_STATE_CLOSED, LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import { Button } from '@material-ui/core'
import { ModalInMobile, PrimaryButton, SecondaryButton, NamedLink } from '../../components';
import { BookingDatesForm, BookingClassifieldsForm } from '../../forms';
import IconCalendar from '../../assets/icons/004_Pages_annonces/Calendar.svg'
import { assignFee } from './FeeHelpers'
import {
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';

import css from './BookingPanel.module.css';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    showContactUser,
    onContactUser,
    unitType,
    onSubmit,
    title,
    subTitle,
    authorDisplayName,
    onManageDisableScrolling,
    timeSlots,
    fetchTimeSlotsError,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    showTitle,
    handleProtectedData,
    initialValues = null
  } = props;


  const [hasDate, setHasDate] = React.useState(false)
  const [hasEvent, setHasEvent] = React.useState(false)

  const price = listing.attributes.price;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingDatesForm = hasListingState && !isClosed;
  const showClosedListingHelpText = listing.id && isClosed;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const isBook = !!parse(location.search).book;
  const slug = createSlug(title)


  const subTitleText = !!subTitle
    ? subTitle
    : showClosedListingHelpText
      ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
      : null;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'BookingPanel.perNight'
    : isDaily
      ? 'BookingPanel.perDay'
      : 'BookingPanel.perUnit';

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.bookingTitle);

  const extraFee = listing.attributes.publicData &&
    listing.attributes.publicData.extra_fee ?
    assignFee(listing.attributes.publicData.extra_fee)
    : null

  const events = listing.attributes.publicData &&
    listing.attributes.publicData.events ? listing.attributes.publicData.events : null


  const category = listing.attributes.publicData && listing.attributes.publicData.category


  return (
    <div>
      <div className={classes}>
        <ModalInMobile
          containerClassName={css.modalContainer}
          id="BookingDatesFormInModal"
          isModalOpenOnMobile={isBook}
          onClose={() => closeBookModal(history, location)}
          showAsModalMaxWidth={MODAL_BREAKPOINT}
          onManageDisableScrolling={onManageDisableScrolling}
          protectCloseButtonBackground={true}
        >
          <div className={css.modalHeading}>
            <h1 className={css.title}>{title}</h1>
            <div className={css.author}>
              <FormattedMessage id="BookingPanel.hostedBy" values={{ name: authorDisplayName }} />
            </div>
          </div>

          <div className={css.bookingHeading}>
            {showTitle ? <h2 className={css.title}>{title}</h2> : null}
            {
              subTitleText && formattedPrice ?
                <div className={css.bookingHelp}>
                  <span className={css.priceSpan}>{formattedPrice}</span>{" / " + subTitleText}<br />
                  {category === 'garden_rent' ? ' par personne' : null}
                </div>
                : null
            }
          </div>

          {showBookingDatesForm ? (
            <BookingDatesForm
              className={css.bookingForm}
              formId="BookingPanel"
              submitButtonWrapperClassName={css.bookingDatesSubmitButtonWrapper}
              unitType={unitType}
              onSubmit={onSubmit}
              price={price}
              listingId={listing.id}
              listing={listing}
              handleProtectedData={handleProtectedData}
              isOwnListing={isOwnListing}
              timeSlots={timeSlots}
              fetchTimeSlotsError={fetchTimeSlotsError}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              extraFee={extraFee}
              events={events}
              availabilityFrom={listing.attributes.publicData.availability_from}
              availabilityTo={listing.attributes.publicData.availability_to}
              capacityMaxPeople={listing.attributes.publicData.capacity_max_people}
              category={category}
              initialValues={initialValues}
              onSetHasDate={setHasDate}
              onSetHasEvent={setHasEvent}
            />
          )
            : null
          }

          {
            showContactUser ?
              <div className={css.buttonSendMessage}>
                <SecondaryButton disabled={!hasDate || (!hasEvent && category !== "tinyhouse")} type="submit" style={{ width: 180, marginBottom: 30 }} onClick={onContactUser}>
                  <FormattedMessage id="ListingPage.contactUser" />
                </SecondaryButton>
              </div>
              : null
          }
        </ModalInMobile>

      </div>

      {
        !isOwnListing ?
          <div className={css.openBookModal}>
            <Button onClick={() => openBookModal(isOwnListing, isClosed, history, location)} className={css.modalButton}>
              <span>Réserver ou<br />envoyer un message</span>
            </Button>
          </div>
          : <div className={css.openBookModal}>
            <NamedLink
              name="EditListingPage"
              params={{ id: listing.id.uuid, slug, type: LISTING_PAGE_PARAM_TYPE_EDIT, tab: 'description' }}>
              <SecondaryButton>
                <FormattedMessage id="EditListingPage.titleEditListing" />
              </SecondaryButton>
            </NamedLink>
          </div>
      }
    </div>
  )
}

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  timeSlots: null,
  fetchTimeSlotsError: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(BookingPanel);
