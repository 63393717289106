import React from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { useSelector } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import config from '../../config';
import { propTypes } from '../../util/types';
import { isStripeInvalidPostalCode, isStripeError } from '../../util/errors';
import * as validators from '../../util/validators';
import { PrimaryButton, ExternalLink, FieldRadioButton, FieldSelect, Form } from '../../components';
import { CircularProgress } from '@material-ui/core';
import PayoutDetailsCompanyAccount from './PayoutDetailsCompanyAccount';
import PayoutDetailsIndividualAccount from './PayoutDetailsIndividualAccount';
import PayoutDetailsIdentityFile from './PayoutDetailsIdentityFile'
import PayoutDetailsAddressFile from './PayoutDetailsAddressFile'
import PayoutDetailsCompanyFile from './PayoutDetailsCompanyFile'

import css from './PayoutDetailsForm.module.css';

// FR1420041010050500013M02606
const supportedCountries = config.stripe.supportedCountries.map(c => c.code);

export const stripeCountryConfigs = countryCode => {
  const country = config.stripe.supportedCountries.find(c => c.code === countryCode);

  if (!country) {
    throw new Error(`Country code not found in Stripe config ${countryCode}`);
  }
  return country;
}
const PayoutDetailsFormComponent = props => {

  const data = useSelector(state => state.PayoutPreferencesPage.stripeAccountData)

  return <FinalForm
    {...props}
    mutators={{
      ...arrayMutators,
    }}
    initialValues={{
      method: props.stripeConnected ? "UPDATE" : "CREATE",
      accountType: data && data.business_type,
      country: data && data.country,
      individual: data && data.business_type === "individual" ? {
        fname: data && data.individual.first_name,
        lname: data && data.individual.last_name,
        phone: data && data.individual.phone && data.individual.phone.replace("+33", "0"),
        email: data && data.individual.email,
        birthDate: data && data.individual.dob,
        verification: {
          document: {
            front: data && data.individual.verification.document.front,
            back: data && data.individual.verification.document.back,
          },
          additional_document: {
            front: data && data.individual.verification.additional_document.front
          }
        },
        address: {
          postalCode: data && data.individual.address.postal_code,
          streetAddress: data && data.individual.address.line1,
          city: data && data.individual.address.city,
        }
      } : {},
      company: props.initialValues && props.initialValues.business_type === "company" ? {
        name: props.initialValues && props.initialValues.company.name,
        taxId: props.initialValues && props.initialValues.company.tax_id,
        phone: props.initialValues && props.initialValues.company.phone && props.initialValues.company.phone.replace("+33", "0"),
        verification: {
          document: {
            front: props.initialValues && props.initialValues.company.verification.document.front,
          },
        },
        address: {
          postalCode: props.initialValues && props.initialValues.company.address.postal_code,
          streetAddress: props.initialValues && props.initialValues.company.address.line1,
          city: props.initialValues && props.initialValues.company.address.city,
        }
      } : {}
    }}
    render={fieldRenderProps => {
      const {
        className,
        createStripeAccountError,
        createStripePersonError,
        disabled,
        handleSubmit,
        initialValues,
        inProgress,
        intl,
        invalid,
        pristine,
        ready,
        submitButtonText,
        currentUserId,
        currentUser,
        stripeConnected,
        values,
      } = fieldRenderProps;


      // console.log("----------values----------", values)
      const { country, individual, company, method } = values;
      const accountType = values.accountType;

      const countryLabel = intl.formatMessage({ id: 'PayoutDetailsForm.countryLabel' });
      const countryPlaceholder = intl.formatMessage({
        id: 'PayoutDetailsForm.countryPlaceholder',
      });
      const countryRequired = validators.required(
        intl.formatMessage({
          id: 'PayoutDetailsForm.countryRequired',
        })
      );

      const classes = classNames(css.root, className, {
        [css.disabled]: disabled,
      });

      const submitInProgress = inProgress;
      const submitDisabled = pristine || invalid || disabled || submitInProgress;
      const showAsRequired = pristine;

      const showIndividual = country && accountType && accountType === 'individual';
      const showCompany = country && accountType && accountType === 'company';

      let error = null;

      if (isStripeInvalidPostalCode(createStripeAccountError)) {
        error = (
          <div className={css.error}>
            <FormattedMessage id="PayoutDetailsForm.createStripeAccountFailedInvalidPostalCode" />
          </div>
        );
      } else if (createStripeAccountError && createStripeAccountError.name === "account[individual][phone]") {
        error = (
          <div className={css.error}>
            <FormattedMessage
              id="PayoutDetailsForm.createStripeAccountFailedInvalidPhoneNumber"
            />
          </div>
        )
      } else if (createStripeAccountError && createStripeAccountError.name === "account[company][phone]") {
        error = (
          <div className={css.error}>
            <FormattedMessage
              id="PayoutDetailsForm.createStripeAccountFailedInvalidPhoneNumber"
            />
          </div>
        )
      } else if (isStripeError(createStripeAccountError)) {
        const stripeMessage = createStripeAccountError.apiErrors[0].meta.stripeMessage;
        error = (
          <div className={css.error}>
            <FormattedMessage
              id="PayoutDetailsForm.createStripeAccountFailedWithStripeError"
              values={{ stripeMessage }}
            />
          </div>
        );
      } else if (createStripeAccountError) {
        error = (
          <div className={css.error}>
            <FormattedMessage id="PayoutDetailsForm.createStripeAccountFailed" />
          </div>
        );
      }

      const stripeConnectedAccountTermsLink = (
        <ExternalLink href="https://stripe.com/connect-account/legal" className={css.termsLink}>
          <FormattedMessage id="PayoutDetailsForm.stripeConnectedAccountTermsLink" />
        </ExternalLink>
      )

      // if(){
      //   return <div style={{textAlign: "center"}}><CircularProgress style={{color:"#e12c42"}} /></div>
      // }

      return config.stripe.publishableKey ? (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.sectionContainer}>
            {
              !stripeConnected &&
              <div className={css.radioContainer}>
                <h3 className={css.titleSelectAccount}>
                  <FormattedMessage id="PayoutDetailsForm.accountTypeTitle" />
                </h3>
                <p className={css.accountTypeTip}><FormattedMessage id="PayoutDetailsForm.accountTypeTitleTip" /></p>
                <FieldRadioButton
                  id="individual"
                  name="accountType"
                  label={intl.formatMessage({ id: 'FieldBoolean.no' })}
                  value="individual"
                  showAsRequired={showAsRequired}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <FieldRadioButton
                  id="company"
                  name="accountType"
                  label={intl.formatMessage({ id: 'FieldBoolean.yes' })}
                  value="company"
                  showAsRequired={showAsRequired}
                />
              </div>
            }

          </div>

          {
            accountType &&
              accountType === "individual" ?
              <PayoutDetailsIdentityFile
                intl={intl}
                stripeConnected={stripeConnected}
                fieldRenderProps={fieldRenderProps}
                fieldId={accountType}
                showAsRequired={showAsRequired}
                front={individual.verification && individual.verification.document && individual.verification.document.front || null}
                back={individual.verification && individual.verification.document && individual.verification.document.back || null}
              />

              : null
          }
          {
            accountType ?
              accountType === "individual" ?
                <PayoutDetailsAddressFile
                  intl={intl}
                  stripeConnected={stripeConnected}
                  fieldRenderProps={fieldRenderProps}
                  address_front={individual.verification && individual.verification.additional_document && individual.verification.additional_document.front || null}
                  fieldId="individual.verification.additional_document.front" />
                : <PayoutDetailsCompanyFile
                  intl={intl}
                  stripeConnected={stripeConnected}
                  fieldRenderProps={fieldRenderProps}
                  company_front={company.verification && company.verification.document && company.verification.document.front || null}
                  fieldId="company.verification.document.front" />
              : null
          }
          {accountType ? (
            <React.Fragment>
              {!inProgress &&
                <>
                  < div className={css.sectionContainer}>
                    <FieldSelect
                      id="country"
                      name="country"
                      disabled={stripeConnected || disabled}
                      className={css.selectCountry}
                      autoComplete="country"
                      label={countryLabel}
                      validate={countryRequired}
                    >
                      <option disabled value="">
                        {countryPlaceholder}
                      </option>
                      {supportedCountries.map(c => (
                        <option key={c} value={c}>
                          {intl.formatMessage({ id: `PayoutDetailsForm.countryNames.${c}` })}
                        </option>
                      ))}
                    </FieldSelect>
                  </div>
                </>
              }
              {
                accountType && country &&
                <>
                  <h3 className={css.subTitle}>
                    {
                      accountType === 'individual' ?
                        <FormattedMessage id="PayoutDetailsForm.bankDetails" />
                        : <FormattedMessage id="PayoutDetailsForm.companyDetails" />
                    }
                  </h3>
                  <p><FormattedMessage id="PayoutDetailsForm.bankDetailsTips" /></p>
                </>
              }
              {showIndividual ? (
                <PayoutDetailsIndividualAccount
                  fieldRenderProps={fieldRenderProps}
                  country={country}
                  initialValues={initialValues}
                  currentUserId={currentUserId}
                  currentUser={currentUser}
                  stripeConnected={stripeConnected}

                />
              ) : showCompany ? (
                <PayoutDetailsCompanyAccount
                  method={method}
                  fieldRenderProps={fieldRenderProps}
                  country={country}
                  stripeConnected={stripeConnected}
                  currentUserId={currentUserId}
                  currentUser={currentUser}
                />
              ) : null}

              {error}

              <p className={css.termsText}>
                <FormattedMessage
                  id="PayoutDetailsForm.stripeToSText"
                  values={{ stripeConnectedAccountTermsLink }}
                />
              </p>
              <PrimaryButton
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={ready}
              >
                {submitButtonText ? (
                  submitButtonText
                ) : (
                  <FormattedMessage id="PayoutDetailsForm.submitButtonText" />
                )}
              </PrimaryButton>
            </React.Fragment>
          ) : null}
        </Form>
      ) : (
        <div className={css.missingStripeKey}>
          <FormattedMessage id="PayoutDetailsForm.missingStripeKey" />
        </div>
      );
    }}
  />
}

PayoutDetailsFormComponent.defaultProps = {
  className: null,
  createStripeAccountError: null,
  disabled: false,
  inProgress: false,
  ready: false,
  submitButtonText: null,
  currentUserId: null,
  fieldRenderProps: null,
};

PayoutDetailsFormComponent.propTypes = {
  className: string,
  createStripeAccountError: object,
  disabled: bool,
  inProgress: bool,
  ready: bool,
  submitButtonText: string,
  currentUserId: propTypes.uuid,
  fieldRenderProps: shape({
    handleSubmit: func,
    invalid: bool,
    pristine: bool,
    values: object,
  }),

  // from injectIntl
  intl: intlShape.isRequired,
};

const PayoutDetailsForm = compose(injectIntl)(PayoutDetailsFormComponent);

export default PayoutDetailsForm;




// accountType: "individual",
//       country: "FR",
//       individual: {
//         fname: "Nicolas",
//         lname: "A",
//         phone: "0695028899",
//         email: "cvbudsjmranob@northsixty.com",
//         birthDate: {
//           year: 1999,
//           month: 10,
//           day: 13
//         }, 
//         verification: {
//           document: {
//             front: props.initialValues && props.initialValues.individual.verification.document.front ,
//             back: props.initialValues && props.initialValues.individual.verification.document.back,
//           },
//           additional_document:  {
//             front: props.initialValues && props.initialValues.individual.verification.additional_document.front
//           }
//         },
//         address: {
//           postalCode: "75010",
//           streetAddress: "56 rue des vignes",
//           city: "Paris",
//         }
//       },

      // company:  {
      //   name: "nico",
      //   taxId: "999999999",
      //   verification: {
      //     document: {
      //       front: "file_identity_document_success",
      //     },
      //   },
      //   address: {
      //     postalCode: "78000",
      //     streetAddress: "address_line1_no_match",
      //     city: "Paris",
      //   }
      // },
      // accountOpener: {
      //   fname: "Nic",
      //   lname: "Las",
      //   birthDate: {
      //     year: 2015,
      //     month: 9,
      //     day: 3
      //   },
      //   verification: {
      //     document: {
      //       front: "file_identity_document_success",
      //       back: "file_identity_document_success",
      //     },
      //     additional_document: {
      //       front: "file_identity_document_success",
      //     }
      //   },
      //   address: {
      //     postalCode: "78000",
      //     streetAddress: "address_line1_no_match",
      //     city: "Paris",
      //   }
      // }