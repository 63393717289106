import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { LINE_ITEM_UNITS, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const LineItemUnitsMaybe = props => {
  const { transaction, unitType='line-item/units', category } = props;

  if (unitType !== LINE_ITEM_UNITS) {
    return null;
  }
  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === unitType && !item.reversal
  );

  // return <p>CODE</p>
  
  if (!unitPurchase) {
    throw new Error(`LineItemUnitsMaybe: lineItem (${unitType}) missing`);
  }

  const quantity = (typeof transaction.attributes.protectedData !== "undefined") ? transaction.attributes.protectedData.nb_unit : unitPurchase.quantity;
  return (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.quantityUnit" />
      </span>
      <span className={css.itemValue}>
        <FormattedMessage id={`BookingBreakdown.${[category]}Quantity`} values={{ quantity }} />
      </span>
    </div>
  );
};

LineItemUnitsMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
};

export default LineItemUnitsMaybe;
