import React from 'react'
import { formatMoney } from '../../util/currency';
import { FieldCheckbox } from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

const RenderExtraFee = (props) => {
    const {label, amount, currency, intl} = props
    
    const formattedFee = formatMoney(
        intl,
        new Money(amount, currency)
    )

    const FeeLabel = intl.formatMessage(
    { id: `FieldCheckbox.${label}` },
    { fee: formattedFee }
    );

    return <FieldCheckbox
        id={label}
        name={label}
        label={FeeLabel}
        value={label}
    />
}

export default RenderExtraFee;