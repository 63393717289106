import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {
  Button,
} from '../../components';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config.js';
import { FormattedMessage } from '../../util/reactIntl';

import css from './FilterEvent.module.css'


const FilterEvent = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = (key) => {
    props.handleChange("pub_events", key)
    handleClose()
  }


  const [checked, setChecked] = React.useState([]);

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }

    setChecked(updatedList);

    props.handleChange("pub_events", updatedList.join(","))
  };

  const eventsOptions = findOptionsForSelectFilter(
    'events',
    config.custom.filters
  )


  if (props.isMobile) {
    return <div className="checkList" xs={12}>
      <div className="list-container" xs={12}>
        {eventsOptions.map((item, index) => (
          item.key &&
          <div key={index} style={{ marginBottom: "15px" }}>
            <label for={item.key} className={css.checkboxes}>
              <input type="checkbox" id={item.key} value={item.key} className={css.checkboxesInput} onChange={handleCheck} />
              {props.intl.formatMessage({ id: `FieldCheckbox.${[item.key || item.label]}` })}
            </label>

          </div>

        ))}
      </div>
    </div >
  }
  /*
    if(props.isMobile) {
      return <select onChange={(e) => handleSearch(e.target.value)}>
          { 
            [{ key: null, label: "allEvent" }, ...eventsOptions].map((item, i) => <option value={item.key} key={i}>
                  {props.intl.formatMessage({id:`FieldCheckbox.${[item.key || item.label]}`})} 
              </option>
            )
          }
      </select>
    }
    */
  let classButton;
  if (props.event) {
    classButton = css.buttonFilterActive
  } else {
    classButton = css.buttonFilter
  }

  return (<div>
    <Button
      className={classButton}
      onClick={handleClick}>
      <FormattedMessage id={`FieldCheckbox.${props.event || 'event'}`} />
    </Button>
    <Menu
      id="events-menu-filter"
      elevation={4}
      variant="menu"
      anchorEl={anchorEl}
      // anchorOrigin={{
      //   vertical: 'bottom',
      //   horizontal: 'bottom',
      // }}
      // transformOrigin={{
      //   vertical: 'top',
      //   horizontal: 'bottom',
      // }}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {[{ key: null, label: "allEvent" }, ...eventsOptions].map((item, i) => {
        return <MenuItem
          style={{ fontWeight: item.key === props.event ? 600 : 400 }}
          onClick={() => handleSearch(item.key)} key={i} value={item.key} >
          <FormattedMessage id={`FieldCheckbox.${item.key || item.label}`} />
        </MenuItem>
      })
      }

    </Menu>
  </div>
  );
}

export default FilterEvent;